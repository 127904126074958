import React, { useState, useEffect } from "react";
import { Modal, ListGroup, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthContext from "../context/AuthContext";

export default function ChatGrammarTensesModal({ show, onHide, articleId }) {
  const { t } = useTranslation();
  const context = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [tenses, setTenses] = useState([]);

  useEffect(() => {
    const fetchTenses = async () => {
      try {
        const response = await fetch("/api/tenses", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            language_string:
              context.getSelectedLanguagePair().language_learning.name,
          }),
        });
        const data = await response.json();
        setTenses(data.tenses);
      } catch (error) {
        console.error("Error fetching tenses:", error);
      }
    };

    if (show) {
      fetchTenses();
    }
  }, [show, context.getSelectedLanguagePair]);

  return (
    <Modal show={show} onHide={onHide} size="lg" fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("grammar_guide:grammar_chat_modal_title")}{" "}
          <span>
            <sup>
              <span className="badge bg-warning text-dark ms-2">
                {t("common:beta")}
              </span>
            </sup>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("grammar_guide:grammar_chat_modal_description")}</p>
        <ListGroup>
          {tenses.map((tense) => (
            <ListGroup.Item
              key={tense}
              className="d-flex justify-content-between align-items-center"
            >
              <h6>{tense}</h6>
              <Button
                variant="primary"
                onClick={() =>
                  navigate(
                    `/chat-grammar?articleId=${articleId}&tense_name=${encodeURIComponent(
                      tense
                    )}`
                  )
                }
              >
                {t("grammar_guide:button_practice")}
              </Button>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
}
