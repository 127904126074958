import React, { useEffect, useState } from "react";

// NOTE: the package react-resize-detector might help as well

export default function ReaderHeight() {
  const [clientHeight, setClientHeight] = useState(0);
  const [divClientHeight, setDivClientHeight] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [divScrollHeight, setDivScrollHeight] = useState(0);
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [divOffsetHeight, setDivOffsetHeight] = useState(0);
  const [innerHeight, setInnerHeight] = useState(0);
  const [divInnerHeight, setDivInnerHeight] = useState(0);
  const [outerHeight, setOuterHeight] = useState(0);
  const [divOuterHeight, setDivOuterHeight] = useState(0);
  const [showDescriptions, setShowDescriptions] = useState(true);

  const margin = 10;
  const padding = 15;
  const border = 11;

  function checkForVerticalOverflow() {
    const bodyHeight = document.body.scrollHeight;
    const windowHeight = window.innerHeight;

    if (bodyHeight > windowHeight) {
      console.log("Vertical overflow detected!");
      document.body.style.backgroundColor = "#ffcccc"; // Light red
      return true;
    } else {
      console.log("No vertical overflow");
      document.body.style.backgroundColor = "white"; // Original color
      return false;
    }
  }

  useEffect(() => {
    // Initial set
    setAllHeights();
    checkForVerticalOverflow();

    // Add event listeners
    const handleUpdate = () => {
      setAllHeights();
      checkForVerticalOverflow();
    };

    window.addEventListener("resize", handleUpdate);
    window.addEventListener("scroll", handleUpdate);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("resize", handleUpdate);
      window.removeEventListener("scroll", handleUpdate);
    };
  }, []); // Empty dependency array means this runs once on mount

  function setAllHeights() {
    setClientHeight(document.documentElement.clientHeight);
    setScrollHeight(document.documentElement.scrollHeight);
    setOffsetHeight(document.documentElement.offsetHeight);
    setInnerHeight(window.innerHeight);
    setOuterHeight(window.outerHeight);
    setDivClientHeight(document.getElementById("bigDiv").clientHeight);
    setDivScrollHeight(document.getElementById("bigDiv").scrollHeight);
    setDivOffsetHeight(document.getElementById("bigDiv").offsetHeight);
    setDivInnerHeight(document.getElementById("bigDiv").innerHeight);
    setDivOuterHeight(document.getElementById("bigDiv").outerHeight);
  }

  function setDivHeight(height) {
    document.getElementById("bigDiv").style.height = height + "px";
    setAllHeights();
  }

  function removeHorizontalScrollbar(width) {
    document.getElementById("bigDiv").style.width = width + "%";
    setAllHeights();
  }

  function removeHeight() {
    document.getElementById("bigDiv").style.height = "auto";
    setAllHeights();
  }

  return (
    <div style={{ marginTop: "10px" }}>
      <div
        style={{
          width: "110%",
          backgroundColor: "lightgray",
          borderTop: border + "px solid black",
          marginTop: margin,
          paddingTop: padding,
        }}
        id="bigDiv"
      >
        <button onClick={() => setDivHeight(400)}>Set height to 400px</button>
        <button onClick={() => setDivHeight(1000)}>Set height to 1000px</button>
        <button onClick={() => removeHeight()}>Remove height</button>
        <button onClick={() => removeHorizontalScrollbar(100)}>
          Remove scroll
        </button>
        <button onClick={() => removeHorizontalScrollbar(110)}>
          Add scroll
        </button>
        <button onClick={() => setShowDescriptions(!showDescriptions)}>
          {showDescriptions ? "Hide" : "Show"} Descriptions
        </button>

        <table style={{ width: "80%" }}>
          <thead>
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <th>Property</th>
              <th>HTML</th>
              <th>div</th>
              <th>div + m</th>
              <th>div + p</th>
              <th>div + b</th>
              <th>div + p + b</th>
              <th>div + p + b + m</th>
              <th style={{ display: showDescriptions ? "table-cell" : "none" }}>
                Description
              </th>
              <th
                style={{
                  display: showDescriptions ? "table-cell" : "none",
                  width: "300px",
                }}
              >
                Original Description
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ backgroundColor: "#ffffff" }}>
              <td>Outer Height</td>
              <td>{outerHeight}</td>
              <td>{divOuterHeight}</td>
              <td>{divOuterHeight + margin}</td>
              <td>{divOuterHeight + padding}</td>
              <td>{divOuterHeight + border}</td>
              <td>{divOuterHeight + padding + border}</td>
              <td>{divOuterHeight + padding + border + margin}</td>
              <td style={{ display: showDescriptions ? "table-cell" : "none" }}>
                HTML: Total height of the browser window including all UI
                elements (toolbars, address bar, status bar)
                <br />
                <br />
                DIV: Not applicable (only available via jQuery)
              </td>
              <td style={{ display: showDescriptions ? "table-cell" : "none" }}>
                Total outer height of the browser window, including browser
                chrome like toolbars and the status bar as well as the window's
                content area.
              </td>
            </tr>
            <tr style={{ backgroundColor: "#f8f8f8" }}>
              <td>Inner Height</td>
              <td>{innerHeight}</td>
              <td>{divInnerHeight}</td>
              <td>{divInnerHeight + margin}</td>
              <td>{divInnerHeight + padding}</td>
              <td>{divInnerHeight + border}</td>
              <td>{divInnerHeight + padding + border}</td>
              <td>{divInnerHeight + padding + border + margin}</td>
              <td style={{ display: showDescriptions ? "table-cell" : "none" }}>
                HTML: Height of the viewport (visible content area) including
                scrollbars if present
                <br />
                <br />
                DIV: Not applicable (only available via jQuery)
              </td>
              <td style={{ display: showDescriptions ? "table-cell" : "none" }}>
                Height of the window's content area. Includes the vertical size
                of the viewport and potentially vertical scrollbars.
              </td>
            </tr>
            <tr style={{ backgroundColor: "#ffffff" }}>
              <td>Client Height</td>
              <td>{clientHeight}</td>
              <td>{divClientHeight}</td>
              <td>{divClientHeight + margin}</td>
              <td>{divClientHeight + padding}</td>
              <td>{divClientHeight + border}</td>
              <td>{divClientHeight + padding + border}</td>
              <td>{divClientHeight + padding + border + margin}</td>
              <td style={{ display: showDescriptions ? "table-cell" : "none" }}>
                HTML: Viewport height minus horizontal scrollbar if present
                <br />
                <br />
                DIV: Content height + padding (excludes borders, margins, and
                scrollbars)
              </td>
              <td style={{ display: showDescriptions ? "table-cell" : "none" }}>
                Height of the visible content of an element in pixels, including
                padding but not borders, scrollbars, or margins. Refers to
                viewport height excluding scrollbars when used on
                document.documentElement.
              </td>
            </tr>
            <tr style={{ backgroundColor: "#f8f8f8" }}>
              <td>Scroll Height</td>
              <td>{scrollHeight}</td>
              <td>{divScrollHeight}</td>
              <td>{divScrollHeight + margin}</td>
              <td>{divScrollHeight + padding}</td>
              <td>{divScrollHeight + border}</td>
              <td>{divScrollHeight + padding + border}</td>
              <td>{divScrollHeight + padding + border + margin}</td>
              <td style={{ display: showDescriptions ? "table-cell" : "none" }}>
                HTML: Total scrollable page height (minimum of viewport height)
                <br />
                <br />
                DIV: Total content height including overflow + padding (excludes
                borders, minimum of clientHeight)
              </td>
              <td style={{ display: showDescriptions ? "table-cell" : "none" }}>
                Total height of the element's content, including content not
                visible on the screen without scrolling. Usually greater than
                clientHeight when there's content overflow. Without horizontal
                scrollbar (if it's present, the height is smaller). Never
                smaller than offset height (except for a specific element where
                it doesn't include borders). It always expands to the visual
                viewport size.
              </td>
            </tr>
            <tr style={{ backgroundColor: "#ffffff" }}>
              <td>Offset Height</td>
              <td>{offsetHeight}</td>
              <td>{divOffsetHeight}</td>
              <td>{divOffsetHeight + margin}</td>
              <td>{divOffsetHeight + padding}</td>
              <td>{divOffsetHeight + border}</td>
              <td>{divOffsetHeight + padding + border}</td>
              <td>{divOffsetHeight + padding + border + margin}</td>
              <td style={{ display: showDescriptions ? "table-cell" : "none" }}>
                HTML: Total document height including all scrollable content
                <br />
                <br />
                DIV: Content height + padding + borders + horizontal scrollbar
                if present (excludes margins)
              </td>
              <td style={{ display: showDescriptions ? "table-cell" : "none" }}>
                Height of the element including vertical padding and borders,
                and horizontal scrollbars if rendered, but not the margin. Used
                for layout calculations. It doesn't expand to the visual
                viewport size.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
