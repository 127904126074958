import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";

const ChartAudioListened = ({ data }) => {
  const { t } = useTranslation();
  // Transform the object data into an array format and convert seconds to minutes
  const chartData = Object.entries(data).map(([date, seconds]) => ({
    date,
    minutes: Math.round(seconds / 60), // Convert seconds to minutes and round to nearest minute
  }));

  return (
    <ResponsiveContainer width="100%" height={250}>
      <LineChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis allowDecimals={false} />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          name={t("statistics:minutes")}
          dataKey="minutes"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ChartAudioListened;
