import React from "react";
import { Button, Alert } from "react-bootstrap";
import { Book, ChatSquareText } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function LibraryTabWriting() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant="dark"
        className="mt-2 mb-3"
        onClick={() => navigate("/chat")}
      >
        <ChatSquareText className="me-2" />
        {t("library:chat_with_ai")}
      </Button>

      {/* Article Discussion Section */}
      <Alert variant="warning" className="mt-2">
        <div className="text-left d-flex align-items-center">
          <Book className="text-primary me-2" color="dark" />
          <div>{t("library:writing_discuss_articles_description")}</div>
        </div>
      </Alert>
    </>
  );
}
