import React, { useContext, useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Card,
  Carousel,
  Row,
  Col,
  Container,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  Headphones,
  Trash,
  Wallet2,
  Pencil,
  Journals,
  ChatSquareText,
} from "react-bootstrap-icons";
import ProgressBar from "react-bootstrap/ProgressBar";
import AuthContext from "../context/AuthContext";
import "./ContinueStudying.css";
import axios from "axios";
import PlaceholderImg from "../chalice-150x150.png";
import BookDetailsModal from "./CollectionDetailsModal";
import { useTranslation } from "react-i18next";
import ChatGrammarTensesModal from "./ChatGrammarTensesModal";

const ContinueStudying = () => {
  const { t } = useTranslation();
  const context = useContext(AuthContext);
  const [itemsPerGroup, setItemsPerGroup] = useState(3); // Initial value: 3 items per group
  const [showModal, setShowModal] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const containerRef = useRef(null);
  const [showGrammarModal, setShowGrammarModal] = useState(false);
  const [selectedArticleForGrammar, setSelectedArticleForGrammar] =
    useState(null);

  let navigate = useNavigate();

  const groupCards = (data, cardsPerGroup) => {
    const grouped = [];
    for (let i = 0; i < data.length; i += cardsPerGroup) {
      grouped.push(data.slice(i, i + cardsPerGroup));
    }
    return grouped;
  };

  useEffect(() => {
    const calculateItemsPerGroup = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        const items = Math.max(1, Math.floor((width - 80) / 200)); // subtracting 20 pixels as a buffer

        setItemsPerGroup(items);
      }
    };

    calculateItemsPerGroup(); // Initial calculation
    window.addEventListener("resize", calculateItemsPerGroup); // Add event listener

    return () => {
      window.removeEventListener("resize", calculateItemsPerGroup); // Cleanup event listener
    };
  }, []);

  context.study_articles.forEach((article) => {
    console.log(
      `Title: ${article.title}, Last Accessed: ${
        article.date_last_accessed || "N/A"
      }, Date Added: ${article.date_added || "N/A"}`
    );
  });

  const groupedCards = groupCards(
    context.study_articles.sort(
      (a, b) =>
        new Date(b.date_last_accessed || b.date_added) -
        new Date(a.date_last_accessed || a.date_added)
    ),
    itemsPerGroup
  );

  const handleShowModal = (book) => {
    setSelectedBook(book);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBook(null);
  };

  function removeArticleFromStudyList(e, articleId) {
    e.preventDefault();
    let languagePairId;
    if (context.language_pairs) {
      for (const pair of context.language_pairs) {
        console.log("pair: ", pair);
        if (pair.is_selected) {
          languagePairId = pair._id;
          break;
        }
      }
    }
    try {
      console.log(
        `Trying to remove article ${articleId} from study list for language pair ${languagePairId} for userId ${context.user_id}`
      );
      axios
        .delete(
          `/api/user/${context.user_id}/languagepair/${languagePairId}/article/${articleId}`
        )
        .then((response) => {
          console.log("Article removed:", response.data);
          // remove article from studyArticles
          context.fetchStudyArticles();
          // setStudyArticles(
          //   studyArticles.filter((article) => article._id !== articleId)
          // );
        })
        .catch((error) => {
          console.error(
            "Error removing article:",
            error.response || error.message
          );
        });
    } catch (error) {
      console.log("Error: ", error);
    }
  }

  return (
    <>
      <div className="" style={{ maxWidth: "700px" }}>
        <h3 className="mb-3">
          {t("library:continue_studying")}
          {/* {studyArticlesAreLoading && (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )} */}
        </h3>
      </div>

      <Container className="mt-3 mb-5" ref={containerRef}>
        <Carousel
          variant="dark"
          indicators={false}
          interval={null}
          controls={groupedCards.length > 1}
        >
          {groupedCards.map((group, index) => (
            <Carousel.Item key={index}>
              <Row style={{ justifyContent: "space-evenly" }}>
                {group.map((article, articleIndex) => (
                  <Col
                    key={article._id}
                    style={{
                      maxWidth: "200px",
                      flexBasis: "200px",
                      padding: "0",
                    }}
                  >
                    <Card style={{ width: "200px" }}>
                      <Card.Body>
                        <Row className="flex-column flex-md-row">
                          {/* <Col xs={12}>
                            <div
                              style={{
                                width: "100%",
                                paddingBottom: "56.25%",
                                position: "relative",
                              }}
                              className="mb-3"
                            >
                              <Link
                                to={`/reader/${article._id}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                <Card.Img
                                  variant="top"
                                  src={article.image || PlaceholderImg}
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    objectFit: article.image
                                      ? "cover"
                                      : "scale-down",
                                  }}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = PlaceholderImg;
                                    e.target.style.objectFit = "scale-down";
                                  }}
                                />
                              </Link>
                            </div>
                          </Col> */}
                          <Col xs={12}>
                            <Card.Title
                              style={{
                                display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textDecoration: "none",
                                color: "black",
                                height: "40px",
                              }}
                            >
                              <Link
                                to={`/reader/${article._id}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                <h6>{article.title}</h6>
                              </Link>
                            </Card.Title>
                            <Card.Subtitle
                              className="mb-2 text-muted"
                              style={{
                                display: "-webkit-box",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textDecoration: "none",
                                color: "black",
                                height: "20px",
                              }}
                            >
                              {article.collection ? (
                                <>
                                  <Journals
                                    size="16"
                                    className="me-1"
                                    onClick={() =>
                                      handleShowModal(article.collection._id)
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span
                                    onClick={() =>
                                      handleShowModal(article.collection._id)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {article.collection.title}
                                  </span>
                                </>
                              ) : (
                                article.publisher_title || "Lingo Champion"
                              )}
                            </Card.Subtitle>
                          </Col>
                        </Row>
                        <Row>
                          {/* <hr className="mt-3" /> */}
                          <div>
                            <ProgressBar
                              now={article.percent_complete}
                              min={0}
                              max={100}
                              variant={
                                article.percent_complete === 100
                                  ? "success"
                                  : article.percent_complete >= 66
                                  ? "warning"
                                  : null // or specify another variant for values below 66%
                              }
                              style={{ height: "2px" }}
                              className="my-2"
                            />
                          </div>
                          <Col className="d-flex justify-content-between">
                            <div className="d-inline">
                              <Dropdown className="d-inline">
                                <Dropdown.Toggle
                                  variant="secondary"
                                  className="custom-dropdown-button"
                                  direction="down-centered"
                                  style={{ fontWeight: 400 }}
                                >
                                  <Wallet2
                                    size="16"
                                    className="remove-article-from-study-list-button mt-1"
                                    color="black"
                                    style={{
                                      transform: "rotate(-90deg)",
                                      marginBottom: "10px",
                                    }}
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    // href="#/action-1"
                                    onClick={() => {
                                      navigate(
                                        "/vocabulary/review/" +
                                          article._id +
                                          "?type=general"
                                      );
                                    }}
                                  >
                                    {t("library:general_flashcards")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    // href="#/action-2"
                                    onClick={() => {
                                      navigate(
                                        "/vocabulary/review/" +
                                          article._id +
                                          "?type=srs"
                                      );
                                    }}
                                  >
                                    {t("library:srs_flashcards")}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>

                              <Link
                                to={
                                  article.audio_url
                                    ? "/player/" + article._id
                                    : "/reader/" + article._id
                                }
                              >
                                <OverlayTrigger
                                  placement="auto"
                                  delay={{ show: 50, hide: 100 }}
                                  overlay={
                                    <Tooltip style={{ zIndex: 20000 }}>
                                      {article.audio_url
                                        ? t("library:listen_to_the_audio")
                                        : t(
                                            "library:audio_is_not_available_yet"
                                          )}
                                    </Tooltip>
                                  }
                                  popperConfig={{
                                    modifiers: [
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [0, 10], // Change the numbers to control x, y offset
                                        },
                                      },
                                      {
                                        name: "preventOverflow",
                                        options: {
                                          padding: 10, // Change this value to control padding
                                        },
                                      },
                                      {
                                        name: "flip",
                                        options: {
                                          padding: 10, // Change this value to control padding
                                        },
                                      },
                                    ],
                                  }}
                                >
                                  <Headphones
                                    size="21"
                                    color={
                                      article.audio_url ? "black" : "lightgray"
                                    }
                                    className="mx-2"
                                    style={{ marginBottom: "7px" }}
                                  />
                                </OverlayTrigger>
                              </Link>

                              <Dropdown className="d-inline">
                                <Dropdown.Toggle
                                  variant="secondary"
                                  className="custom-dropdown-button"
                                  direction="down-centered"
                                  style={{ fontWeight: 400 }}
                                >
                                  <ChatSquareText
                                    size="16"
                                    className="mt-1"
                                    color="black"
                                    style={{ marginBottom: "10px" }}
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => {
                                      navigate(
                                        `/chat?articleId=${article._id}`
                                      );
                                    }}
                                  >
                                    {t("readers:discuss_with_ai")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setSelectedArticleForGrammar(article._id);
                                      setShowGrammarModal(true);
                                    }}
                                  >
                                    {t(
                                      "grammar_guide:button_practice_grammar_with_ai"
                                    )}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>

                            <div className="d-flex">
                              {article.import_source.user_id &&
                                article.import_source.user_id ===
                                  context.user_id && (
                                  <Link to={"/library/editor/" + article._id}>
                                    <OverlayTrigger
                                      placement="auto"
                                      delay={{ show: 50, hide: 100 }}
                                      overlay={
                                        <Tooltip style={{ zIndex: 20000 }}>
                                          {t("library:edit")}
                                        </Tooltip>
                                      }
                                      popperConfig={{
                                        modifiers: [
                                          {
                                            name: "offset",
                                            options: {
                                              offset: [0, 10], // Change the numbers to control x, y offset
                                            },
                                          },
                                          {
                                            name: "preventOverflow",
                                            options: {
                                              padding: 10, // Change this value to control padding
                                            },
                                          },
                                          {
                                            name: "flip",
                                            options: {
                                              padding: 10, // Change this value to control padding
                                            },
                                          },
                                        ],
                                      }}
                                    >
                                      <Pencil
                                        size="16"
                                        color={"black"}
                                        className="mx-2"
                                        style={{ marginBottom: "3px" }}
                                      />
                                    </OverlayTrigger>
                                  </Link>
                                )}
                              <Trash
                                size="18"
                                className="remove-article-from-study-list-button mt-1"
                                onClick={(e) => {
                                  removeArticleFromStudyList(e, article._id);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}

                {/* Add empty placeholders if the group has fewer cards than itemsPerGroup */}
                {Array.from({ length: itemsPerGroup - group.length }).map(
                  (_, index) => (
                    <Col
                      key={`placeholder-${index}`}
                      style={{
                        maxWidth: "200px",
                        flexBasis: "200px",
                        padding: "0",
                        visibility: "hidden", // Hide the placeholder
                      }}
                    >
                      <Card style={{ width: "200px" }}>
                        <Card.Body>{/* Empty placeholder */}</Card.Body>
                      </Card>
                    </Col>
                  )
                )}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>

      {selectedBook && (
        <BookDetailsModal
          showModal={showModal}
          handleClose={handleCloseModal}
          collectionId={selectedBook}
        />
      )}

      {selectedArticleForGrammar && (
        <ChatGrammarTensesModal
          show={showGrammarModal}
          onHide={() => {
            setShowGrammarModal(false);
            setSelectedArticleForGrammar(null);
          }}
          articleId={selectedArticleForGrammar}
        />
      )}
    </>
  );
};

export default ContinueStudying;
