import React, { useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  ProgressBar,
  Button,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Form,
} from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { QuestionCircle } from "react-bootstrap-icons";
import "./Review.css";
import AuthContext from "../context/AuthContext";
import FlashcardList from "../Components/FlashcardList.js";
import axios from "axios";
import { useTranslation } from "react-i18next";

const GridLayout = () => {
  const context = React.useContext(AuthContext);
  const { t } = useTranslation();
  const [flashcards, setFlashcards] = React.useState([]);
  const [currentCardIndex, setCurrentCardIndex] = React.useState(0);
  const [loadingKnownWords, setLoadingKnownWords] = React.useState(false);
  const [hasLoadedOnce, setHasLoadedOnce] = React.useState(false);
  const [notEnoughWords, setNotEnoughWords] = React.useState(false);
  const [flashcardsType, setFlashcardsType] = React.useState(""); // 'general' or 'srs' (spaced repetition system)

  const { articleId } = useParams();
  const location = useLocation();

  const showQuestionWordRef = useRef(context.settings.show_question_word_in_flashcards);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get("type"); // 'general' or 'srs'
    console.log("Type: ", type);
    if (type === "srs") setFlashcardsType("srs");
    if (type === "general") setFlashcardsType("general");
  }, [location]);

  // useEffect for flashcardsType (otherwise there's a bug where the flashcardsType is not set when the component is first rendered)
  useEffect(() => {
    if (flashcardsType === "srs" || flashcardsType === "general") {
      startNewSession();
    }
  }, [flashcardsType]);

  const startNewSession = async () => {
    setLoadingKnownWords(true);
    setCurrentCardIndex(0); // Reset currentCardIndex to 0
    let knownWords = await context.fetchKnownWords(); // Fetch known words to update the context
    console.log(
      "Got the known words. Now creating a new flashcards sessions: ",
      knownWords
    );

    // let articleId = window.location.pathname.split("/").pop();
    // if (articleId === "review") articleId = false;

    // send a track request to server for starting a new session
    // TODO: need to change the mode
    await axios.post("/api/flashcards/started", {
      auto_speech: true,
      mode: articleId ? "article" : "vocabulary",
      type: flashcardsType,
      // words: [],  // TODO: should be replaced with the words to get sample sentences
    });

    // NOTE: currently trusting the server to save changes to the known words during the session, so, no need to pull the fetch the words again before the session
    let filteredWords = knownWords.filter((word) => word.translation);

    // check if the URL contains article_id, if it does, filter the known words to only include the words from the article
    if (articleId) {
      console.log("Flashcards are meant only for Article ID: ", articleId);
      filteredWords = filteredWords.filter(
        (word) =>
          word.article_id && String(word.article_id) === String(articleId)
      );
    }

    if (filteredWords.length < 2) {
      setNotEnoughWords(true);
      setLoadingKnownWords(false);
      return;
    }

    let sortedFilteredWords;

    if (flashcardsType === "general") {
      sortedFilteredWords = filteredWords.sort(
        (a, b) => a.strength - b.strength
      );
    } else if (flashcardsType === "srs") {
      // sort out all words that have srs_due_date set to null
      // NOTE: old users who added words before the update will have loads of words with srs_due_date set to null, these would be sorted out as well unless I update them manually in the database
      let additionalWords = filteredWords.filter(
        (word) => word.srs_due_date === undefined
      );
      filteredWords = filteredWords.filter((word) => word.srs_due_date);

      // add nr_of_tries to the words
      filteredWords = filteredWords.map((word) => {
        word.nr_of_tries = 0;
        return word;
      });

      // Get today's date at midnight in the user's local timezone
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Adjust to midnight

      // Filter words due "today" in the user's local timezone
      const wordsDueToday = filteredWords.filter((word) => {
        const dueDateLocal = new Date(word.srs_due_date);
        // No need to adjust the dueDateLocal here, as we're comparing full dates
        return dueDateLocal.toDateString() === today.toDateString();
      });

      console.log("SRS: words due today: ", wordsDueToday);

      // Filter and sort words with due dates before "today"
      const wordsBeforeTodaySorted = filteredWords
        .filter((word) => {
          const dueDateLocal = new Date(word.srs_due_date);
          dueDateLocal.setHours(0, 0, 0, 0); // Adjust to midnight for comparison
          return dueDateLocal < today;
        })
        .sort((a, b) => new Date(a.srs_due_date) - new Date(b.srs_due_date));

      // Concatenate the arrays
      sortedFilteredWords = wordsDueToday.concat(wordsBeforeTodaySorted);
      // NOTE: this is because originally srs_due_date did not exist, so, all the words that were added before the update will have srs_due_date set to undefined
      if (sortedFilteredWords.length < 7) {
        console.log(
          "Adding additional words to the session: ",
          additionalWords.length
        );
        sortedFilteredWords = sortedFilteredWords.concat(additionalWords);
      }
      console.log("SRS: sorted filtered words: ", sortedFilteredWords);
    }

    const firstSixWords = sortedFilteredWords.slice(0, 7);

    const tripledWords = firstSixWords.flatMap((word) => [word, word, word]);

    const randomWords = randomizeWithoutAdjacentDuplicates(tripledWords);

    console.log("New flashcards session: ", randomWords);

    setFlashcards(randomWords); // Set the new flashcards
    setLoadingKnownWords(false);
    setHasLoadedOnce(true);
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  function randomizeWithoutAdjacentDuplicates(array) {
    console.log("Randomizing without adjacent duplicates: ", array);
    if (array.length <= 3) return array; // 0 or 3 elements means no duplicates, exit early, otherwise infinite loop

    let shuffled;
    do {
      shuffled = shuffleArray([...array]);
      for (var i = 0; i < shuffled.length - 1; i++) {
        if (shuffled[i] === shuffled[i + 1]) {
          break;
        }
      }
    } while (i !== shuffled.length - 1);

    return shuffled;
  }

  return (
    <Container
      id="reviewContainer"
      fluid
      className="d-flex justify-content-center"
    >
      <Row style={{ minHeight: "100svh", maxWidth: "300px", width: "60svh" }}>
        <Col className="d-flex flex-column">
          <Row
            id="headerRow"
            className="justify-content-center align-items-center mt-lg-3 mt-2 mb-3"
            style={{ maxWidth: "600px" }}
          >
            <Col xs={2} md={1} className="d-flex justify-content-center">
              <Link to="/vocabulary">
                <Button id="closeButton" variant="light" size="sm">
                  &#10006;
                </Button>
              </Link>
            </Col>
            <Col xs={8} md={10} className="d-flex justify-content-center">
              <ProgressBar
                now={currentCardIndex}
                min={0}
                max={flashcards.length - 1}
                variant={
                  currentCardIndex === flashcards.length - 1
                    ? "success"
                    : currentCardIndex >=
                        Math.floor((flashcards.length - 1) * 0.66) &&
                      currentCardIndex < flashcards.length - 1
                    ? "warning"
                    : undefined
                }
                style={{ height: "5px", width: "75%" }}
              />
            </Col>
            <Col xs={2} md={1} className="d-flex justify-content-center">
              <OverlayTrigger
                placement="left"
                delay={{ show: 50, hide: 100 }}
                overlay={
                  <Tooltip style={{ zIndex: 20000 }}>
                    {t("flashcards:general_flashcards_tooltip")}
                  </Tooltip>
                }
                popperConfig={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, 10], // Change the numbers to control x, y offset
                      },
                    },
                    {
                      name: "preventOverflow",
                      options: {
                        padding: 10, // Change this value to control padding
                      },
                    },
                    {
                      name: "flip",
                      options: {
                        padding: 10, // Change this value to control padding
                      },
                    },
                  ],
                }}
              >
                <div id="helpIcon">
                  <QuestionCircle size={20} />
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <Col className="flex-grow-1 d-flex justify-content-center text-center">
              {notEnoughWords ? (
                <div>
                  <h4 className="my-3">{t("flashcards:not_enough_words")}</h4>
                  <p>
                    {t("flashcards:not_enough_words_description")}
                  </p>
                </div>
              ) : loadingKnownWords ? (
                <div>
                  <Spinner animation="border" variant="primary" />
                  <div>{t("flashcards:loading_the_words")}</div>
                </div>
              ) : (
                <FlashcardList
                  flashcards={flashcards}
                  setFlashcards={setFlashcards}
                  currentCardIndex={currentCardIndex}
                  setCurrentCardIndex={setCurrentCardIndex}
                  startNewSession={startNewSession}
                  isLoading={loadingKnownWords}
                  flashcardsType={flashcardsType}
                />
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              {" "}
              <div className="d-flex align-items-center justify-content-center my-3">
                <Form>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={showQuestionWordRef.current}
                    onChange={() => {
                      context.toggleShowQuestionWordInFlashcards();
                      console.log(
                        `[${new Date().toISOString()}] Toggling whether to show the question word: new=${!showQuestionWordRef.current}`
                      );
                      showQuestionWordRef.current =
                        !showQuestionWordRef.current;
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Form>
                <div className="fs-6">{t("flashcards:show_the_word_on_the_front_side")}</div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default GridLayout;
