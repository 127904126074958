import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Badge,
  Offcanvas,
} from "react-bootstrap";
import {
  BoxArrowInRight,
  Book,
  JournalText,
  List,
  GraphUpArrow,
  PlusCircle,
  ChatSquareText,
  MusicNoteBeamed,
  BodyText,
  FileText,
  ListColumns,
} from "react-bootstrap-icons";
// this is needed to make Bootstrap links react the same way as <Link> would in React Router
import { LinkContainer } from "react-router-bootstrap";
import AuthContext from "../context/AuthContext";
import AppMetaDataContext from "../context/AppMetaDataContext";
import chalice from "../chalice-150x150.png";
import "./Navigation.css";
import { useNavigate } from "react-router-dom";
import StreakNumber from "./StreakNumber";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ReactCountryFlag from "react-country-flag";

function Navigation(props) {
  // console.log("CONNNN NAV: ", this.context);
  const context = useContext(AuthContext);
  const metaData = useContext(AppMetaDataContext);
  console.log("Navigation got the context: ", context);

  const [showOffcanvas, setShowOffcanvas] = useState(false); // Manage offcanvas visibility

  const toggleOffcanvas = () => setShowOffcanvas(!showOffcanvas); // Toggle the offcanvas state

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    axios
      .get("/api/languages")
      .then((response) => {
        const sortedLanguages = response.data.languages.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setLanguages(sortedLanguages);
      })
      .catch((error) => {
        console.error("Error fetching languages:", error);
      });
  }, []);

  // disable automatic translation for logged in user
  useEffect(() => {
    if (context.user_id) {
      document.documentElement.setAttribute("translate", "no");
      const meta = document.createElement("meta");
      meta.name = "google";
      meta.content = "notranslate";
      document.head.appendChild(meta);

      return () => {
        // Cleanup: remove meta tag if user logs out
        document.head.removeChild(meta);
        document.documentElement.removeAttribute("translate");
      };
    }
  }, [context.user_id]);

  let planBadge;

  switch (context.plan) {
    case "Free plan":
      planBadge = (
        <Badge bg="danger" text="dark">
          {context.plan}
        </Badge>
      );
      break;
    case "Premium plan":
      planBadge = (
        <Badge bg="dark" text="light">
          {context.plan}
        </Badge>
      );
      break;
    default:
      break;
  }

  let language_learning;
  // let language_base;
  console.log("context.language_pairs: ", context.language_pairs);

  // loop through context.language_pairs and find the one that has is_selected=true
  // and return the language_learning value
  if (context.language_pairs) {
    for (const pair of context.language_pairs) {
      console.log("pair: ", pair);
      if (pair.is_selected) {
        language_learning = pair.language_learning.code;
        // language_base = pair.language_base;
        break;
      }
    }
  }

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light"
        sticky="top"
        className="mb-5 d-none d-lg-flex"
        style={{ backgroundColor: "lightblue", fontWeight: 500 }}
        id="desktop-nav-bar"
      >
        <Container>
          <LinkContainer
            to="/"
            className={context.user_id && "d-none d-sm-block"}
          >
            <Navbar.Brand href="/">
              {" "}
              <img
                src={chalice}
                height="30"
                className="d-inline-block align-top"
                alt={metaData.appName + " logo"}
              />
            </Navbar.Brand>
          </LinkContainer>
          <LinkContainer to="/" className="d-none d-lg-block">
            <Navbar.Brand>{metaData.appName} </Navbar.Brand>
          </LinkContainer>

          {/* Nav for larger screens */}
          <Nav className="me-auto d-flex flex-row">
            {!context.user_id && (
              <LinkContainer to="/">
                <Nav.Link className="me-2">{t("navigation:home")}</Nav.Link>
              </LinkContainer>
            )}
            {context.user_id && (
              <LinkContainer to="/library">
                <Nav.Link className="me-2">{t("navigation:library")}</Nav.Link>
              </LinkContainer>
            )}
            {context.user_id && (
              <LinkContainer to="/vocabulary">
                <Nav.Link className="me-2">
                  {t("navigation:vocabulary")}
                </Nav.Link>
              </LinkContainer>
            )}
            {context.user_id && (
              <LinkContainer to="/statistics">
                <Nav.Link className="me-2">
                  {t("navigation:statistics")}
                </Nav.Link>
              </LinkContainer>
            )}
            {!context.user_id && (
              <LinkContainer to="/pricing" className="me-2">
                <Nav.Link href="/pricing">{t("navigation:pricing")}</Nav.Link>
              </LinkContainer>
            )}
            {/* {context.user_id && (
              <LinkContainer to="/pricing" className="me-2 d-none d-md-block">
                <Nav.Link href="/pricing">Pricing</Nav.Link>
              </LinkContainer>
            )} */}
            {/* Contact link for larger screens */}
            {!context.user_id && (
              <LinkContainer to="/contact" className="me-2 d-none d-md-block">
                <Nav.Link>{t("navigation:contact")}</Nav.Link>
              </LinkContainer>
            )}
          </Nav>

          {context.user_id && (
            <Nav className="justify-content-end d-block">
              <Nav.Item className="ml-auto">
                <LinkContainer to="/statistics">
                  <Nav.Link>
                    <StreakNumber />
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            </Nav>
          )}

          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="ms-3"
          />
          <Navbar.Collapse id="responsive-navbar-nav" style={{ flexGrow: 0 }}>
            <Nav className="ms-auto">
              {context.user_id ? (
                <>
                  <NavDropdown
                    title={
                      <ReactCountryFlag
                        countryCode={
                          languages.find(
                            (lang) => lang.code === language_learning
                          )?.flag_code || language_learning
                        }
                        svg
                        style={{
                          fontSize: "1.5em",
                          lineHeight: "1.5em",
                        }}
                      />
                    }
                    id="basic-nav-dropdown"
                  >
                    {context.language_pairs.map((pair) => (
                      <NavDropdown.Item
                        key={pair._id}
                        onClick={() => context.setActiveCourse(pair._id)}
                        className={
                          pair.is_selected ? "active-language-pair" : ""
                        }
                      >
                        <ReactCountryFlag
                          countryCode={
                            languages.find(
                              (lang) =>
                                lang.code === pair.language_learning.code
                            )?.flag_code || pair.language_learning.code
                          }
                          svg
                          style={{
                            fontSize: "1.5em",
                            lineHeight: "1.5em",
                            marginRight: "0.5em",
                          }}
                        />
                        {pair.language_learning.name} &#8592;{" "}
                        {pair.language_base.name}
                      </NavDropdown.Item>
                    ))}
                    {/* divider */}
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      key={999}
                      onClick={() => navigate("/account")}
                    >
                      &#43; {t("account:add_course")}
                    </NavDropdown.Item>
                  </NavDropdown>

                  {/* <Nav>
                    <LinkContainer to="/statistics">
                      <Nav.Link>
                        <StreakNumber />
                      </Nav.Link>
                    </LinkContainer>
                  </Nav> */}

                  <Nav>
                    <LinkContainer to="/pricing">
                      <Nav.Link href="/pricing">{planBadge}</Nav.Link>
                    </LinkContainer>
                  </Nav>
                  <Nav>
                    {/* BUG: on desktop the dropdown overflows to right */}
                    <NavDropdown title={t("navigation:settings")}>
                      {/* check if the user email contains a string */}
                      {context.email.includes("atjackiejohns") && (
                        <LinkContainer to="/admin">
                          <NavDropdown.Item href="/admin">
                            Admin &#128524;
                          </NavDropdown.Item>
                        </LinkContainer>
                      )}
                      <LinkContainer to="/account">
                        <NavDropdown.Item href="/account">
                          {t("navigation:account")}
                        </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/pricing">
                        <NavDropdown.Item href="/pricing">
                          {t("navigation:change_plan")}
                        </NavDropdown.Item>
                      </LinkContainer>
                      <NavDropdown.Divider className="d-none d-lg-block" />
                      <NavDropdown.Item
                        href="https://chromewebstore.google.com/detail/lingo-champion/jjbaohhbimlnboamjmdbblhjomhnipff"
                        target="_blank"
                        className="d-none d-lg-block"
                      >
                        {t("navigation:browser_extension")}
                      </NavDropdown.Item>
                      <NavDropdown.Divider className="d-none d-lg-block" />
                      <NavDropdown.Item
                        href="https://lingochampion.freshdesk.com"
                        target="_blank"
                        className="d-none d-lg-block"
                      >
                        {t("navigation:support")}
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <LinkContainer to="/logout">
                        <NavDropdown.Item>
                          {t("navigation:log_out")} <BoxArrowInRight />
                        </NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                  </Nav>
                </>
              ) : (
                <>
                  <LinkContainer to="/login">
                    <Nav.Link>{t("navigation:login")}</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/register">
                    <Nav.Link>{t("navigation:sign_up")}</Nav.Link>
                  </LinkContainer>
                </>
              )}
              {/* Contact link for smaller screens */}
              {/* <LinkContainer to="/https" className="d-md-none">
                <Nav.Link>Pricing</Nav.Link>
              </LinkContainer> */}
              <a
                href="https://chromewebstore.google.com/detail/lingo-champion/jjbaohhbimlnboamjmdbblhjomhnipff"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link d-lg-none"
              >
                {t("navigation:browser_extension")}
              </a>
              <a
                href="https://lingochampion.freshdesk.com"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link d-lg-none"
              >
                {t("navigation:support")}
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Mobile navigation for non-logged-in users */}

      {!context.user_id && (
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="light"
          sticky="top"
          className="d-lg-none mb-5"
          style={{ backgroundColor: "lightblue", fontWeight: 500 }}
          id="mobile-nav-bar-non-logged-in"
        >
          <Container>
            <LinkContainer to="/" className="d-none d-sm-block">
              <Navbar.Brand href="/">
                {" "}
                <img
                  src={chalice}
                  height="30"
                  className="d-inline-block align-top"
                  alt={metaData.appName + " logo"}
                />
              </Navbar.Brand>
            </LinkContainer>
            <LinkContainer to="/" className="d-none d-lg-block">
              <Navbar.Brand>{metaData.appName} </Navbar.Brand>
            </LinkContainer>

            {/* Nav for mobile screens */}
            <Nav className="me-auto d-flex flex-row">
              <LinkContainer to="/">
                <Nav.Link className="me-2">{t("navigation:home")}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/pricing" className="me-2">
                <Nav.Link href="/pricing">{t("navigation:pricing")}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contact" className="me-2">
                <Nav.Link>{t("navigation:contact")}</Nav.Link>
              </LinkContainer>
            </Nav>

            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="ms-3"
            />
            <Navbar.Collapse id="responsive-navbar-nav" style={{ flexGrow: 0 }}>
              <Nav className="ms-auto">
                <>
                  <LinkContainer to="/login">
                    <Nav.Link>{t("navigation:login")}</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/register">
                    <Nav.Link>{t("navigation:sign_up")}</Nav.Link>
                  </LinkContainer>
                </>
                <a
                  href="https://chromewebstore.google.com/detail/lingo-champion/jjbaohhbimlnboamjmdbblhjomhnipff"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link d-lg-none"
                >
                  {t("navigation:browser_extension")}
                </a>
                <a
                  href="https://lingochampion.freshdesk.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link d-lg-none"
                >
                  {t("navigation:support")}
                </a>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}

      {/* Mobile navigation for logged-in users */}
      {context.user_id && (
        <>
          <Navbar
            expand={false}
            sticky="top"
            className="d-lg-none"
            style={{
              backgroundColor: "white",
              // borderBottom: "1px solid lightgray",
            }}
          >
            <Container>
              <div className="d-flex justify-content-between align-items-center">
                <NavDropdown
                  title=<ReactCountryFlag
                    countryCode={
                      languages.find((lang) => lang.code === language_learning)
                        ?.flag_code || language_learning
                    }
                    svg
                    style={{
                      fontSize: "1.5em",
                      lineHeight: "1.5em",
                    }}
                  />
                  id="basic-nav-dropdown"
                >
                  {context.language_pairs.map((pair) => (
                    <NavDropdown.Item
                      key={pair._id}
                      onClick={() => context.setActiveCourse(pair._id)}
                      className={pair.is_selected ? "active-language-pair" : ""}
                    >
                      <ReactCountryFlag
                        countryCode={
                          languages.find(
                            (lang) => lang.code === pair.language_learning.code
                          )?.flag_code || pair.language_learning.code
                        }
                        svg
                        style={{
                          fontSize: "1.5em",
                          lineHeight: "1.5em",
                          marginRight: "0.5em",
                        }}
                      />
                      {pair.language_learning.name} &#8592;{" "}
                      {pair.language_base.name}
                    </NavDropdown.Item>
                  ))}
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    key={999}
                    onClick={() => navigate("/account")}
                  >
                    &#43; {t("account:add_course")}
                  </NavDropdown.Item>
                </NavDropdown>
                <LinkContainer to="/statistics">
                  <Nav.Link className="text-center p-2 ms-2">
                    <StreakNumber />
                  </Nav.Link>
                </LinkContainer>
              </div>

              <LinkContainer to="/pricing">
                <Nav.Link>{planBadge}</Nav.Link>
              </LinkContainer>

              <Navbar.Offcanvas
                id="mobile-nav-dropdown"
                placement="end"
                show={showOffcanvas}
                onHide={toggleOffcanvas}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>{metaData.appName}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    {/* Settings links: Admin, Account, Browser Extension, Support, Log Out */}
                    {context.email.includes("atjackiejohns") && (
                      <LinkContainer to="/admin">
                        <Nav.Link onClick={toggleOffcanvas}>Admin</Nav.Link>
                      </LinkContainer>
                    )}
                    <LinkContainer to="/account">
                      <Nav.Link onClick={toggleOffcanvas}>
                        {t("navigation:account")}
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/pricing">
                      <Nav.Link onClick={toggleOffcanvas}>
                        {t("navigation:change_plan")}
                      </Nav.Link>
                    </LinkContainer>

                    <div
                      style={{
                        height: "1px",
                        backgroundColor: "#ddd",
                        margin: "8px 0",
                      }}
                    />

                    <a
                      href="https://chromewebstore.google.com/detail/lingo-champion/jjbaohhbimlnboamjmdbblhjomhnipff"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link d-lg-none"
                    >
                      {t("navigation:browser_extension")}
                    </a>
                    <div
                      style={{
                        height: "1px",
                        backgroundColor: "#ddd",
                        margin: "8px 0",
                      }}
                    />
                    <a
                      href="https://lingochampion.freshdesk.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link d-lg-none"
                    >
                      {t("navigation:support")}
                    </a>
                    <div
                      style={{
                        height: "1px",
                        backgroundColor: "#ddd",
                        margin: "8px 0",
                      }}
                    />

                    <LinkContainer to="/logout">
                      <Nav.Link onClick={toggleOffcanvas}>
                        {t("navigation:log_out")} <BoxArrowInRight />
                      </Nav.Link>
                    </LinkContainer>
                    <NavDropdown.Divider />
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>

          <Navbar
            fixed="bottom"
            className="d-lg-none pb-3 pt-1 pb-4"
            id="mobile-bottom-nav-bar"
            style={{
              backgroundColor: "white",
              // borderTop: "1px solid lightgray",
              // paddingBottom: "env(safe-area-inset-bottom)",
            }}
          >
            <Container className="p-0">
              <Nav
                className="d-grid w-100"
                style={{
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", // Ensures equal width for each item
                }}
              >
                <LinkContainer
                  to="/library"
                  className="d-flex flex-column align-items-center"
                >
                  <Nav.Link className="text-center p-2">
                    <Book size={20} className="d-block mx-auto mb-1" />
                    <span style={{ fontSize: "0.7rem" }}>
                      {t("navigation:library")}
                    </span>
                  </Nav.Link>
                </LinkContainer>

                <LinkContainer
                  to="/vocabulary"
                  className="d-flex flex-column align-items-center"
                >
                  <Nav.Link className="text-center p-2">
                    <JournalText size={20} className="d-block mx-auto mb-1" />
                    <span style={{ fontSize: "0.7rem" }}>
                      {t("navigation:vocabulary")}
                    </span>
                  </Nav.Link>
                </LinkContainer>

                {/* dropdown for adding a course  */}
                <NavDropdown
                  id="no-caret-dropdown"
                  className="text-center d-flex flex-column align-items-center" // Add these classes to match other items
                  title={
                    <div className="d-flex flex-column align-items-center">
                      <PlusCircle size={20} className="d-block mx-auto mb-1" />
                      <span style={{ fontSize: "0.7rem" }}>
                        {t("navigation:shortcuts")}
                      </span>
                    </div>
                  }
                  drop="up"
                  showCaret={false}
                >
                  <NavDropdown.Item onClick={() => navigate("/library/editor")}>
                    <FileText size={20} className="me-2" />
                    {t("library:import_content")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/library/generator")}
                  >
                    <BodyText size={20} className="me-2" />
                    {t("library:create_content_with_ai")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/library/songs/editor")}
                  >
                    <MusicNoteBeamed size={20} className="me-2" />
                    {t("library:add_song")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/vocabulary/import")}
                  >
                    <ListColumns size={20} className="me-2" />
                    {t("vocabulary:import_words_button_text")}
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => navigate("/chat")}>
                    <ChatSquareText size={20} className="me-2" />
                    {t("library:chat_with_ai")}
                  </NavDropdown.Item>
                </NavDropdown>

                <LinkContainer
                  to="/statistics"
                  className="d-flex flex-column align-items-center"
                >
                  <Nav.Link className="text-center p-2">
                    <GraphUpArrow size={20} className="d-block mx-auto mb-1" />
                    <span style={{ fontSize: "0.7rem" }}>
                      {t("navigation:statistics")}
                    </span>
                  </Nav.Link>
                </LinkContainer>

                <Nav.Link
                  className="text-center p-2 d-flex flex-column align-items-center"
                  onClick={toggleOffcanvas}
                >
                  <List size={20} className="d-block mx-auto mb-1" />
                  <span style={{ fontSize: "0.7rem" }}>
                    {t("navigation:settings")}
                  </span>
                </Nav.Link>
              </Nav>
            </Container>
          </Navbar>
        </>
      )}
    </>
  );
}

export default Navigation;
