import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";

const LearnedWordsChartCumulative = ({ data }) => {
  const { t } = useTranslation();

  // Transform the object data into an array format, convert to minutes, and make it cumulative
  const chartData = Object.entries(data)
    .sort(([dateA], [dateB]) => dateA.localeCompare(dateB)) // Ensure dates are in order
    .reduce((acc, [date, seconds]) => {
      const lastValue = acc.length > 0 ? acc[acc.length - 1].minutes : 0;
      acc.push({
        date,
        minutes: lastValue + Math.round(seconds / 60), // Add to previous total and convert to minutes
      });
      return acc;
    }, []);

  return (
    <ResponsiveContainer width="100%" height={250}>
      <LineChart
        data={chartData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis allowDecimals={false} />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="minutes"
          name={t("statistics:minutes")}
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LearnedWordsChartCumulative;
