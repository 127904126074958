import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";

const BackButtonHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (Capacitor.getPlatform() === "android") {
      const listener = App.addListener("backButton", ({ canGoBack }) => {
        if (canGoBack) {
          window.history.back(); // Go back in navigation history
        } else {
          // If no history exists, show a confirmation before exiting
          App.exitApp(); // Exit the app if no history exists
        }
      });

      return () => {
        listener.remove(); // Cleanup listener on component unmount
      };
    }
  }, [navigate]);

  return null;
};

export default BackButtonHandler;
