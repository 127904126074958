import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation
import { Robot, MusicNoteList } from 'react-bootstrap-icons'; // Adjust based on how you import icons
import { useTranslation } from 'react-i18next';

function GeneratingAudioModal({ articleId, show, setShow, generateAudioIsProcessing, setGenerateAudioIsProcessing, setShowPlaylistSelectionModal, setAudioUrl }) {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [generateAudioMessage, setGenerateAudioMessage] = useState(false);
  const [generateAudioError, setGenerateAudioError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (generateAudioIsProcessing && articleId) {
      generateAudio();
    }
  }, [generateAudioIsProcessing, articleId]);

  async function generateAudio() {
    setError(null);

    try {
      const response = await axios({
        method: 'post',
        url: `/api/text-to-speech/articles/${articleId}`,
      });
      // this is necessary for the reader - otherwise the whole article will be re-processed
      setAudioUrl(response.data.audioUrl);
      setGenerateAudioMessage(true);
    } catch (error) {
      setError(error.message);
      setGenerateAudioError(true);
      console.error('Error getting TTS for article:', error);
    } finally {
      setGenerateAudioIsProcessing(false);
      setShow(false);
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("library:audio_is_being_generated")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p><strong>{t("library:audio_generation_description_1")}</strong> {t("library:audio_generation_description_2")}</p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={generateAudioMessage}
        centered
        onHide={() => setGenerateAudioMessage(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Robot className="me-2 mb-1" />
            {t("library:audio_generation_complete")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("library:audio_generation_complete_description")}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setGenerateAudioMessage(false);
              setShow(false);
              setShowPlaylistSelectionModal(true);
            }}
            className="d-flex align-items-center"
          >
            <MusicNoteList className="me-2" />{t("library:add_to_playlist")}
          </Button>
          <Button
            variant="success"
            onClick={() => {
              navigate("/player/" + articleId);
            }}
          >
            {t("library:listen")} &#8594;
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={generateAudioError}
        centered
        onHide={() => setGenerateAudioError(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Robot className="me-2 mb-1" color="red" />
            {t("library:error_generating_audio")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("library:audio_generation_error_description")}
          </p>
          <p>
            {t("library:audio_generation_error_server_message")}: <br />
            <i>{error}</i>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setGenerateAudioError(false)}
          >
            {t("library:okay_whatever")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export { GeneratingAudioModal };