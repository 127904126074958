import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from "react";
import {
  splitIntoSentences,
  splitIntoTokens,
  checkTranslationPanelProp,
  escapeHTML,
  sendBugReport,
  openSupportCenter,
  checkForVerticalOverflow,
  usePagination,
} from "../utils/ReaderUtils.js";
import {
  Container,
  Row,
  Col,
  Button,
  ProgressBar,
  Dropdown,
} from "react-bootstrap";
import { ThreeDots, Headphones, Magic } from "react-bootstrap-icons";
import "./Reader.css";
import { useLocation } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import TranslationPanel from "./TranslationPanel";
import { checkIfWordIsKnown } from "../utils/ReaderUtils";
import AuthContext from "../context/AuthContext";
import QuickTranslationTooltip from "../Components/QuickTranslationTooltip";

// Create a utility function just for setting span attributes and classes
const configureWordSpan = (span, wordData) => {
  // Set all data attributes
  span.dataset.wordId = wordData.id;
  span.dataset.index = wordData.id;
  span.dataset.known = wordData.is_known ? "true" : "false";
  span.dataset.translation = wordData.knownWord?.translation || "undefined";
  span.dataset.strength = wordData.knownWord?.strength || "undefined";
  span.dataset._id = wordData.knownWord?._id || "undefined";
  span.dataset.is_title = wordData.is_title || "false";
  span.dataset.is_not_a_word = wordData.is_punctuation || "false";
  span.dataset.is_number = wordData.is_number || "false";
  span.dataset.sentence = wordData.sentence || "";

  // Update className to include title class
  span.className = `word${wordData.is_title ? " title" : ""}${
    wordData.is_known && wordData.knownWord?.translation
      ? ` known known-strength-${wordData.knownWord.strength}`
      : wordData.is_known && !wordData.knownWord?.translation
      ? " skipped"
      : wordData.is_punctuation
      ? " punctuation"
      : wordData.is_number
      ? " number"
      : " unknown"
  }`;
};

// Add this cleanup function
const cleanupPhrase = (phrase) => {
  // Remove spaces and punctuation from start and end
  return phrase
    .trim() // Remove spaces from start and end
    .replace(/^[.,!?;:'"\s]+|[.,!?;:'"\s]+$/g, ""); // Remove punctuation from start and end
};

const Reader4 = () => {
  const containerRef = useRef(null);
  const [allWords, setAllWords] = useState([]);
  const [currentArticle, setCurrentArticle] = useState(null);
  const location = useLocation();
  const articleId = location.pathname.split("/")[2];

  // Add context
  const context = useContext(AuthContext);
  useEffect(() => {
    context.fetchKnownWords();
  }, []); // Run once on mount

  const knownPhrases = (context.known_words || []).filter(({ word }) =>
    word.includes(" ")
  );

  const {
    data: articleData,
    loading,
    error,
  } = useFetch(articleId ? `/api/articles/${articleId}` : null);

  // Add effect to process article data when it arrives
  useEffect(() => {
    if (articleData?.article) {
      const article = articleData.article;
      if (articleData.tokens) {
        article.tokens = articleData.tokens;
      }
      setCurrentArticle(article);
    }
  }, [articleData]);

  // Use the custom hook with configureWordSpan
  const { pages, currentPage, changePage } = usePagination(
    allWords,
    containerRef,
    configureWordSpan
  );

  const [selectedWords, setSelectedWords] = useState([]); // State to track selected words
  const isTouching = useRef(false); // Ref to track if a touch event is active
  const isMouseDown = useRef(false); // Ref to track if a mouse down event is active
  const firstTouchedId = useRef(null); // Ref to store the first touched word ID
  const lastTouchedId = useRef(null); // Ref to store the last touched word ID
  const isTouchEvent = useRef(false); // Ref to distinguish between touch and mouse events
  const selectionMade = useRef(false); // Ref to track if a selection was made
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [wordForQuickLookup, setWordForQuickLookup] = useState(null);
  const [wordForFullLookup, setWordForFullLookup] = useState(null);

  // Add state for quick lookup position
  const [quickLookupPosition, setQuickLookupPosition] = useState({
    x: 0,
    y: 0,
  });

  // Add quickLookupRef
  const quickLookupRef = useRef(context.settings?.quick_lookup_in_reader);
  //   const quickLookupRef = useRef(false);

  // Add width state
  const [width, setWidth] = useState(document.documentElement.clientWidth);

  // Add resize effect
  useEffect(() => {
    const handleResize = () => {
      setWidth(document.documentElement.clientWidth);
      if (document.documentElement.clientWidth < 768) {
        setIsFullScreen(true);
      } else {
        setIsFullScreen(false);
      }
    };

    // Call it once on mount
    handleResize();

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    if (window.visualViewport) {
      window.visualViewport.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
      if (window.visualViewport) {
        window.visualViewport.removeEventListener("resize", handleResize);
      }
    };
  }, []); // Empty dependency array means this runs once on mount

  // Add refreshPage function
  const refreshPage = () => {
    if (pages[currentPage]) {
      insertWords(pages[currentPage][0].id);
    }
  };

  // Add closeTranslationsPanel function
  const closeTranslationsPanel = () => {
    document.getElementById("translations").style.display = "none";
    clearTranslationPanelSelectedWord();
  };

  // Process text into words once when text changes
  useEffect(() => {
    // if article is not loaded or context.known_words is undefined, return
    if (!currentArticle || !context.known_words) return;

    const text = currentArticle.title + "\n\n" + currentArticle.body;
    let wordId = 0;
    const sentences = splitIntoSentences(text);

    // Find the title (first sentence before empty lines)
    const titleSentence = sentences[0];

    const words = sentences.flatMap((sentence) => {
      // Check if this sentence is the title
      const isTitle = sentence === titleSentence;

      // Rest of the processing remains the same, just add is_title property
      const knownPhrasesInSentence = knownPhrases.filter(({ word }) => {
        const phraseRegex = new RegExp(`\\b${word}\\b`, "i");
        return phraseRegex.test(sentence);
      });

      if (knownPhrasesInSentence.length > 0) {
        let processedSentence = sentence;
        let tokens = [];

        knownPhrasesInSentence.forEach((phraseObj) => {
          // Split using regex to preserve the exact matches
          const phraseRegex = new RegExp(`(\\b${phraseObj.word}\\b)`, "i");
          const parts = processedSentence.split(phraseRegex);

          // Process each part
          parts.forEach((part) => {
            if (phraseRegex.test(part)) {
              // This part is the exact phrase
              tokens.push({
                id: wordId++,
                text: part,
                is_known: true,
                knownWord: phraseObj,
                is_punctuation: false,
                is_number: false,
                sentence: sentence,
                is_title: isTitle,
              });
            } else if (part) {
              // Process other parts normally
              tokens.push(
                ...splitIntoTokens(part, sentence).map((token) => ({
                  id: wordId++,
                  text: token.token || token,
                  is_known: !!checkIfWordIsKnown(
                    token.token || token,
                    context.known_words
                  ),
                  knownWord: checkIfWordIsKnown(
                    token.token || token,
                    context.known_words
                  ),
                  is_punctuation:
                    /^[.,!?;:'"\s]*$/.test(token.token || token) ||
                    /^\s+$/.test(token.token || token),
                  is_number: /^\d+$/.test(token.token || token),
                  sentence: sentence,
                  is_title: isTitle,
                }))
              );
            }
          });
        });

        return tokens;
      }

      // Regular word processing with is_title added
      return splitIntoTokens(sentence, sentence).map((token) => {
        const knownWord = checkIfWordIsKnown(
          token.token || token,
          context.known_words
        );
        return {
          id: wordId++,
          text: token.token || token,
          is_known: !!knownWord,
          knownWord,
          is_punctuation:
            /^[.,!?;:'"\s]*$/.test(token.token || token) ||
            /^\s+$/.test(token.token || token),
          is_number: /^\d+$/.test(token.token || token),
          sentence: sentence,
          is_title: isTitle,
        };
      });
    });

    setAllWords(words);
  }, [currentArticle, context.known_words]);

  // Shared function for starting selection
  const startSelection = (wordId, isTouch = false) => {
    if (isTouch) {
      isTouching.current = true;
      isTouchEvent.current = true;
    } else {
      isMouseDown.current = true;
    }
    firstTouchedId.current = wordId;
    lastTouchedId.current = wordId;
    highlightWords(wordId, wordId);
  };

  // Add new state/refs for touch timing
  const touchTimeout = useRef(null);
  const touchStartTime = useRef(null);
  const SELECTION_DELAY = 100; // milliseconds to wait before considering it a selection

  // Update handleTouchStart
  const handleTouchStart = (wordId) => {
    touchStartTime.current = Date.now();

    // Clear any existing timeout
    if (touchTimeout.current) {
      clearTimeout(touchTimeout.current);
    }

    // Set a timeout before starting selection
    touchTimeout.current = setTimeout(() => {
      startSelection(wordId, true);
      setSelectedWords([wordId]);
    }, SELECTION_DELAY);
  };

  const handleMouseDown = (wordId) => {
    startSelection(wordId, false);
  };

  // Update handleTouchMove
  const handleTouchMove = (event) => {
    // If we haven't waited long enough, cancel the selection timeout
    if (Date.now() - touchStartTime.current < SELECTION_DELAY) {
      if (touchTimeout.current) {
        clearTimeout(touchTimeout.current);
        touchTimeout.current = null;
      }
      return;
    }

    if (isTouching.current) {
      const touch = event.touches[0];
      const element = document.elementFromPoint(touch.clientX, touch.clientY);
      if (element && element.dataset.wordId) {
        const wordId = parseInt(element.dataset.wordId, 10);
        if (wordId !== lastTouchedId.current) {
          lastTouchedId.current = wordId;
          highlightWords(firstTouchedId.current, lastTouchedId.current);
        }
      }
    }
  };

  // Add this shared function for translation setup
  const setupTranslation = (selectedWordObjects, event = null) => {
    if (selectedWordObjects.length === 0) return;

    const phraseText = cleanupPhrase(
      selectedWordObjects.map((word) => word.text).join("")
    );
    const phraseSentence = selectedWordObjects[0].sentence;

    const wordForTranslation = {
      word: phraseText,
      sentence: phraseSentence,
      is_known:
        selectedWordObjects.length === 1
          ? selectedWordObjects[0].is_known
          : false,
      translation:
        selectedWordObjects.length === 1
          ? selectedWordObjects[0].knownWord?.translation
          : null,
      strength:
        selectedWordObjects.length === 1
          ? selectedWordObjects[0].knownWord?.strength
          : null,
      _id:
        selectedWordObjects.length === 1
          ? selectedWordObjects[0].knownWord?._id
          : null,
    };

    if (quickLookupRef.current) {
      // Get position for tooltip
      let position;
      if (event) {
        // Single word click
        const rect = event.target.getBoundingClientRect();
        position = {
          x: rect.left + rect.width / 2,
          y: rect.bottom + 5,
        };
      } else {
        // Multi-word selection
        const lastWordElement = document.getElementById(
          `word-${selectedWordObjects[selectedWordObjects.length - 1].id}`
        );
        if (lastWordElement) {
          const rect = lastWordElement.getBoundingClientRect();
          position = {
            x: rect.left + rect.width / 2,
            y: rect.bottom + 5,
          };
        }
      }
      setQuickLookupPosition(position);
      setWordForQuickLookup(wordForTranslation);
      setWordForFullLookup(null);
    } else {
      setWordForFullLookup(wordForTranslation);
      setWordForQuickLookup(null);
      document.getElementById("translations").style.display = "block";
    }
  };

  // Update handleTouchEnd
  const handleTouchEnd = () => {
    // Clear the timeout if it hasn't fired yet
    if (touchTimeout.current) {
      clearTimeout(touchTimeout.current);
      touchTimeout.current = null;
    }

    if (isTouching.current) {
      const selectedRange = getWordsBetween(
        firstTouchedId.current,
        lastTouchedId.current
      );
      setSelectedWords(selectedRange);

      const selectedWordObjects = selectedRange
        .map((id) => pages[currentPage].find((word) => word.id === id))
        .filter(Boolean);

      setupTranslation(selectedWordObjects);
      isTouching.current = false;
    }
    isTouchEvent.current = false;
  };

  // Handle mouse move event
  const handleMouseMove = (event) => {
    if (isMouseDown.current) {
      const element = document.elementFromPoint(event.clientX, event.clientY);
      if (element && element.dataset.wordId) {
        const wordId = parseInt(element.dataset.wordId, 10);
        if (wordId !== lastTouchedId.current) {
          lastTouchedId.current = wordId;
          highlightWords(firstTouchedId.current, lastTouchedId.current);
        }
      }
    }
  };

  // Handle mouse up event
  const handleMouseUp = () => {
    if (isMouseDown.current) {
      const selectedRange = getWordsBetween(
        firstTouchedId.current,
        lastTouchedId.current
      );
      setSelectedWords(selectedRange);

      const selectedWordObjects = selectedRange
        .map((id) => pages[currentPage].find((word) => word.id === id))
        .filter(Boolean);

      setupTranslation(selectedWordObjects);
      isMouseDown.current = false;
      selectionMade.current = true;
    }
  };

  // Handle word click event
  const handleWordClick = (wordId, event) => {
    const clickedWord = pages[currentPage].find((word) => word.id === wordId);
    if (!clickedWord) return;

    setSelectedWords([wordId]);
    highlightWords(wordId, wordId);
    selectionMade.current = true;

    setupTranslation([clickedWord], event);
  };

  // Create a shared function that only handles the React state
  const clearSelection = () => {
    setSelectedWords([]);
    console.log("Cleared selection");
  };

  // The DOM manipulation will happen automatically through the useEffect
  // that watches selectedWords
  useEffect(() => {
    document.querySelectorAll("[data-word-id]").forEach((element) => {
      const wordId = parseInt(element.dataset.wordId);
      if (selectedWords.includes(wordId)) {
        element.classList.add("highlighted");
      } else {
        element.classList.remove("highlighted");
      }
    });
  }, [selectedWords]);

  const handleContainerClick = (event) => {
    if (!isTouchEvent.current && !selectionMade.current) {
      const element = event.target;
      if (!element.dataset.wordId) {
        clearSelection();
        // Clear translation panel
        setWordForFullLookup(null);
        setWordForQuickLookup(null);
      }
    }
    selectionMade.current = false;
  };

  // Highlight words between startId and endId
  const highlightWords = (startId, endId) => {
    document.querySelectorAll("[data-word-id]").forEach((element) => {
      element.classList.remove("highlighted");
    });

    getWordsBetween(startId, endId).forEach((id) => {
      const wordElement = document.querySelector(`[data-word-id='${id}']`);
      if (wordElement) {
        wordElement.classList.add("highlighted");
      }
    });
  };

  // Get word IDs between startId and endId
  const getWordsBetween = (startId, endId) => {
    const start = Math.min(startId, endId);
    const end = Math.max(startId, endId);
    const allWords = pages.flat();
    return allWords.slice(start, end + 1).map((word) => word.id);
  };

  // Get the text of selected words
  const selectedWordsText = selectedWords
    .map((id) => pages[currentPage].find((word) => word.id === id)?.text)
    .join("");

  // Add cleanup on unmount
  useEffect(() => {
    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = "";
      }
    };
  }, []);

  // Render the current page using pure DOM manipulation
  const renderCurrentPage = useCallback(() => {
    const container = containerRef.current;
    if (!container || !pages[currentPage]) return;

    // Clear container
    container.textContent = "";

    // Render each word using DOM manipulation
    pages[currentPage].forEach((wordData) => {
      const span = document.createElement("span");
      span.textContent = wordData.text;
      span.id = `word-${wordData.id}`;
      configureWordSpan(span, wordData);

      // Keep existing event listeners
      span.addEventListener("touchstart", () => handleTouchStart(wordData.id));
      span.addEventListener("mousedown", () => handleMouseDown(wordData.id));
      span.addEventListener("click", (event) =>
        handleWordClick(wordData.id, event)
      );

      container.appendChild(span);
    });
  }, [currentPage, pages]);

  // Use effect to render the page when needed
  useEffect(() => {
    renderCurrentPage();
  }, [renderCurrentPage]);

  // Add function to clear translation panel
  const clearTranslationPanelSelectedWord = () => {
    setSelectedWords([]);
    setWordForFullLookup(null);
    setWordForQuickLookup(null);
    document.querySelectorAll(".highlighted").forEach((word) => {
      word.classList.remove("highlighted");
    });
  };

  // Add functions for word management
  const addUnknownWordToKnownWords = (word, translation, strength) => {
    let newWord = {
      word: word,
      translation: translation,
      strength: strength,
    };
    console.log("Sending this word to backend for updating: ", newWord);
    context.saveNewWordToBackend(newWord, currentArticle._id);
  };

  const updateKnownWordInKnownWords = (wordToUpdate, translation, strength) => {
    console.log("checking updated known word: ", wordToUpdate);
    console.log("the known word translation is: ", translation);
    console.log("the strength of the updated word should be: ", strength);
    context.updateKnownWord(wordToUpdate, translation, strength);
  };

  // Update insertWords to use pages from usePagination
  const insertWords = (startIndex) => {
    const textContentWordsDiv = document.getElementById("textContentWords");
    if (!textContentWordsDiv) return startIndex;

    // Clear existing content
    textContentWordsDiv.innerHTML = "";

    // Get the current page's words
    const currentPageWords = pages[currentPage];
    if (!currentPageWords) return startIndex;

    // Insert each word
    currentPageWords.forEach((wordData) => {
      const span = document.createElement("span");
      span.textContent = wordData.text;
      span.id = `word-${wordData.id}`;
      configureWordSpan(span, wordData);

      // Add event listeners
      span.addEventListener("touchstart", () => handleTouchStart(wordData.id));
      span.addEventListener("mousedown", () => handleMouseDown(wordData.id));
      span.addEventListener("click", (event) =>
        handleWordClick(wordData.id, event)
      );

      textContentWordsDiv.appendChild(span);
    });

    return startIndex + currentPageWords.length - 1;
  };

  // Add cleanup for touch timeout
  useEffect(() => {
    return () => {
      if (touchTimeout.current) {
        clearTimeout(touchTimeout.current);
      }
    };
  }, []);

  // Add near other state declarations
  const [startX, setStartX] = useState(null);
  const [touchStartTimeRef, setTouchStartTimeRef] = useState(null);
  const [isSwipeInProgress, setIsSwipeInProgress] = useState(false);
  const SWIPE_THRESHOLD = 30;
  const LONG_PRESS_DURATION = 100;

  const handleReaderTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setTouchStartTimeRef(Date.now());
    setIsSwipeInProgress(false);

    // Only start word selection if we're not swiping
    if (e.target.dataset.wordId) {
      handleTouchStart(parseInt(e.target.dataset.wordId));
    }
  };

  const handleReaderTouchMove = (e) => {
    const touchDuration = Date.now() - touchStartTimeRef;

    // If already swiping, don't allow word selection
    if (isSwipeInProgress) {
      return;
    }

    // If touch has been held longer than LONG_PRESS_DURATION, prioritize word selection
    if (touchDuration > LONG_PRESS_DURATION) {
      handleTouchMove(e);
      return;
    }

    // Handle swipe for quick touches
    if (startX !== null) {
      const xDiff = startX - e.touches[0].clientX;

      if (Math.abs(xDiff) > SWIPE_THRESHOLD) {
        setIsSwipeInProgress(true);
        if (xDiff > 0 && currentPage < pages.length - 1) {
          changePage(currentPage + 1);
          setStartX(null);
        } else if (xDiff < 0 && currentPage > 0) {
          changePage(currentPage - 1);
          setStartX(null);
        }
      }
    }
  };

  const handleReaderTouchEnd = (e) => {
    setStartX(null);
    setTouchStartTimeRef(null);
    setIsSwipeInProgress(false);

    // Only handle word selection end if we weren't swiping
    if (!isSwipeInProgress) {
      handleTouchEnd(e);
    }
  };

  if (loading) return <div>Loading article...</div>;
  if (error) return <div>Error loading article: {error.message}</div>;
  if (!currentArticle) return <div>No article found</div>;

  return (
    <Container fluid="md">
      <Row style={{ minHeight: "100svh" }}>
        <Col className="d-flex flex-column">
          {/* Header Panel */}
          <Row className="justify-content-center align-items-center pt-3">
            <Col xs={2} md={1} className="d-flex justify-content-center">
              <Button variant="light" size="sm">
                &#10006;
              </Button>
            </Col>
            <Col xs={8} md={10} className="text-center">
              <ProgressBar
                now={currentPage}
                min={0}
                max={pages.length - 1}
                style={{ height: "5px" }}
              />
            </Col>
            <Col xs={2} md={1} className="d-flex justify-content-center mb-1">
              <Dropdown align="end">
                <Dropdown.Toggle
                  as="button"
                  variant="link"
                  className="p-0 border-0 bg-transparent"
                >
                  <ThreeDots size={20} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>Settings 1</Dropdown.Item>
                  <Dropdown.Item>Settings 2</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          {/* Main Content Panel */}
          <Row className="flex-grow-1 g-0">
            <Col xs="auto" className="d-flex align-items-center px-1">
              <Button
                variant="light"
                size="sm"
                className="arrowButton"
                style={{ width: "3em" }}
                onClick={() => changePage(Math.max(currentPage - 1, 0))}
                disabled={currentPage === 0}
              >
                &#8249;
              </Button>
            </Col>

            <Col>
              <div
                ref={containerRef}
                style={{
                  height: "100%",
                  overflow: "hidden",
                  position: "relative",
                  fontSize: "16px",
                  lineHeight: "1.5",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  wordBreak: "break-word",
                  userSelect: "none",
                  WebkitUserSelect: "none",
                  WebkitTouchCallout: "none",
                  msUserSelect: "none",
                  MozUserSelect: "none",
                  touchAction: "pan-y pinch-zoom",
                }}
                onTouchStart={(e) => {
                  e.preventDefault();
                  handleReaderTouchStart(e);
                }}
                onTouchMove={handleReaderTouchMove}
                onTouchEnd={handleReaderTouchEnd}
                onClick={handleContainerClick}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
              />
            </Col>

            <Col xs="auto" className="d-flex align-items-center px-1">
              <Button
                variant="light"
                size="sm"
                className="arrowButton"
                style={{ width: "3em" }}
                onClick={() =>
                  changePage(Math.min(currentPage + 1, pages.length - 1))
                }
                disabled={currentPage === pages.length - 1}
              >
                &#8250;
              </Button>
            </Col>
          </Row>

          {/* Footer Panel */}
          <Row className="mb-3">
            <Col className="d-flex justify-content-center">
              <Dropdown className="mx-2">
                <Dropdown.Toggle variant="light" size="lg">
                  <Headphones className="me-2" />
                  Listen
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>Generate Audio</Dropdown.Item>
                  <Dropdown.Item>Listen to Article</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="mx-2">
                <Dropdown.Toggle variant="light" size="lg">
                  <Magic className="me-2" />
                  Simplify
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>Simplify with AI</Dropdown.Item>
                  <Dropdown.Item>Make it Ghetto</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Col>

        {/* Translation Panel */}
        <Col
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={3}
          id="translations"
          className={isFullScreen ? "full-screen" : ""}
        >
          <TranslationPanel
            selectedWord={wordForFullLookup}
            setSelectedWord={setWordForFullLookup}
            refreshPage={refreshPage}
            addUnknownWordToKnownWords={addUnknownWordToKnownWords}
            updateKnownWordInKnownWords={updateKnownWordInKnownWords}
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
            closeTranslationsPanel={closeTranslationsPanel}
            fullTextTokens={pages.flat()}
            clearTranslationPanelSelectedWord={
              clearTranslationPanelSelectedWord
            }
          />
        </Col>
      </Row>

      {/* Add QuickTranslationTooltip at the end of the container */}
      <QuickTranslationTooltip
        selectedWord={wordForQuickLookup}
        setSelectedWord={setWordForQuickLookup}
        setWordForFullLookup={setWordForFullLookup}
        addUnknownWordToKnownWords={addUnknownWordToKnownWords}
        updateKnownWordInKnownWords={updateKnownWordInKnownWords}
        speakOnRender={true}
        position={quickLookupPosition}
      />
    </Container>
  );
};

export default Reader4;
