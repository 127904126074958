import React, { useState, useContext } from "react";
import { Card, Col, Button, Row, Badge } from "react-bootstrap";
import { Book, Mortarboard, Collection } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import PlaceholderImg from "../chalice-150x150.png";
import BookDetailsModal from "./CollectionDetailsModal";
import { useTranslation } from "react-i18next";
import AuthContext from "../context/AuthContext.js";


export default function CollectionCard({ collection, index }) {
  const { t } = useTranslation();
  const context = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  // Function to calculate read badges and percentages (not displayed for now)
  const calculateReadBadges = () => {
    // Placeholder function logic
    return {
      readPercentage: 0, // Example value
      unreadPercentage: 100, // Example value
    };
  };

  // Function to determine badge color based on read percentage
  const getBadgeColor = (percentage) => {
    if (percentage > 75) return "success";
    if (percentage > 50) return "warning";
    return "danger";
  };

  // Call the function to ensure it's retained
  const { readPercentage, unreadPercentage } = calculateReadBadges();

  return (
    <Col key={index} xs={12} className="mb-4">
      <Card>
        <Card.Body>
          <Row className="flex-column flex-md-row">
            <Col xs={12} md={2}>
              <div
                style={{
                  width: "100%",
                  paddingBottom: "56.25%",
                  position: "relative",
                }}
                className="mb-3 mb-md-0"
              >
                <div
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <Card.Img
                    variant="top"
                    src={collection?.cover_image || PlaceholderImg}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: collection?.cover_image
                        ? "cover"
                        : "scale-down",
                      cursor: "pointer",
                    }}
                    onClick={handleShow}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = PlaceholderImg;
                      e.target.style.objectFit = "scale-down";
                    }}
                  />
                </div>
                {/* <Badge
                  bg="warning"
                  text="dark"
                  className="me-2 fs-7"
                  style={{ position: "absolute", top: "0.5em", left: "0.5em" }}
                >
                  {collection?.type === "book" && <Book className="me-2" />}
                  {collection?.type === "course" && (
                    <Mortarboard className="me-2" />
                  )}
                  {collection?.type === "other" && (
                    <Collection className="me-2" />
                  )}
                  {collection?.type}
                </Badge> */}
              </div>
            </Col>
            <Col xs={12} md={10}>
              <Card.Title>
                <span onClick={handleShow} style={{ cursor: "pointer" }}>
                  {collection?.type === "book" && <Book className="me-2" />}
                  {collection?.type === "course" && (
                    <Mortarboard className="me-2" />
                  )}
                  {collection?.type === "other" && (
                    <Collection className="me-2" />
                  )}
                  {collection?.title}
                </span>
              </Card.Title>

              <Card.Subtitle
                className="mb-2 text-muted"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: window.innerWidth < 768 ? 3 : 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {collection?.description ||
                  t(
                    "library:collection_details_modal_no_description_available"
                  )}
              </Card.Subtitle>
              {/* <Card.Text>
              </Card.Text> */}
              {/* <Link
                to={`/reader/${collection?.articles[0]?.article_id}?collectionId=${collection?._id}`}
              >
                <Button variant="outline-dark" className="btn-sm me-2 mb-2">
                  {t("library:read_and_study")}
                </Button>
              </Link> */}
              <div className="mt-3">
                <Button
                  variant="outline-dark"
                  className="btn-sm me-2 mb-2"
                  onClick={handleShow}
                >
                  {t("library:collection_card_show_details")}
                  <Badge bg="warning" text="dark" className="ms-2">
                    {collection?.articles?.length}
                  </Badge>
                </Button>
                {context.user_id === collection?.created_by && (
                  <Link to={`/library/collection/editor/${collection?._id}`}>
                    <Button variant="outline-dark" className="btn-sm mb-2">
                      {t("common:edit")}
                    </Button>
                  </Link>
                )}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <BookDetailsModal
        showModal={showModal}
        handleClose={handleClose}
        collectionId={collection?._id}
      />
    </Col>
  );
}
