import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Dropdown, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
// import playlistModal
import PlaylistModal from "../Components/PlaylistModal.js";
import QuickTranslationTooltip from "../Components/QuickTranslationTooltip.js";
// import table landscape icon
import { ThreeDots, Bug, InfoCircle } from "react-bootstrap-icons";
import "./Music.css";
import YouTube from "react-youtube";
import { useTranslation } from "react-i18next";

import AuthContext from "../context/AuthContext.js";

// import articleData from "./Reader-example.json";
// import knownWords from "./Reader-known-words.json";
import { useLocation } from "react-router-dom";

import {
  splitIntoTokens,
  checkTranslationPanelProp,
  escapeHTML,
  sendBugReport,
  openSupportCenter,
} from "../utils/ReaderUtils.js";

import { useNavigate } from "react-router-dom";

import axios from "axios";

// import css for Reader
import "./Reader.css";
import TranslationPanel from "./TranslationPanel.js";
import { checkIfWordIsKnown } from "../utils/ReaderUtils.js";

// BUG: if you finish the article and navigate back to Dashboard and read the same article, the words are kept in memory and the words are not marked from the beginning
let tokens = [];
/* 
  {
    _id: "id",
    date_added: "date",
    word: "word",
    known: true,
    is_title: true,
    is_last_title_word: true,
    is_not_a_word: true,
    is_number: false,
    translation: "translation",
    strength: 2,

    is_number: true,
    is_punctuation: true,
  }
*/
function App() {
  const { t } = useTranslation();
  const context = React.useContext(AuthContext);
  // const [tokens, setTokens] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [knownWordsArray, setKnownWordsArray] = useState(null);
  const [knownWordsAtTheStart, setKnownWordsAtTheStart] = useState([]);
  const [articleIsInserted, setArticleIsInserted] = useState(false);

  const [showPlaylistSelectionModal, setShowPlaylistSelectionModal] =
    useState(false);
  const [selectedWord, setSelectedWord] = useState(null);
  const [wordForQuickLookup, setWordForQuickLookup] = useState(null);
  const [wordForFullLookup, setWordForFullLookup] = useState(null);
  const quickLookupRef = useRef(context.settings?.quick_lookup_in_music);
  const expandAIExplanationBoxRef = useRef(
    context.settings?.expand_ai_explanation_box
  );
  // State variables to manage the text content
  const [selectedWordOriginalTextState, setSelectedWordOriginalTextState] =
    useState({});

  let navigate = useNavigate();

  // Prevents default context menu on right-click or long-press (this should include mobile text selection)
  // NOTE: it does not work on Android because onTouchEnd somehow gets triggered differently (after closing the default context menu - preventDefault doesn't work)
  const handleContextMenu = (e) => {
    e.preventDefault();

    // Check if the device is Android
    const isAndroid = /Android/i.test(navigator.userAgent);

    if (isAndroid) {
      // Check if the user is currently touching the screen
      if (!e.touches || e.touches.length === 0) {
        // BUG: the following hack works but not for Thai because there are no spaces there
        const selection = window.getSelection().toString().trim();
        // if the selection contains multiple words, trigger the handleTextSelection function
        // Otherwise this event gets triggered already when long pressing on the screen
        if (selection) {
          const words = selection
            .split(/\s+/)
            .filter((word) => word.length > 0);
          if (words.length > 1) {
            handleTextSelection();
          }
        }
      }
    }
  };

  // NOTE: this is purely bc tokens is declared outside the component and the values would persist even when the component gets unmounted (moving betw library and reader)
  useEffect(() => {
    if (tokens.length > 0) tokens = [];
  }, []);

  useEffect(() => {
    console.log("settings changed:", context.settings);
  }, [context.settings]);

  useEffect(() => {
    console.log("Quick lookup in music changed: ", quickLookupRef.current);
  }, [quickLookupRef.current]);

  useEffect(() => {
    console.log("Selected word changed: ", selectedWord);
    // decide whether to use quick lookup or full lookup
    if (selectedWord?.word && selectedWord.word.trim() !== "") {
      if (quickLookupRef.current) {
        console.log("Should use quick lookup for the word: ", selectedWord);
        setWordForQuickLookup(selectedWord);
      } else {
        console.log("Should use full lookup for the word: ", selectedWord);
        setWordForFullLookup(selectedWord);
      }
    }
    // REVIEW: there probably should be a for cleanup
  }, [selectedWord]);

  // useEffect for articleIsInserted
  useEffect(() => {
    console.log("articleIsInserted changed: ", articleIsInserted);
    if (!articleIsInserted) {
      // set the content of the textContentWords div to empty
      document.getElementById("textContentWords").innerHTML =
        "Loading the lyrics..";
    }
  }, [articleIsInserted]);

  // Function to process a text fragment for phrases and tokens
  function processFragment(fragment, sentence) {
    let phrases = knownWordsArray.filter(
      (obj) => obj && obj.word && obj.word.includes(" ")
    );
    let lowerCaseFragment = fragment.toLowerCase();
    let phraseMatches = [];

    // Find all matches first
    phrases.forEach((phrase) => {
      let lowerCasePhrase = phrase.word.toLowerCase();
      let phraseIndex = lowerCaseFragment.indexOf(lowerCasePhrase);
      while (phraseIndex !== -1) {
        phraseMatches.push({
          index: phraseIndex,
          length: phrase.word.length,
          phrase: phrase.word,
        });
        phraseIndex = lowerCaseFragment.indexOf(
          lowerCasePhrase,
          phraseIndex + 1
        );
      }
    });

    // Sort the matches by their start index; in case of a tie, longer phrases first
    phraseMatches.sort((a, b) => a.index - b.index || b.length - a.length);

    let processedFragments = [];
    let currentIndex = 0;

    // Process the matches in order and handle overlaps
    phraseMatches.forEach((match) => {
      if (match.index >= currentIndex) {
        if (currentIndex < match.index) {
          processedFragments.push(
            ...splitIntoTokens(
              fragment.substring(currentIndex, match.index),
              sentence
            )
          );
        }
        processedFragments.push({
          token: fragment.substring(match.index, match.index + match.length),
          sentence,
        });
        currentIndex = match.index + match.length;
      }
    });

    // Process any remaining text
    if (currentIndex < fragment.length) {
      processedFragments.push(
        ...splitIntoTokens(fragment.substring(currentIndex), sentence)
      );
    }

    return processedFragments;
  }

  // Function to split text by URLs and process each fragment
  function splitByURLsAndProcess(sentence) {
    const urlRegex = /https?:\/\/[^\s]+/g;
    let splitText = sentence.split(urlRegex);
    let urlMatches = sentence.match(urlRegex) || [];

    let finalTokens = [];
    splitText.forEach((fragment, index) => {
      finalTokens.push(...processFragment(fragment, sentence));
      if (urlMatches[index]) {
        finalTokens.push({ token: urlMatches[index], sentence });
      }
    });

    return finalTokens;
  }

  // Function to process the entire text
  function processText(text) {
    console.log("processText: Processing text:", text); // Debugging statement

    // Replace double line breaks with a unique placeholder
    const doubleLineBreakPlaceholder = "__DOUBLE_LINE_BREAK__";
    const singleLineBreakPlaceholder = "__SINGLE_LINE_BREAK__";

    text = text.replace(/\n{2}/g, doubleLineBreakPlaceholder);
    text = text.replace(/\n/g, singleLineBreakPlaceholder);

    // Split the text into fragments by placeholders
    let fragments = text.split(
      new RegExp(
        `(${doubleLineBreakPlaceholder}|${singleLineBreakPlaceholder})`
      )
    );

    console.log("processText: Fragments found:", fragments); // Debugging statement

    if (fragments.length === 0) {
      console.log("processText: No fragments to process.");
      return [];
    }

    // Process each fragment separately
    return fragments.flatMap((fragment) => {
      if (fragment === doubleLineBreakPlaceholder) {
        return [{ token: "\n\n", sentence: "" }];
      } else if (fragment === singleLineBreakPlaceholder) {
        return [{ token: "\n", sentence: "" }];
      } else {
        return splitByURLsAndProcess(fragment);
      }
    });
  }

  // fetch known words from the backend
  useEffect(() => {
    const fetchAndSetKnownWords = async () => {
      try {
        await context.fetchKnownWords();
        setKnownWordsAtTheStart(context.known_words || []);
      } catch (error) {
        console.error("Error fetching known words:", error);
      }
    };

    fetchAndSetKnownWords();
  }, []);

  // create useEffect for SelectedWordOriginalTextState
  useEffect(() => {
    console.log(
      "SelectedWordOriginalTextState changed: ",
      selectedWordOriginalTextState
    );
  }, [selectedWordOriginalTextState]);

  const [currentArticle, setCurrentArticle] = useState(null);

  let location = useLocation();
  // fetch the article data from the backend and tokenize it
  useEffect(() => {
    console.log("New location: ", location.pathname);
    // setCurrentArticle(exampleSong);
    // return;
    const articleId = location.pathname.split("/")[2];
    console.log("Get the song for songId:", articleId);
    axios
      .get(`/api/songs/${articleId}`)
      .then((response) => {
        if (response.data.tokens)
          response.data.song.tokens = response.data.tokens;
        setCurrentArticle(response.data.song); // Assuming your API returns the article data
        console.log("Got the song from backend: ", response.data.song);
        trackSongListened(articleId, "webapp", response.data.song.language);
        // console.log("Received article data:", articleData);
        // addArticleToUserLibrary(response.data.article._id);
      })
      .catch((error) => {
        console.error("Error fetching song:", error);
        // Handle the error appropriately
      });
  }, [location]);

  // useEffect for knownWordsArray and article
  useEffect(() => {
    if (!currentArticle || knownWordsArray === null) {
      console.log(
        "currentArticle or knownWordsArray is empty. Not going to tokenize the article yet or calculate pages."
      );
      return;
    }
    // if (articleIsInserted) {
    //   console.log(
    //     "Article is already inserted. Not going to tokenize the article again or calculate pages."
    //   );
    //   return;
    // }
    // Update your state or perform any necessary operations with the article data
    const articleBody = currentArticle.lyrics;
    const articleTitle = currentArticle.title;

    if (currentArticle.tokens) {
      console.log("Tokens already exist for the article.");
      // make a deep copy of the currentArticle.tokens
      let currentArticleTokens = JSON.parse(
        JSON.stringify(currentArticle.tokens)
      );
      console.log("Current article tokens 1: ", currentArticleTokens);
      // currentArticleTokens.title.words  - loop through the array and add .sentence property to each .word
      function transformWordsArray(array) {
        return array.map((item) => {
          return {
            ...item, // Keep the original sentence
            words: item.words.map((word) => ({
              word: word, // Each word becomes an object
              sentence: item.sentence, // Retain the original sentence
            })),
          };
        });
      }
      function transformTitleObject(titleObj) {
        return {
          ...titleObj, // Keep the original sentence
          words: titleObj.words.map((word) => ({
            word: word, // Each word becomes an object
            sentence: titleObj.sentence, // Retain the original sentence
          })),
        };
      }

      currentArticleTokens.body = transformWordsArray(
        currentArticleTokens.body
      );
      currentArticleTokens.title = transformTitleObject(
        currentArticleTokens.title
      );

      // loop through currentArticleTokens.body array and merge the words into one array
      let finalBodyWordTokens = currentArticleTokens.body.reduce(
        (acc, item) => {
          return acc.concat(item.words);
        },
        []
      );

      let finalTitleWordTokens = currentArticleTokens.title.words;

      // currentArticleTokens.body.words - each word should have the sentence property which equals the sentence
      console.log("current article tokens: ", currentArticleTokens);
      // TODO: need to take care of the title as well
      console.log(
        "final current article title tokens: ",
        currentArticleTokens.title
      );
      console.log("final current article title tokens: ", finalTitleWordTokens);
      console.log("final current article body tokens: ", finalBodyWordTokens);
      let titleTokensToMerge = createWords(
        finalTitleWordTokens,
        knownWordsArray,
        "title"
      );
      let bodyTokensToMerge = createWords(
        finalBodyWordTokens,
        knownWordsArray,
        "body"
      );
      tokens = titleTokensToMerge.concat(bodyTokensToMerge);
      // create words from the tokens
      // let titleTokens = createWords(currentArticleTokens.title, knownWordsArray, "title");
      // let bodyTokens = createWords(currentArticleTokens.body, knownWordsArray, "body");
    } else {
      console.log("Tokens do not exist for the article.");
      let finalWords = processText(articleBody, knownWordsArray);
      let finalArticleTitle = processText(articleTitle, knownWordsArray);

      // Processing the article title
      // const finalArticleTitle = articleTitle
      //   .split(/(\s+|\p{P}+|\p{L}+(?:\p{M}*\p{N}*)*)/gu)
      //   .filter((token) => token !== "")
      //   .map((token) => ({ token, sentence: articleTitle }));

      console.log("FINALARTICLETITLE: ", finalArticleTitle);
      console.log("FINALWORDS: ", finalWords);

      let titleTokens = createWords(
        finalArticleTitle,
        knownWordsArray,
        "title"
      );
      let bodyTokens = createWords(finalWords, knownWordsArray, "body");
      tokens = titleTokens.concat(bodyTokens);
    }

    console.log("TOKENS: ", tokens);
    if (!articleIsInserted) {
      setArticleIsInserted(true);
    }

    if (knownWordsArray === null) return;
    console.log("knownWordsArray changed: ", knownWordsArray);
    // REVIEW: there is probably no need to update the words because I'm now rekotenizing the article every time the knownWordsArray changes anyway
    console.log("Calling the refreshPage function");
    refreshPage();
  }, [knownWordsArray, currentArticle]);

  // create useEffect for knownWordsArray
  useEffect(() => {
    return;
  }, [knownWordsArray]);

  useEffect(() => {
    console.log("Known words array changed in the context.");
    // set the knownWordsArray state variable to the new value
    setKnownWordsArray(context.known_words || []);
  }, [context.known_words]);

  // function toggleFullScreen() {
  //   setIsFullScreen(!isFullScreen);
  // }

  async function addArticleToUserLibrary(
    articleId,
    percentComplete = 0,
    wordsRead = 0
  ) {
    // get the selected language pair ID from the context
    let languagePairId;
    if (context.language_pairs) {
      for (const pair of context.language_pairs) {
        console.log("Selected language pair (in Reader): ", pair);
        if (pair.is_selected) {
          languagePairId = pair._id;
          break;
        }
      }
    }
    console.log("Language pair ID is (in Reader): ", languagePairId);

    try {
      console.log("Trying to add article to user library.");
      const response = await axios.post("/api/user/add-article-to-library", {
        article_id: articleId,
        language_pair_id: languagePairId,
        percent_complete: percentComplete,
        words_read: wordsRead,
      });
      console.log("Response from adding article to user library: ", response);
    } catch (error) {
      console.error(
        "There was a problem with adding the article to the user library:",
        error
      );
    }
  }

  // BUG: swiping on mobile doesn't work properly - it's impossible to select text without swiping
  const [startX, setStartX] = useState(null);

  const handleTouchStart = (e) => {
    // e.stopPropagation();
    setStartX(e.touches[0].clientX);
  };

  // close the translations panel when the user swipes down on it
  // NOTE: this works even when the panel is not full screen
  const [startY, setStartY] = useState(null); // New state variable for Y-coordinate

  const handleTouchStartTranslationsPanel = (e) => {
    setStartY(e.touches[0].clientY); // Store the Y-coordinate at touch start
  };

  const handleTouchMoveTranslationsPanel = (e) => {
    // if a selection was made, don't count it as a swipe
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      return; // Exit function if text is selected
    }

    if (startY === null) {
      return;
    }

    const yDiff = startY - e.touches[0].clientY; // Calculate Y-coordinate difference

    // if the user is scrolling down more than 50% of the viewport height, close the translations panel
    // originally used just 500 in absolute units but it's less reliable bc screen sizes vary
    if ((Math.abs(yDiff) / document.documentElement.clientHeight) * 100 > 50) {
      // Threshold for minimal swipe distance
      if (yDiff < 0) {
        closeTranslationsPanel(); // Call closeTranslationsPanel on swipe down
        // hide translations panel
        document.getElementById("translations").style.display = "none";
      }

      setStartY(null); // Reset startY so that the swipe is only counted once
    }
  };

  // handle mouse clicks and touch events and selection
  useEffect(() => {
    // Get the element by ID
    const textContentElement = document.getElementById("textContent");

    // Add event listeners if the element exists
    // NOTE: can't add to document because it doesn't work on mobile - translation panel clicks would be counted as well
    if (textContentElement) {
      textContentElement.addEventListener("mouseup", handleTextSelection);
      textContentElement.addEventListener("touchend", handleTextSelection); // for touch screens
    }

    // Cleanup function to remove event listeners
    return () => {
      if (textContentElement) {
        document.removeEventListener("mouseup", handleTextSelection);
        document.removeEventListener("touchend", handleTextSelection); // for touch screens
      }
    };
  }, []);

  const handleTextSelection = (e) => {
    const selection = window.getSelection();
    console.log("SELECTION: ", selection);
    console.log("SELECTION EVENT: ", e);

    // if the click is on the translation panel, do nothing - don't clear the selection or highlights
    let anchorNode = selection.anchorNode;
    if (anchorNode && anchorNode.nodeType === Node.TEXT_NODE) {
      anchorNode = anchorNode.parentNode;
    }
    // alert("anchorNode: " + anchorNode.classList)

    // if the click is on the translation panel, do nothing - don't clear the selection or highlights
    const propExists = checkTranslationPanelProp(anchorNode);
    if (anchorNode) {
      console.log(`Does --translation-panel exist on the parent?`, propExists);
      if (propExists) {
        // alert("Prop exists: " + propExists)
        // BUG: it does not work on mobile with the full screen panel and text selection
        // BUG: on mobile the text selection doesn't work across the lines - the space between the lines is not included in the selection
        return;
      }
    }

    clearTranslationPanelSelectedWord();

    // return;

    // phrase selection
    if (selection.rangeCount > 0 && selection.type === "Range") {
      const baseNode = selection.baseNode;
      // extentNode is the last node in the selection
      // baseNode is the first node in the selection
      const extentNode = selection.extentNode;
      console.log("baseNode: ", baseNode);
      // console.log("baseNode parent's sentence: ", baseNode.parentElement.getAttribute('data-sentence'));
      console.log("extentNode: ", extentNode);
      let firstWordIndex = parseInt(baseNode.parentElement.id.split("-")[1]);
      console.log("firstWordIndex: ", firstWordIndex);
      let lastWordIndex = parseInt(extentNode.parentElement.id.split("-")[1]);
      console.log("lastWordIndex: ", lastWordIndex);
      // switch the indexes if the selection was made from right to left
      if (lastWordIndex < firstWordIndex) {
        const temp = lastWordIndex;
        lastWordIndex = firstWordIndex;
        firstWordIndex = temp;
      }
      const selectedText = selection.toString().trim();
      const words = selectedText.split(/\s+/).filter((word) => /\w/.test(word)); // Split and filter out non-word elements
      console.log("WORDS: ", words.length, words);

      console.log("Number of words in selection:", words.length); // Log the number of words

      if (words.length > 30) {
        // NOTE: The delay is only needed for iOS - it doesn't work without it
        setTimeout(() => {
          selection.removeAllRanges();
        }, 100);
        return console.log(
          "The selection contains too many words - not sending it to translation."
        );
      }
      // loop through the words between firstWordIndex and lastWordIndex
      let selectedPhrase = { word: "", is_phrase: false };
      for (let i = firstWordIndex; i <= lastWordIndex; i++) {
        // highlight the word
        const word = document.getElementById(`word-${i}`);
        selectedPhrase.word += word.innerText;
        selectedPhrase.is_phrase = true;
        word.classList.add("highlighted");
      }
      // clear selection
      // NOTE: the delay is only needed because of iOS - it doesn't work without it
      setTimeout(() => {
        selection.removeAllRanges();
      }, 100);
      document.getElementById("textContent").focus();
      // clear spaces from both sides of the selected text
      selectedPhrase.word = selectedPhrase.word.trim();
      selectedPhrase.word = removePunctuation(selectedPhrase.word);
      selectedPhrase.sentence =
        baseNode.parentElement.getAttribute("data-sentence");
      console.log("Selected phrase: ", selectedPhrase);
      // send the selected text to the translated panel displayer
      // if (openTranslationPanelByDefaultRef.current) {
      //   showTranslationPanel(selectedPhrase, "selection");
      // }
      setSelectedWord(selectedPhrase);
    } else {
      setSelectedWordOriginalTextState("");
    }
  };

  // Function to remove punctuation from the start and end of a string
  function removePunctuation(str) {
    return str.replace(/^\p{P}+|\p{P}+$/gu, "");
  }

  // BUG: when clicking on a blue word after a blue word was clicked, the translation doesn't show up
  // it shows up when clicking on a known first though

  function handleWordClick(event) {
    // const clickedWord = event.target.textContent;
    const clickedWord = tokens[parseInt(event.target.dataset.index)];
    console.log(`Clicked word:`, clickedWord);

    console.log("Highlighting the word: ", event.target);
    event.target.classList.add("highlighted");

    setSelectedWord(clickedWord);

    // if (openTranslationPanelByDefaultRef.current) {
    //   showTranslationPanel(clickedWord);
    // }
  }

  function closeTranslationsPanel() {
    document.getElementById("translations").style.display = "none";
    clearTranslationPanelSelectedWord();
  }

  // NOTE: even if I refresh the page, I still have to deal with word highlighting as well somehow
  function refreshPage() {
    insertWords(0);
  }

  function markUnknownWordsAsKnown() {
    // loop through the words between startingWordIndex and endingWordIndex
    let wordsToUpdate = [];
    for (let i = 0; i < tokens.length; i++) {
      // check if the word is a word and unknown
      if (tokens[i].is_not_a_word || tokens[i].known) {
        continue;
      }
      // add the word to the wordsToUpdate array
      // REVIEW: not sure if this is needed anymore - maybe the backend query somehow handles it anyway
      tokens[i].known = true;
      tokens[i].strength = 5;
      tokens[i].translation = undefined;
      wordsToUpdate.push(tokens[i]);
    }
    // remove duplicates from the wordsToUpdate array
    wordsToUpdate = wordsToUpdate.filter(
      (word, index, self) =>
        index === self.findIndex((w) => w.word === word.word)
    );
    console.log("WORDS TO UPDATE after removing duplicates: ", wordsToUpdate);
    sendSkippedWordsToBacked(wordsToUpdate);
  }

  function sendSkippedWordsToBacked(wordsToUpdate) {
    if (wordsToUpdate.length === 0)
      return console.log("No skipped words to update in the backend.");

    // Extracting only the needed properties from each word object (otherwise the payload would be too big)
    const wordsToSend = wordsToUpdate.map(({ word, strength }) => ({
      word,
      strength,
    }));

    console.log(
      "Sending these skipped words to backend for updating: ",
      wordsToSend
    );

    // send a put request to the api to update the word
    const request = {
      words: wordsToSend,
    };
    axios
      .put("/api/user/vocabulary/add-skipped-words", request)
      .then((response) => {
        console.log(
          "RESPONSE FROM UPDATE SKIPPED WORDS backend: ",
          response.data
        );
        // Assuming `setKnownWordsArray` and `knownWordsArray` are defined elsewhere
        setKnownWordsArray(knownWordsArray.concat(response.data.addedWords));
        console.log("Updated known words array with skipped words.");
      })
      .catch((err) => {
        console.error("ERROR WHEN UPDATING SKIPPED WORDS in backend:", err);
      });
  }

  function sendWordsToUpdateToBackend(wordsToUpdate) {
    if (wordsToUpdate.length === 0)
      return console.log("No words to update in the backend.");
    console.log("Sending these words to backend for updating: ", wordsToUpdate);
    // send a put request to the api to update the word
    const request = {
      words: wordsToUpdate,
    };
    axios
      .put("/api/user/update-words", request)
      .then((response) => {
        // setKnownWordsArray(knownWordsArray.concat([response.data.added]));
        // console.log("Added new known word: ", newWord);
        console.log("RESPONSE FROM UPDATE WORDS backend: ", response.data);
      })
      .catch((err) => {
        console.error("ERROR WHEN UPDATING WORDS in backend:", err);
      });
  }

  function insertWords(startingIndex) {
    console.log("Starting to insert the words...");
    console.log("KNOWN WORDS ATM: ", knownWordsArray);

    // Create a reference to the 'textContentWords' div
    const textContentDiv = document.getElementById("readerContainer");
    const textContentWordsDiv = document.getElementById("textContentWords");

    // clear up any words on the page
    textContentWordsDiv.innerHTML = "";

    console.log("TOKENS BEFORE INSERT WORDS LOOP: ", tokens);

    for (let i = startingIndex; i < tokens.length; i++) {
      const word = tokens[i];
      let wordDiv;

      if (word.word === "\n") {
        // Insert single line break as <br>
        wordDiv = document.createElement("br");
        textContentWordsDiv.appendChild(wordDiv);
      } else if (word.word === "\n\n") {
        // Insert double line break as <p>
        wordDiv = document.createElement("p");
        textContentWordsDiv.appendChild(wordDiv);
      } else {
        // Create a new span element for the word
        wordDiv = document.createElement("span");
        wordDiv.textContent = word.word;

        // Append the span to the 'textContentWords' div
        textContentWordsDiv.appendChild(wordDiv);

        wordDiv.classList.add("word");
        if (context.getSelectedLanguagePair().language_learning.code === "th") {
          wordDiv.classList.add("thai");
        }

        // Add additional attributes and event listeners
        wordDiv.id = `word-${i}`;
        wordDiv.dataset.index = i;
        wordDiv.dataset.known = word.known;
        wordDiv.dataset.translation = word.translation;
        wordDiv.dataset.strength = word.strength;
        wordDiv.dataset._id = word._id;
        wordDiv.dataset.is_title = word.is_title;
        wordDiv.dataset.is_last_title_word = word.is_last_title_word;
        wordDiv.dataset.is_not_a_word = word.is_not_a_word;
        wordDiv.dataset.is_number = word.is_number;
        wordDiv.dataset.sentence = word.sentence;

        if (word.is_title) {
          wordDiv.classList.add("title");
          if (word.is_last_title_word) {
            const pElement = document.createElement("p");
            textContentWordsDiv.appendChild(pElement);
          }
        }

        if (word.known) {
          if (word.translation) {
            wordDiv.classList.add("known");
            wordDiv.classList.add("known-strength-" + word.strength);
          } else {
            wordDiv.classList.add("skipped");
          }
          wordDiv.addEventListener("click", handleWordClick);
        } else if (word.is_not_a_word) {
          wordDiv.classList.add("punctuation");
          if (word.is_number) {
            wordDiv.classList.add("number");
            wordDiv.addEventListener("click", handleWordClick);
          }
        } else {
          wordDiv.classList.add("unknown");
          wordDiv.addEventListener("click", handleWordClick);
        }
      }
    }
    return;
  }

  function clearTranslationPanelSelectedWord() {
    setSelectedWordOriginalTextState({});
    setWordForFullLookup(null);
    setWordForQuickLookup(null);
    const highlightedWords = document.querySelectorAll(".highlighted");
    highlightedWords.forEach((word) => {
      word.classList.remove("highlighted");
    });
    // hide the tooltip
    console.log("Translation panel cleared.");
    document.getElementById("tooltip").style.display = "none";
    // this is for mobile only essentially to make sure the content is visible again after the translation panel was opened
    document.getElementById("textContent").style.display = "block";
  }

  function addUnknownWordToKnownWords(word, translation, strength) {
    let newWord = {
      word: word,
      translation: translation,
      strength: strength,
    };
    console.log("Sending this word to backend for updating: ", newWord);
    context.saveNewWordToBackend(newWord, currentArticle._id);
    return;
  }

  function updateKnownWordInKnownWords(wordToUpdate, translation, strength) {
    console.log("checking updated known word: ", wordToUpdate);
    console.log("the strength of the updated word should be: ", strength);
    // NOTE: the strength for the word that was skipped is 4, it's not updated here to 0 when meaning is added
    // find the index of the word to update
    const index = knownWordsArray.findIndex(
      (knownWord) => knownWord._id === wordToUpdate._id
    );
    // update the word in the array
    let newWord = knownWordsArray[index];
    // let the user update a translation only if the word already had a translation (and wasn't therefore skipped)
    // if (newWord.translation === undefined && checkIfFreePlanLimitReached())
    //   return;
    newWord.translation = translation;
    // NOTE: existing strength could also be 0
    newWord.strength =
      wordToUpdate.strength !== undefined && wordToUpdate.strength !== null
        ? wordToUpdate.strength
        : strength;
    if (newWord.strength !== strength) newWord.strength = strength;
    // set the state variable to the new array without referencing the old one
    setKnownWordsArray([...knownWordsArray]);

    console.log("Updated known word: ", newWord);
    sendWordsToUpdateToBackend([newWord]);
  }

  return (
    <>
      <Container id="readerContainer" fluid="md">
        <Row style={{ minHeight: "100svh" }}>
          <Col className="d-flex flex-column">
            <div className="sticky-top-row">
              {/* First row with buttons */}
              <Row className="justify-content-between align-items-start pt-3">
                <Col xs={2} md={1} className="d-flex justify-content-center">
                  <Link to="/library">
                    <Button
                      id="readerCloseReaderButton"
                      variant="light"
                      size="sm"
                    >
                      &#10006;
                    </Button>
                  </Link>
                </Col>
                <Col xs={8} md={10}></Col>
                <Col
                  xs={2}
                  md={1}
                  className="d-flex justify-content-center mb-1"
                >
                  <div>
                    <Dropdown align="end" autoClose="outside">
                      <Dropdown.Toggle
                        as="button"
                        variant="link"
                        className="p-0 border-0 bg-transparent no-caret"
                      >
                        <ThreeDots size={20} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Header>
                          {t("readers:music_settings")}
                        </Dropdown.Header>
                        <Dropdown.Item className="d-flex align-items-center">
                          <Form>
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              checked={quickLookupRef.current}
                              onChange={() => {
                                context.toggleQuickLookupInMusic();
                                quickLookupRef.current =
                                  !quickLookupRef.current;
                                console.log(
                                  `[${new Date().toISOString()}] Toggling open trans panel by default: new=${!context
                                    .settings.quick_lookup_in_music}`
                                );
                              }}
                              onClick={(e) => e.stopPropagation()}
                            />
                          </Form>
                          <div className="fs-6">
                            {t("readers:setting_quick_word_lookup")}
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Header>
                          {t("readers:global_settings")}
                        </Dropdown.Header>
                        <Dropdown.Item className="d-flex align-items-center">
                          <Form>
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              checked={expandAIExplanationBoxRef.current}
                              onChange={() => {
                                context.toggleExpandAIExplanationBox();
                                expandAIExplanationBoxRef.current =
                                  !expandAIExplanationBoxRef.current;
                                console.log(
                                  `[${new Date().toISOString()}] Toggling expand ai explanation box: new=${!context
                                    .settings.expand_ai_explanation_box}`
                                );
                              }}
                              onClick={(e) => e.stopPropagation()}
                            />
                          </Form>
                          <div className="fs-6">
                            {t("readers:global_setting_expand_ai_explanation_box_by_default")}
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={openSupportCenter}
                          className="d-flex align-items-center"
                        >
                          <InfoCircle className="me-2" />{" "}
                          {t("readers:global_setting_support_center")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={sendBugReport}
                          className="d-flex align-items-center"
                        >
                          <Bug className="me-2" />{" "}
                          {t("readers:global_setting_send_bugs_and_feedback")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
              </Row>
              {/* Second row with YouTube player */}
              <Row>
                <Col className="youtube-container px-0">
                  <div className="youtube-aspect-ratio">
                    <YouTube
                      videoId={currentArticle?.youtube_video_id}
                      opts={{
                        playerVars: {
                          autoplay: 1,
                        },
                        width: "100%",
                        height: "100%",
                      }}
                      className="youtube-player"
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <Row className="flex-grow-1">
              <Col
                id="textContent"
                onTouchStart={handleTouchStart}
                onContextMenu={handleContextMenu}
                className="flex-grow-1"
              >
                <div className="d-flex justify-content-center pt-5">
                  <div id="textContentWords"></div>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="d-flex justify-content-center"></Col>
            </Row>
          </Col>

          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={3}
            id="translations"
            tabIndex="0"
            className={isFullScreen ? "full-screen" : ""}
            onTouchStart={handleTouchStartTranslationsPanel}
            onTouchMove={handleTouchMoveTranslationsPanel}
          >
            <Button
              variant="light"
              onClick={closeTranslationsPanel}
              id="closeTranslationsPanelButton"
              className={
                isFullScreen ? "closeTranslationsPanelButtonBigger" : ""
              }
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              &#x2715;
            </Button>
            <TranslationPanel
              selectedWord={wordForFullLookup}
              setSelectedWord={setWordForFullLookup}
              refreshPage={refreshPage}
              addUnknownWordToKnownWords={addUnknownWordToKnownWords}
              updateKnownWordInKnownWords={updateKnownWordInKnownWords}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              closeTranslationsPanel={closeTranslationsPanel}
              fullTextTokens={tokens}
              clearTranslationPanelSelectedWord={
                clearTranslationPanelSelectedWord
              }
              speakOnRender={false}
            />
          </Col>
        </Row>
      </Container>

      <QuickTranslationTooltip
        selectedWord={wordForQuickLookup}
        setSelectedWord={setWordForQuickLookup}
        setWordForFullLookup={setWordForFullLookup}
        addUnknownWordToKnownWords={addUnknownWordToKnownWords}
        updateKnownWordInKnownWords={updateKnownWordInKnownWords}
        speakOnRender={false}
      />
      <PlaylistModal
        show={showPlaylistSelectionModal}
        article={currentArticle}
        setShow={setShowPlaylistSelectionModal}
      />
    </>
  );
}

function createWords(wordsArray, knownWords, inputType) {
  let words = [];
  for (let i = 0; i < wordsArray.length; i++) {
    // console.log("Creating word: ", wordsArray[i]);
    // let word = {};

    // Extract the token from the word object
    // NOTE: only necessary for Thai which already has the .word property
    // Use existing 'word' property if available, otherwise default to 'token'
    let word =
      wordsArray[i].word !== undefined
        ? {
            word: wordsArray[i].word,
            sentence: escapeHTML(wordsArray[i].sentence),
          }
        : {
            word: wordsArray[i].token,
            sentence: escapeHTML(wordsArray[i].sentence),
          };

    // console.log("The word is: ", word);

    // Rest of your existing code...
    word.known = false;
    word.is_not_a_word = false;
    word.is_number = false;
    if (inputType === "title") {
      word.is_title = true;
      if (i === wordsArray.length - 1) {
        word.is_last_title_word = true;
      }
    } else {
      word.is_title = false;
    }
    const regex = /[\p{P}\p{Z}\p{N}]/gu; // punctuation, separator, number
    const got_punctuation = word.word.match(regex);
    const is_a_phrase = /(?<=\S)\s(?=\S)/.test(word.word);
    if (got_punctuation && !is_a_phrase) {
      word.is_not_a_word = true;
      word.known = undefined;
      // NOTE: this works for making multiline selection possible on mobile but it also puts spaces in the beginning of new lines sometimes
      // if (word.word === " ") word.word = "\u00A0";
    } else {
      const known = checkIfWordIsKnown(word.word, knownWords);
      if (known) {
        word.known = true;
        word._id = known._id;
        word.translation = known.translation;
        word.strength = known.strength;
        word.srs_due_date = known.srs_due_date;
      }
    }
    const regexNumber = /[\d]+([.,\s]?[\d]+)*/g; // includes also separators
    const is_number = word.word.match(regexNumber);
    if (is_number) {
      word.is_number = true;
    }
    words.push(word);
  }
  return words;
}

const trackSongListened = async (songId, location, language_learning_code) => {
  try {
    const response = await axios.post(`/api/user/songs/${songId}`, {
      location: location,
      language_learning_code: language_learning_code,
    });

    console.log(response.data.message);
  } catch (error) {
    console.error("Error tracking song listened:", error);
  }
};

export default App;
