import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // Import the plugin
import { Table } from "react-bootstrap"; // Import Bootstrap Table component
import { useTranslation } from "react-i18next";

const GrammarGuide = ({ language, value, type }) => {
  const { t } = useTranslation();
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    // Use a regular fetch instead of dynamic import
    fetch(
      `/grammar-guides/${language.toLowerCase()}/${type.toLowerCase()}/${value.toLowerCase()}.md`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("File not found");
        }
        return response.text();
      })
      .then((text) => setMarkdownContent(text))
      .catch((err) => {
        console.error("Error loading markdown file:", err);
        setMarkdownContent(`# ${t("grammar_guide:guide_not_available")}`);
      });
  }, [language, value, type]);

  // Custom renderers for ReactMarkdown to use React Bootstrap components
  const customRenderers = {
    table: ({ children }) => (
      <Table striped bordered hover>
        {children}
      </Table>
    ),
    th: ({ children }) => <th>{children}</th>,
    td: ({ children }) => <td>{children}</td>,
    tr: ({ children }) => <tr>{children}</tr>,
  };

  return (
    <div>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={customRenderers} // Use custom renderers
      >
        {markdownContent}
      </ReactMarkdown>
    </div>
  );
};

export default GrammarGuide;
