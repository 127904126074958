import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Table, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import {
  XCircle,
  GripVertical,
  Trash,
  Eye,
  Pencil,
} from "react-bootstrap-icons";
import "./CollectionEditor.css";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";

const CollectionEditor = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: collectionId } = useParams();
  const isEditMode = collectionId !== undefined;
  const [items, setItems] = useState([]);
  const [collectionTitle, setCollectionTitle] = useState("");
  const [collectionDescription, setCollectionDescription] = useState("");
  const [collectionType, setCollectionType] = useState("book"); // State for collection type
  const [privacySetting, setPrivacySetting] = useState("private"); // State for privacy setting
  const [coverImage, setCoverImage] = useState("");
  const [isUploading, setIsUploading] = useState(false); // State to track upload status
  const [isSaving, setIsSaving] = useState(false); // Add state to track saving status
  const context = React.useContext(AuthContext);

  const fetchCollection = async () => {
    if (!isEditMode) return;

    try {
      const response = await axios.get(`/api/collections/${collectionId}`);
      const collection = response.data;
      setCollectionTitle(collection.title);
      setCollectionDescription(collection.description || "");
      setCoverImage(collection.cover_image || "");
      setPrivacySetting(collection.privacy || "private"); // Default to "private" if not set
      setCollectionType(collection.type || "book"); // Default to "book" if not set

      const sortedArticles = collection.articles
        .sort((a, b) => a.order - b.order)
        .map((article) => ({
          id: article.article_id,
          title: article.title,
          order: article.order,
        }));

      setItems(sortedArticles);
    } catch (error) {
      console.error("Error fetching collection:", error);
    }
  };

  useEffect(() => {
    fetchCollection();
  }, [collectionId]);

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const updatedItems = Array.from(items);
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    setItems(updatedItems);

    const updatedOrder = updatedItems.map((item, index) => ({
      id: item.id,
      order: index + 1,
    }));

    try {
      await axios.put(`/api/user/collection/${collectionId}/reorder`, {
        items: updatedOrder,
      });
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const handleOpenArticle = async (id) => {
    console.log("Opening article: ", id);
    window.open(`/reader/${id}?collectionId=${collectionId}`, "_blank");
  };

  const handleEditArticle = async (id) => {
    console.log("Editing article: ", id);
    window.open(`/library/editor/${id}`, "_blank");
  };

  const handleDelete = async (id, deleteCompletely = false) => {
    try {
      await axios.delete(`/api/user/collection/${collectionId}/${id}`, {
        data: { deleteCompletely },
      });
      const updatedItems = items.filter((item) => item.id !== id);
      setItems(updatedItems);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const collectionData = {
        title: collectionTitle,
        description: collectionDescription,
        type: collectionType,
        privacy: privacySetting,
        language: context.getSelectedLanguagePair().language_learning.code,
      };

      if (isEditMode) {
        await axios.put(`/api/user/collection/${collectionId}`, collectionData);
      } else {
        const response = await axios.post(
          "/api/user/collection",
          collectionData
        );
        navigate(`/library/collection/editor/${response.data.id}`);
      }
      console.log(
        `Collection ${isEditMode ? "updated" : "created"} successfully`
      );
    } catch (error) {
      console.error(
        `Error ${isEditMode ? "updating" : "creating"} collection:`,
        error
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteCollection = async (deleteCompletely = false) => {
    try {
      await axios.delete(`/api/user/collection/${collectionId}`, {
        data: { deleteCompletely },
      });
      console.log("Collection deleted successfully");
      setTimeout(() => {
        navigate("/library");
      }, 2000);
    } catch (error) {
      console.error("Error deleting collection:", error);
    }
  };

  const handleImageUpload = async (event) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("coverImage", event.target.files[0]);

    try {
      const response = await axios.post(
        `/api/user/collection/${collectionId}/image-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setCoverImage(response.data.path);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="container mt-3">
      <h2 className="mb-4">{isEditMode ? t("library:collection_editor_edit_collection_title") : t("library:collection_editor_create_new_collection_title")}</h2>

      {isEditMode && (
        <div className="row mb-3">
          <h3>{t("library:editor_cover_image")}</h3>
          <div className="col-auto">
            {coverImage && (
              <img
                src={coverImage}
                alt="Cover"
                className="img-thumbnail"
                style={{
                  maxWidth: "150px",
                  maxHeight: "150px",
                  objectFit: "cover",
                }}
              />
            )}
            <Form.Group controlId="coverImage" className="mt-2">
              <Form.Label>{t("library:collection_editor_upload_new_image")}</Form.Label>
              <Form.Control type="file" onChange={handleImageUpload} />
            </Form.Group>
          </div>
          {isUploading && (
            <div className="col-auto mt-2">
              <Spinner animation="border" role="status" />
              <span className="ms-2">{t("common:uploading")}</span>
            </div>
          )}
        </div>
      )}

      <Form>
        <Form.Group controlId="collectionTitle">
          <Form.Label>
            <strong>{t("library:collection_editor_name")}</strong>
          </Form.Label>
          <Form.Control
            type="text"
            value={collectionTitle}
            onChange={(e) => setCollectionTitle(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="collectionDescription" className="mt-3">
          <Form.Label>
            <strong>{t("library:collection_editor_description")}</strong> {t("common:optional")}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={collectionDescription}
            onChange={(e) => setCollectionDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="collectionType" className="mt-3">
          <Form.Label>
            <strong>{t("library:collection_editor_collection_type_setting")}</strong>
          </Form.Label>
          <Row>
            <Col md={4}>
              <Form.Select
                value={collectionType}
                onChange={(e) => setCollectionType(e.target.value)}
              >
                <option value="book">{t("library:collection_type_book")}</option>
                <option value="course">{t("library:collection_type_course")}</option>
                <option value="other">{t("library:collection_type_other")}</option>
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="privacySetting" className="mt-3">
          <Form.Label>
            <strong>{t("library:privacy")}</strong>
          </Form.Label>
          <Row>
            <Col md={4}>
              <Form.Select
                value={privacySetting}
                onChange={(e) => setPrivacySetting(e.target.value)}
              >
                <option value="private">{t("library:editor_private_setting")}</option>
                <option value="public">{t("library:editor_public_setting")}</option>
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="languageSetting" className="mt-3">
          <Form.Label>
            <strong>{t("library:collection_editor_language_setting")}</strong>
          </Form.Label>
          <Row>
            <Col md={4}>
              <Form.Control
                type="text"
                value={context.getSelectedLanguagePair().language_learning.name}
                disabled
              />
            </Col>
          </Row>
          <Form.Text className="text-muted">
            {t("library:collection_editor_language_setting_description")}
          </Form.Text>
        </Form.Group>
      </Form>
      <Button
        onClick={handleSave}
        variant="primary"
        className="mt-3 me-2"
        disabled={isSaving}
      >
        {isSaving ? (
          <>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="ms-2">
              {isEditMode ? t("common:updating") : t("common:creating")}
            </span>
          </>
        ) : isEditMode ? (
          t("common:update")
        ) : (
          t("common:create")
        )}
      </Button>

      {isEditMode && (
        <>
          <Button
            onClick={() => handleDeleteCollection(false)}
            variant="danger"
            className="mt-3 me-2"
          >
            {t("library:collection_editor_delete_collection_button")}
          </Button>
          <Button
            onClick={() => handleDeleteCollection(true)}
            variant="danger"
            className="mt-3"
          >
            {t("library:collection_editor_delete_collection_and_content_button")}
          </Button>
        </>
      )}

      {isEditMode && (
        <>
          <h2 className="mt-4">{t("library:collection_editor_content_and_ordering")}</h2>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="collection">
              {(provided) => (
                <Table
                  striped
                  bordered
                  hover
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="mt-3"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{t("library:editor_title")}</th>
                      <th>{t("library:actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map(({ id, title }, index) => (
                      <Draggable key={id} draggableId={id} index={index}>
                        {(provided) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td>
                              {" "}
                              <span
                                {...provided.dragHandleProps}
                                className="drag-handle"
                              >
                                <GripVertical className="mx-2" />
                              </span>
                              {index + 1}
                            </td>
                            <td>{title}</td>
                            <td>
                              <Eye
                                onClick={() => handleOpenArticle(id)}
                                className="mx-2 remove-item-button"
                                title="Open article"
                              />
                              <Pencil
                                onClick={() => handleEditArticle(id)}
                                className="mx-2 remove-item-button"
                                title="Edit article"
                              />
                              <XCircle
                                onClick={() => handleDelete(id, false)}
                                className="mx-2 remove-item-button"
                                title="Remove from collection"
                              />
                              <Trash
                                onClick={() => handleDelete(id, true)}
                                className="mx-2 remove-item-button"
                                title="Delete permanently and remove from collection"
                              />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                </Table>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </div>
  );
};

export default CollectionEditor;
