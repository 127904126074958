import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Table,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Alert,
  Badge,
  Dropdown,
} from "react-bootstrap";
import {
  QuestionCircle,
  Wallet2,
  Trash,
  BrowserChrome,
  Pencil,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import AppMetaDataContext from "../context/AppMetaDataContext";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import SpeechButton from "../Components/SpeechButton";
import WordEditModal from "../Components/WordEditModal";
import axios from "axios";
import "./Vocabulary.css";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";

export default function Vocabulary() {
  const { t } = useTranslation();
  const metaData = React.useContext(AppMetaDataContext);
  const context = React.useContext(AuthContext);

  let navigate = useNavigate();

  // Initialize state
  const [learningWords, setLearningWords] = useState([]);
  const [skippedWords, setSkippedWords] = useState([]);
  const [knownWordsArray, setKnownWordsArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showWordEditModal, setShowWordEditModal] = useState(false);
  const [wordToEdit, setWordToEdit] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [deletingWordIds, setDeletingWordIds] = useState([]);

  const filteredLearningWords = learningWords.filter(
    (word) => word.word.toLowerCase().includes(searchQuery.toLowerCase()) // {{ edit_2 }}
  );

  useEffect(() => {
    setIsLoading(true);
    context.fetchKnownWords().then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    console.log("Known words array in the context changed. Updating state.");
    setKnownWordsArray(context.known_words);
  }, [context.known_words]);

  // Set state with imported articles data
  useEffect(() => {
    if (knownWordsArray === undefined) return;
    // filter out words that have strength and where ignored is not true
    const filteredLearningWords = knownWordsArray.filter(
      (word) => word.translation
    );
    setLearningWords(filteredLearningWords);

    const filteredSkippedWords = knownWordsArray.filter(
      (word) => !word.translation
    );
    setSkippedWords(filteredSkippedWords);
  }, [knownWordsArray]);

  async function deleteWord(e) {
    e.preventDefault();
    const wordId = e.currentTarget.dataset.id;
    console.log("Deleting word with id: ", wordId);

    if (!wordId) {
      console.error("No word ID found for deletion.");
      return;
    }

    setDeletingWordIds((prev) => [...prev, wordId]);

    // Remove the word from the state
    setLearningWords((prevWords) => prevWords.filter((word) => word._id !== wordId));
    let wordsToDelete = [wordId];

    sendWordsToDeleteToBackend(wordsToDelete)
      .then(() => {
        console.log("Word deleted successfully.");
        setDeletingWordIds((prev) => prev.filter((id) => id !== wordId));
      })
      .catch((err) => {
        console.error("Error when deleting word: ", err);
        setDeletingWordIds((prev) => prev.filter((id) => id !== wordId));
      });
  }

  async function sendWordsToDeleteToBackend(wordsToDelete) {
    return new Promise((resolve, reject) => {
      if (wordsToDelete.length === 0) {
        console.log("No words to delete in the backend.");
        resolve(false);
      } else {
        console.log(
          "Sending these words to backend for deletion: ",
          wordsToDelete
        );
        // send a request to the api to update the word
        // NOTE: for delete requests, the payload has to be in the config.data property
        // ideally I would use the :id in the url but it wouldn't support deleting multiple words at once
        const config = {
          data: { words: wordsToDelete },
        };
        axios
          .delete("/api/user/delete-words", config)
          .then((response) => {
            console.log("RESPONSE FROM DELETE WORDS backend: ", response.data);
            resolve(true);
          })
          .catch((err) => {
            console.error("ERROR WHEN DELETE WORDS in backend:", err);
            reject(err);
          });
      }
    });
  }

  function handleWordEditClick(word) {
    console.log("Edit word clicked: ", word);
    setWordToEdit(word);
    setShowWordEditModal(true);
  }

  return (
    <Container
      className="d-flex pt-5 flex-column justify-content-top"
      style={{ minHeight: "70vh" }}
    >
      <Helmet>
        <title>Vocabulary - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>
      <div>
        <div className="" style={{ maxWidth: "700px" }}>
          {/* <h1 className="mb-5">Vocabulary</h1> */}
        </div>

        <Alert variant="secondary" className="mb-5">
          <Alert.Heading>
            {t("vocabulary:chrome_extension_alert_title")}
          </Alert.Heading>
          <div>
            <ul>
              <li>{t("vocabulary:chrome_extension_alert_list_item_1")}</li>
              <li>{t("vocabulary:chrome_extension_alert_list_item_2")}</li>
              <li>{t("vocabulary:chrome_extension_alert_list_item_3")}</li>
              <li>
                {t("vocabulary:chrome_extension_alert_list_item_4")}
                <sup>
                  <Badge bg="primary" text="light" className="ms-1">
                    {t("common:new")}
                  </Badge>
                </sup>
              </li>
            </ul>
          </div>
          <p className="mb-0">
            {t("vocabulary:chrome_extension_alert_description")}
          </p>
          <hr />

          <div className="d-flex justify-content-start">
            <Button
              variant="dark"
              href="https://chromewebstore.google.com/detail/lingo-champion/jjbaohhbimlnboamjmdbblhjomhnipff?hl=en-GB"
              target="_blank"
            >
              {t("vocabulary:chrome_extension_alert_button_text")}
            </Button>
          </div>
        </Alert>

        {isLoading && (
          <div className="d-flex justify-content-center mb-5">
            <div className="d-flex flex-column">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </div>
        )}

        <h3>{t("vocabulary:learning_words_list_title")}</h3>
        <div className="mb-4 align-items-end">
          <Link to="/vocabulary/import">
            <Button size="sm" className="me-2 mt-2">
              &#43; {t("vocabulary:import_words_button_text")}
            </Button>
          </Link>
          <Dropdown className="me-2 mt-2 d-inline-block align-bottom">
            <Dropdown.Toggle
              variant="warning"
              style={{ fontWeight: 400 }}
              size="sm"
            >
              <Wallet2
                style={{ transform: "rotate(-90deg)", marginBottom: "2px" }}
              />{" "}
              {t("flashcards:review_with_flashcards")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => navigate("/vocabulary/review?type=general")}
                disabled={learningWords.length === 0}
              >
                <strong>
                  {t("flashcards:general_flashcards_lowest_strength_first")}
                </strong>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => navigate("/vocabulary/review?type=srs")}
                disabled={learningWords.length === 0}
              >
                <strong>
                  {t("flashcards:srs_flashcards_earliest_due_date_first")}
                </strong>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Button
            size="sm"
            variant="outline-dark"
            className="mt-2"
            href="https://chromewebstore.google.com/detail/lingo-champion/jjbaohhbimlnboamjmdbblhjomhnipff?hl=en-GB"
            target="_blank"
          >
            <BrowserChrome className="me-2" style={{ marginBottom: "0.2em" }} />
            {t("vocabulary:learn_with_chrome_extension_button_text")}
          </Button>
        </div>

        {/* display words in a table with rows: word, translation, strength */}
        <>
          <div className="form-group row mb-3">
            <div className="col-12 col-md-4">
              <input
                type="text"
                placeholder={t(
                  "vocabulary:search_words_or_phrases_placeholder"
                )}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="form-control"
                onFocus={() => setSearchQuery("")}
              />
            </div>
          </div>
          <Table
            striped
            bordered
            hover
            size="sm"
            responsive="sm"
            className="d-none d-sm-table"
          >
            <thead>
              <tr>
                <th>{t("vocabulary:word_column_title")}</th>
                <th>{t("vocabulary:translation_column_title")}</th>
                <th>
                  {t("vocabulary:strength_column_title")}
                  <sup>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 50, hide: 100 }}
                      overlay={
                        <Tooltip style={{ zIndex: 20000 }}>
                          {t("vocabulary:strength_column_tooltip_text")}
                        </Tooltip>
                      }
                    >
                      <QuestionCircle size="1.2em" className="ms-1 p-0" />
                    </OverlayTrigger>
                  </sup>
                </th>
                <th>
                  {t("vocabulary:srs_due_date_column_title")}
                  <sup>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 50, hide: 100 }}
                      overlay={
                        <Tooltip style={{ zIndex: 20000 }}>
                          {t("vocabulary:srs_due_date_column_tooltip_text")}
                        </Tooltip>
                      }
                    >
                      <QuestionCircle size="1.2em" className="ms-1 p-0" />
                    </OverlayTrigger>
                  </sup>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredLearningWords.map((word, index) => (
                <tr key={index}>
                  <td>
                    <SpeechButton word={word.word} speakOnRender={false} />
                    <span
                      className="wordEditLink fw-bold"
                      onClick={() => handleWordEditClick(word)}
                    >
                      {word.word}
                    </span>
                  </td>
                  <td>{word.translation}</td>
                  <td>{word.strength}</td>
                  <td>
                    {word.srs_due_date && word.srs_due_date.split("T")[0]}
                  </td>
                  <td className="text-center">
                    <Pencil
                      className="wordEditLink me-3"
                      data-id={word._id}
                      onClick={() => handleWordEditClick(word)}
                    />
                    <Trash
                      className="deleteWordIcon"
                      data-id={word._id}
                      onClick={deleteWord}
                      disabled={deletingWordIds.includes(word._id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="d-sm-none">
            {filteredLearningWords.map((word, index) => (
              <div key={index} className="mb-3 p-2 border rounded">
                <div className="d-flex flex-column">
                  <div className="d-flex justify-content-between">
                    <span>
                      <SpeechButton word={word.word} speakOnRender={false} />
                      <span
                        className="wordEditLink fw-bold"
                        onClick={() => handleWordEditClick(word)}
                      >
                        {word.word}
                      </span>
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span>{word.translation}</span>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <span>
                      <span>{t("vocabulary:strength_column_title")}: </span>
                      {word.strength}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span>
                      <span>{t("vocabulary:srs_due_date_column_title")}: </span>
                      {word.srs_due_date && word.srs_due_date.split("T")[0]}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="fw-bold"></span>
                    <span>
                      <Pencil
                        className="wordEditLink me-3"
                        data-id={word._id}
                        onClick={() => handleWordEditClick(word)}
                      />
                      <Trash
                        className="deleteWordIcon"
                        data-id={word._id}
                        onClick={deleteWord}
                        disabled={deletingWordIds.includes(word._id)}
                      />
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      </div>
      <WordEditModal
        show={showWordEditModal}
        setShow={setShowWordEditModal}
        knownWordsArray={knownWordsArray}
        wordToEdit={wordToEdit}
      />
    </Container>
  );
}
