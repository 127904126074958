/* global store */ // Inform ESLint about the global 'store' variable from the plugin

import React, { useContext, useEffect } from "react";
import { Card, Button, Alert, Badge, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import StripePricingPage from "../Components/StripePricingPage";
import AuthContext from "../context/AuthContext";
import AppMetaDataContext from "../context/AppMetaDataContext";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export default function Pricing() {
  const context = useContext(AuthContext);
  const metaData = useContext(AppMetaDataContext);
  const { t } = useTranslation();

  useEffect(() => {
    const initializeStore = () => {
      if (typeof store !== "undefined") {
        console.log("Store object:", store); // Debug log to see store object

        // Wait for the plugin to be ready
        store.ready(() => {
          console.log("Store is ready");

          // Register products after store is ready
          store.register({
            id: "premium-monthly",
            type: store.PAID_SUBSCRIPTION,
          });

          // Rest of your store setup
          store.when("premium-monthly").approved((product) => {
            product.finish();
            console.log("Purchase approved:", product);
          });

          store.refresh();
        });

        // Add error handler outside ready callback
        store.error((error) => {
          console.error("Store error:", error);
        });
      } else {
        console.error("Store is not available");
      }
    };

    // Wait for both deviceready and platform ready events
    document.addEventListener("deviceready", () => {
      console.log("Device is ready");
      console.log("Store object initial state:", store);
      setTimeout(initializeStore, 300);
    });

    return () => {
      document.removeEventListener("deviceready", initializeStore);
    };
  }, []);

  const handleSubscription = async (plan) => {
    if (typeof store === "undefined") {
      console.error(
        "Store is not initialized. Ensure `cordova-plugin-purchase` is configured."
      );
      return;
    }

    if (plan === "premium") {
      try {
        const product = store.get("premium-monthly");
        if (!product) {
          console.error("Product not found in store.");
          return;
        }

        console.log("Initiating purchase for:", product);
        store.order("premium-monthly");
      } catch (error) {
        console.error("Error initiating purchase:", error);
      }
    } else {
      console.log("Free plan selected. No purchase required.");
    }
  };

  let planBadge;

  switch (context.plan) {
    case "Free plan":
      planBadge = (
        <Badge bg="danger" text="dark">
          {context.plan}
        </Badge>
      );
      break;
    case "Premium plan":
      planBadge = (
        <Badge bg="dark" text="light">
          {context.plan}
        </Badge>
      );
      break;
    default:
      break;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("pricing:title")} - {metaData.appName}
        </title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <div
        className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center"
        style={{ maxWidth: "800px" }}
      >
        <h1 className="display-4">{t("pricing:title")}</h1>
        <p className="lead">{t("pricing:description")}</p>
        <br />

        {context.user_id ? (
          <>
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 col-lg-8">
                <Card className="mb-3 p-3">
                  <Card.Body>
                    <div className="mb-1">
                      <h5 className="d-flex justify-content-center">
                        <span className="me-2">
                          {t("pricing:your_current_plan_is")}
                        </span>{" "}
                        {planBadge}
                      </h5>
                    </div>
                    {context.plan === "Free plan" && (
                      <div className="mt-5">
                        <div className="my-3">
                          <span>
                            <strong>
                              {t("pricing:progress_table_word_lookups")}
                            </strong>{" "}
                            {context.word_lookups_count} / 500
                          </span>
                          <ProgressBar
                            now={(context.word_lookups_count / 500) * 100}
                          />
                        </div>
                        <div>
                          <span>
                            <strong>
                              {t(
                                "pricing:progress_table_characters_translated"
                              )}
                            </strong>{" "}
                            {context.translations} / 20,000
                          </span>
                          <ProgressBar
                            now={(context.translations / 20000) * 100}
                          />
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </div>
            <StripePricingPage />
          </>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <Card className="mb-5">
                  <Card.Header as="h5">Free plan</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      Unlimited texts to study,{" "}
                      <strong>500 word lookups</strong> total, flashcards, and
                      more!
                    </Card.Text>
                    <Button variant="success" href="/register">
                      Try for free
                    </Button>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-12 col-md-6">
                <Card className="mb-5">
                  <Card.Header as="h5">Premium plan</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      Enjoy <strong>unlimited</strong> features, including
                      flashcards, videos, and more for just $4.99/month.
                    </Card.Text>
                    <Button
                      variant="primary"
                      onClick={() => handleSubscription("premium")}
                    >
                      Subscribe
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
