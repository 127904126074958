import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Spinner,
  ProgressBar,
  Card,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function CollectionDetailsModal({
  showModal,
  handleClose,
  collectionId,
}) {
  const { t } = useTranslation();
  const context = React.useContext(AuthContext);
  const [collection, setCollection] = useState(null);
  const [loading, setLoading] = useState(false);

  // TODO: no need to fetch every time, only if the collectionId changed
  useEffect(() => {
    if (showModal) {
      fetchCollectionDetails(collectionId);
    }
  }, [showModal, collectionId]);

  const fetchCollectionDetails = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/user/language-pair/${
          context.getSelectedLanguagePair()._id
        }/collections/${id}`
      );
      setCollection(response.data);
    } catch (error) {
      console.error("Error fetching collection details:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{collection?.title || t("common:loading")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div>
            <Spinner animation="grow" size="sm" className="me-2" />
            {t("common:loading")}
          </div>
        ) : collection ? (
          <>
            {collection.cover_image && (
              <Card.Img
                variant="top"
                src={collection.cover_image}
                alt={collection.title}
                className="mb-2" // Reduced margin-bottom for a smaller appearance
                style={{ maxWidth: '50%', maxHeight: '50%' }} // Ensures the image maintains its aspect ratio without stretching
              />
            )}
            <Card.Body>
              <h5>
                {/* {t("library:collection_details_modal_author")}:{" "}{collection.author} */}
              </h5>
              {/* Description */}
              <p>
                {collection.description ||
                  t(
                    "library:collection_details_modal_no_description_available"
                  )}
              </p>
              {/* <h6>
                <strong>Published:</strong>{" "}
                {collection?.date_published?.slice(0, 4) || "Unknown"}
              </h6> */}
              <h5 className="mt-4 mb-3">
                {t("library:collection_details_modal_contents")}
              </h5>
              <div>
                {collection.articles &&
                  collection.articles.map((chapter, idx) => (
                    <div className="mb-3" key={idx}>
                      <div className="card">
                        <div className="card-body">
                          <h6 className="card-title">
                            {`${idx + 1}. `}
                            <Link
                              to={`/reader/${chapter?.article_id}?collectionId=${collection._id}`}
                            >
                              {chapter?.title ||
                                t("library:collection_details_modal_section", {
                                  index: idx + 1,
                                })}
                            </Link>
                          </h6>
                          <p>
                            {chapter.word_count} {t("library:words")} (
                            {Math.ceil(chapter.word_count / 100)}{" "}
                            {t("library:minutes")})
                          </p>
                          <ProgressBar
                            now={chapter.percent_complete}
                            label={`${chapter.percent_complete}%`}
                            variant={
                              chapter.percent_complete === 100
                                ? "success"
                                : chapter.percent_complete >= 66
                                ? "warning"
                                : null // or specify another variant for values below 66%
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                {!collection.articles ||
                  (collection.articles.length === 0 && (
                    <p>
                      {t(
                        "library:collection_details_modal_no_items_in_collection"
                      )}
                    </p>
                  ))}
              </div>
            </Card.Body>
          </>
        ) : (
          <p>
            {t(
              "library:collection_details_modal_error_loading_collection_details"
            )}
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        {collection?.created_by === context.user_id && (
          <Link to={`/library/collection/editor/${collection._id}`}>
            <Button variant="primary">{t("common:edit")}</Button>
          </Link>
        )}
        <Button variant="secondary" onClick={handleClose}>
          {t("common:close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
