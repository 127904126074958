import { useTranslation } from "react-i18next";

const useLevels = () => {
  const { t } = useTranslation();

  return [
    {
      title: t("statistics:levels_newbie"),
      range: 500,
      explainer: t("statistics:levels_newbie_explainer"),
    },
    {
      title: t("statistics:levels_beginner"),
      range: 1000,
      explainer: t("statistics:levels_beginner_explainer"),
    },
    {
      title: t("statistics:levels_elementary"),
      range: 2000,
      explainer: t("statistics:levels_elementary_explainer"),
    },
    {
      title: t("statistics:levels_intermediate"),
      range: 4000,
      explainer: t("statistics:levels_intermediate_explainer"),
    },
    {
      title: t("statistics:levels_upper_intermediate"),
      range: 8000,
      explainer: t("statistics:levels_upper_intermediate_explainer"),
    },
    {
      title: t("statistics:levels_advanced"),
      range: 16000,
      explainer: t("statistics:levels_advanced_explainer"),
    },
    {
      title: t("statistics:levels_proficient"),
      range: 32000,
      explainer: t("statistics:levels_proficient_explainer"),
    },
    {
      title: t("statistics:levels_native"),
      range: 64000,
      explainer: t("statistics:levels_native_explainer"),
    },
  ];
};

export default useLevels;
