import axios from "axios";

// Create an axios instance with the base URL from the environment variable
const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to log the details of each request
axiosInstance.interceptors.request.use(
  (request) => {
    console.log("Making request to:", request.url);
    console.log("Request headers:", request.headers);
    console.log("Request body:", request.data); // Will log the request payload

    return request;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor to log the response or errors
axiosInstance.interceptors.response.use(
  (response) => {
    console.log("Received response from:", response.config.url);
    console.log("Response data:", response.data);
    console.log("Response status:", response.status);

    return response;
  },
  (error) => {
    console.error("Response error:", error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
