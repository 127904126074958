import axios from "axios";

// Dynamically load all JSON files in the locales folder
const loadNamespaces = () => {
  const context = require.context("../locales", false, /\.json$/); // Load all JSON files
  const namespaces = {};

  context.keys().forEach((key) => {
    const namespaceName = key.replace("./", "").replace(".json", ""); // Extract file name
    namespaces[namespaceName] = context(key); // Load the JSON content
  });

  return namespaces;
};

export const sendCombinedTranslations = async () => {
  // Dynamically load and combine namespaces
  const combinedTranslations = loadNamespaces();
  console.log("Combined Translations:", combinedTranslations);

  try {
    // Send to backend
    const response = await axios.post(
      "/api/localization/editor/update-keys",
      combinedTranslations,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("Translations successfully sent:", response.data);
  } catch (error) {
    console.error("Error sending translations:", error.message);
  }
};
