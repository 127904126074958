import { useState, useEffect, useCallback, useRef } from "react";

// Function to split a string by URLs and keep them as tokens
const splitByURLs = (str) => {
  const regex = /(https?:\/\/[^\s]+)/g;
  let match;
  let lastIndex = 0;
  const result = [];

  while ((match = regex.exec(str)) !== null) {
    // Push non-URL part
    result.push(str.slice(lastIndex, match.index));
    // Push URL
    result.push(match[0]);
    lastIndex = regex.lastIndex;
  }

  // Push remaining non-URL part
  result.push(str.slice(lastIndex));

  return result;
};

function createWords(wordsArray, knownWords, inputType) {
  // create a new array of words
  let words = [];
  // loop through wordsArray
  for (let i = 0; i < wordsArray.length; i++) {
    // create a new word object
    let word = {};
    // add the word to the word object
    word.word = wordsArray[i];
    // add the known property to the word object
    // set the known property to false
    word.known = false;
    word.is_punctuation_or_space_or_number = false;
    if (inputType === "title") {
      word.is_title = true;
      if (i === wordsArray.length - 1) {
        word.is_last_title_word = true;
      }
    } else {
      word.is_title = false;
    }
    // check if the word is punctuation or space or contains a number with regex
    const regex = /[\p{P}\p{Z}\p{N}]/gu;
    const got_punctuation = word.word.match(regex);
    if (got_punctuation) {
      word.is_punctuation_or_space_or_number = true;
      word.known = undefined;
    } else {
      const known = checkIfWordIsKnown(word.word, knownWords || []);
      // set the known property to true
      if (known) {
        word.known = true;
        word.translation = known.translation;
      }
    }
    // add the word object to the words array
    words.push(word);
  }
  return words;
}

function checkIfWordIsKnown(wordToCompare, knownWordsArray) {
  // if knownWordsArray is undefined or empty, return false
  if (!knownWordsArray || knownWordsArray.length === 0) {
    return false;
  }
  // Convert the word to lowercase for comparison
  const wordToCompareLowerCase = wordToCompare.toLowerCase();

  // Search for a match in the lowercase "word" properties of knownWordsArray
  // NOTE: this sometimes crashed the app because the word property was undefined
  // const foundWord = knownWordsArray.find(
  //   (wordObj) => wordObj.word.toLowerCase() === wordToCompareLowerCase
  // );

  const foundWord = knownWordsArray.find(
    (wordObj) =>
      wordObj.word && wordObj.word.toLowerCase() === wordToCompareLowerCase
  );

  if (foundWord) {
    // console.log("FOUND WORD: ", foundWord);
  }

  // If a match is found, return the word; otherwise, return false
  return foundWord ? foundWord : false;
}

function checkIfWordIsKnownInSet(wordToCompare, knownWordsSet) {
  // Convert the word to lowercase for comparison
  const wordToCompareLowerCase = wordToCompare.toLowerCase();

  // Check if the word exists in the Set
  return knownWordsSet.has(wordToCompareLowerCase);
}

// NOTE: these are actually used:

// Helper function to split text into sentences
function splitIntoSentences(text) {
  return text.match(/[^\.!\?]+[\.!\?]*|.+$/g) || [];
}

// Helper function to split text into tokens
function splitIntoTokens(text, sentence) {
  return text
    .split(
      /(\s+|[^\s\p{L}\p{M}\p{N}-]+|\p{L}+(?:\p{M}*\p{N}*)*(?:-\p{L}+(?:\p{M}*\p{N}*)*)*)/gu
    )
    .filter((token) => token !== "")
    .map((token) => ({ token, sentence }));
}

function escapeHTML(str) {
  return str
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;");
}

// this is for checking if the --translation-panel property exists (to see if the translation panel was clicked)
function checkTranslationPanelProp(element) {
  if (!element) return false;
  const computedStyle = window.getComputedStyle(element);
  const translationPanelValue = computedStyle
    .getPropertyValue("--translation-panel")
    .trim();

  return translationPanelValue !== ""; // return true if the property exists and has a value
}

function sendBugReport() {
  // open an url
  window.open("https://tally.so/r/wbW5X1", "_blank");
}

function openSupportCenter() {
  // open an url
  window.open("https://lingochampion.freshdesk.com", "_blank");
}

function checkForVerticalOverflow() {
  const bodyHeight = document.body.scrollHeight;
  const windowHeight = window.innerHeight;

  if (bodyHeight > windowHeight) {
    console.log("Vertical overflow detected!");
    document.body.style.backgroundColor = "#ffcccc"; // Light red
    return true;
  } else {
    console.log("No vertical overflow");
    document.body.style.backgroundColor = "white"; // Original color
    return false;
  }
}

// Add the pagination hook
const usePagination = (allWords, containerRef, configureWordSpan) => {
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const firstWordBeforeResize = useRef(null);

  const calculatePages = useCallback(() => {
    console.log("📊 calculatePages called", {
      containerExists: !!containerRef.current,
      wordsCount: allWords.length,
      containerWidth: containerRef.current?.clientWidth,
      containerHeight: containerRef.current?.clientHeight,
    });

    const container = containerRef.current;
    if (!container || !allWords.length) return [];

    // Temporarily clear the container to get its natural height
    const originalContent = container.innerHTML;
    container.innerHTML = "";
    const containerHeight = container.clientHeight;
    container.innerHTML = originalContent;

    // Create a hidden measurement container
    const measureContainer = document.createElement("div");
    measureContainer.style.cssText = `
      position: absolute;
      visibility: hidden;
      width: ${container.clientWidth}px;
      font-size: ${window.getComputedStyle(container).fontSize};
      line-height: ${window.getComputedStyle(container).lineHeight};
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-word;
    `;
    document.body.appendChild(measureContainer);

    const tempPages = [];
    let currentWords = [];

    try {
      for (const word of allWords) {
        if (currentWords.length === 0 && /^[\s\n\r]*$/.test(word.text)) {
          continue;
        }

        const span = document.createElement("span");
        span.textContent = word.text;
        span.id = `word-${word.id}`;
        configureWordSpan(span, word);
        measureContainer.appendChild(span);

        if (measureContainer.offsetHeight > containerHeight) {
          tempPages.push([...currentWords]);
          currentWords = [];
          measureContainer.textContent = "";

          if (!/^[\s\n\r]*$/.test(word.text)) {
            currentWords = [word];
            measureContainer.appendChild(span);
          }
        } else {
          currentWords.push(word);
        }
      }

      if (currentWords.length > 0) {
        tempPages.push(currentWords);
      }

      return tempPages;
    } finally {
      document.body.removeChild(measureContainer);
    }
  }, [allWords, configureWordSpan]);

  useEffect(() => {
    let resizeTimer;

    const handleViewportChange = () => {
      const previousHeight = containerRef.current?.clientHeight;
      const previousWidth = containerRef.current?.clientWidth;

      clearTimeout(resizeTimer);
      if (pages[currentPage]?.[0]) {
        firstWordBeforeResize.current = pages[currentPage][0];
      }

      resizeTimer = setTimeout(() => {
        const newHeight = containerRef.current?.clientHeight;
        const newWidth = containerRef.current?.clientWidth;

        console.log("🔄 handleViewportChange triggered", {
          previousHeight,
          newHeight,
          previousWidth,
          newWidth,
        });

        const newPages = calculatePages();

        if (firstWordBeforeResize.current) {
          const newPageIndex = newPages.findIndex((page) =>
            page.some((word) => word.id === firstWordBeforeResize.current.id)
          );

          if (newPageIndex !== -1) {
            setCurrentPage(newPageIndex);
          }
        }

        setPages(newPages);
      }, 1000);
    };

    window.addEventListener("resize", handleViewportChange);
    window.addEventListener("orientationchange", handleViewportChange);
    if (window.visualViewport) {
      window.visualViewport.addEventListener("resize", handleViewportChange);
    }

    return () => {
      window.removeEventListener("resize", handleViewportChange);
      window.removeEventListener("orientationchange", handleViewportChange);
      if (window.visualViewport) {
        window.visualViewport.removeEventListener(
          "resize",
          handleViewportChange
        );
      }
      clearTimeout(resizeTimer);
    };
  }, [calculatePages, currentPage, pages]);

  useEffect(() => {
    const newPages = calculatePages();
    setPages(newPages);
  }, [calculatePages]);

  const changePage = (newPageIndex) => {
    setCurrentPage(newPageIndex);
  };

  return {
    pages,
    currentPage,
    changePage,
    calculatePages,
  };
};

export {
  splitByURLs,
  createWords,
  checkIfWordIsKnown,
  checkIfWordIsKnownInSet,
  splitIntoSentences,
  splitIntoTokens,
  checkTranslationPanelProp,
  escapeHTML,
  sendBugReport,
  openSupportCenter,
  checkForVerticalOverflow,
  usePagination,
};
