import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  Table,
  Tabs,
  Tab,
  Row,
  Col,
} from "react-bootstrap";
import { sendCombinedTranslations } from "../utils/Localization";
import TextareaAutosize from 'react-textarea-autosize';

const TranslationsEditor = () => {
  const [languages, setLanguages] = useState([]);
  const [namespaces, setNamespaces] = useState([]);
  const [selectedNamespace, setSelectedNamespace] = useState(null);
  const [globalContext, setGlobalContext] = useState("");
  const [namespaceContext, setNamespaceContext] = useState("");
  const [translations, setTranslations] = useState({});
  const [englishStrings, setEnglishStrings] = useState({});
  const [unsavedTranslations, setUnsavedTranslations] = useState({});

  const [language, setLanguage] = useState("");
  const [savingKeys, setSavingKeys] = useState(new Set());
  const [translatingKeys, setTranslatingKeys] = useState(new Set());

  // Handle input change without saving
  const handleTranslationChange = (key, value) => {
    setUnsavedTranslations((prev) => ({ ...prev, [key]: value }));
  };

  // Fetch available languages
  useEffect(() => {
    const fetchLanguages = async () => {
      const res = await fetch("/api/localization/languages");
      const data = await res.json();
      setLanguages(data);
    };
    fetchLanguages();
  }, []);

  // Fetch namespaces when language changes
  useEffect(() => {
    if (language) {
      const fetchNamespaces = async () => {
        const res = await fetch(
          `/api/localization/editor/namespaces/${language}`
        );
        const data = await res.json();
        setNamespaces(data);
      };
      fetchNamespaces();
    }
  }, [language]);

  // Fetch global context
  useEffect(() => {
    const fetchGlobalContext = async () => {
      const res = await fetch("/api/localization/editor/global-context");
      const data = await res.json();
      setGlobalContext(data.context);
    };
    fetchGlobalContext();
  }, []);

  // Fetch namespace context when namespace changes
  useEffect(() => {
    if (selectedNamespace) {
      const fetchNamespaceContext = async () => {
        const res = await fetch(
          `/api/localization/editor/context/${selectedNamespace}`
        );
        const data = await res.json();
        setNamespaceContext(data.context);
      };
      fetchNamespaceContext();

      // Fetch English strings
      const fetchEnglishStrings = async () => {
        const res = await fetch(
          `/api/localization/locales/en-US/${selectedNamespace}`
        );
        const data = await res.json();
        setEnglishStrings(data);
      };
      fetchEnglishStrings();

      // Fetch translations for the selected namespace
      const fetchTranslations = async () => {
        const res = await fetch(
          `/api/localization/locales/${language}/${selectedNamespace}`
        );
        const data = await res.json();
        setTranslations(data);
      };
      fetchTranslations();
    }
  }, [selectedNamespace, language]);

  // Save global context
  const handleSaveGlobalContext = async () => {
    await fetch("/api/localization/editor/global-context", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ context: globalContext }),
    });
    alert("Global context saved!");
  };

  // Save namespace context
  const handleSaveNamespaceContext = async () => {
    await fetch(`/api/localization/editor/context/${selectedNamespace}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ context: namespaceContext }),
    });
    alert("Namespace context saved!");
  };

  // Save translation when Save button is clicked
  const handleSaveTranslation = async (key) => {
    setSavingKeys((prev) => new Set([...prev, key]));
    try {
      const valueToSave = unsavedTranslations[key] || translations[key];
      const response = await fetch(
        `/api/localization/editor/translations/${language}/${selectedNamespace}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ [key]: valueToSave }),
        }
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      setTranslations((prev) => ({ ...prev, [key]: valueToSave }));
      setUnsavedTranslations((prev) => {
        const newUnsaved = { ...prev };
        delete newUnsaved[key];
        return newUnsaved;
      });
    } catch (error) {
      console.error("Failed to save translation:", error);
      alert(`Failed to save translation: ${error.message}`);
      // Don't clear unsaved changes when save fails
    } finally {
      setSavingKeys((prev) => {
        const newSet = new Set(prev);
        newSet.delete(key);
        return newSet;
      });
    }
  };

  // Translate with OpenAI
  const handleOpenAITranslate = async (key) => {
    setTranslatingKeys((prev) => new Set([...prev, key]));
    try {
      const res = await fetch("/api/localization/editor/translate-string", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          string: englishStrings[key],
          targetLang: language,
          namespace: selectedNamespace,
          key,
        }),
      });
      const data = await res.json();
      if (data.success) {
        setUnsavedTranslations((prev) => ({
          ...prev,
          [key]: data.translation,
        }));
      } else {
        throw new Error("Translation failed");
      }
    } catch (error) {
      console.error("Failed to get AI translation:", error);
      alert("Failed to get AI translation");
    } finally {
      setTranslatingKeys((prev) => {
        const newSet = new Set(prev);
        newSet.delete(key);
        return newSet;
      });
    }
  };

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center my-4">
        <h1>Translations Editor</h1>
        <Button variant="primary" onClick={sendCombinedTranslations}>
          Update the keys
        </Button>
      </div>

      {/* Language and Namespace Selectors in a row */}
      <Row className="mb-4">
        <Col md={6}>
          <Form.Group>
            <Form.Label>Language:</Form.Label>
            <Form.Select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value="">Select a language</option>
              {languages.map((lng) => (
                <option key={lng} value={lng}>
                  {lng}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Namespace:</Form.Label>
            <Form.Select onChange={(e) => setSelectedNamespace(e.target.value)}>
              <option value="">Select a namespace</option>
              {namespaces.map((ns) => (
                <option key={ns} value={ns}>
                  {ns}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      {selectedNamespace && (
        <Tabs defaultActiveKey="strings" className="mb-4">
          <Tab eventKey="strings" title="Strings">
            <div className="mt-4">
              <h3>{selectedNamespace}</h3>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th style={{ width: "20%" }}>Key</th>
                    <th style={{ width: "65%" }}>Text</th>
                    <th style={{ width: "15%" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(englishStrings).map((key, index) => {
                    const currentTranslation =
                      unsavedTranslations[key] !== undefined
                        ? unsavedTranslations[key]
                        : translations[key];

                    return (
                      <>
                        <tr
                          key={`${key}-en`}
                          className={index % 2 === 0 ? "bg-light" : ""}
                        >
                          <td 
                            rowSpan="2" 
                            style={{ 
                              fontSize: '0.85rem',
                              wordBreak: 'break-word',  // Allows words to break and wrap
                              whiteSpace: 'normal'      // Enables text wrapping
                            }}
                          >
                            {key}
                          </td>
                          <td>
                            <small className="text-muted">English:</small>
                            <br />
                            {englishStrings[key]}
                          </td>
                          <td rowSpan="2" className="text-center">
                            <div className="d-inline-flex flex-column gap-2">
                              <Button
                                variant="primary"
                                size="sm"
                                onClick={() => handleOpenAITranslate(key)}
                                disabled={translatingKeys.has(key)}
                              >
                                {translatingKeys.has(key)
                                  ? "Translating..."
                                  : "Translate"}
                              </Button>
                              <Button
                                variant="success"
                                size="sm"
                                onClick={() => handleSaveTranslation(key)}
                                disabled={
                                  savingKeys.has(key) ||
                                  unsavedTranslations[key] === undefined ||
                                  unsavedTranslations[key] === translations[key]
                                }
                              >
                                {savingKeys.has(key) ? "Saving..." : "Save"}
                              </Button>
                            </div>
                          </td>
                        </tr>
                        <tr
                          key={`${key}-trans`}
                          className={index % 2 === 0 ? "bg-light" : ""}
                        >
                          <td>
                            <small className="text-muted">Translation:</small><br />
                            <TextareaAutosize
                              minRows={1}
                              value={currentTranslation || ""}
                              onChange={(e) => handleTranslationChange(key, e.target.value)}
                              className={`form-control ${
                                !currentTranslation || currentTranslation === ""
                                  ? "bg-danger bg-opacity-50"
                                  : ""
                              }`}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Tab>

          <Tab eventKey="context" title="Context">
            {/* Global Context Editor */}
            <div className="mt-4">
              <h3>Global Context</h3>
              <Form.Control
                as="textarea"
                rows={5}
                value={globalContext}
                onChange={(e) => setGlobalContext(e.target.value)}
                className="mb-2"
              />
              <Button onClick={handleSaveGlobalContext}>Save</Button>
            </div>

            {/* Namespace Context Editor */}
            <div className="mt-4">
              <h3>Namespace Context: {selectedNamespace}</h3>
              <Form.Control
                as="textarea"
                rows={5}
                value={namespaceContext}
                onChange={(e) => setNamespaceContext(e.target.value)}
                className="mb-2"
              />
              <Button onClick={handleSaveNamespaceContext}>Save</Button>
            </div>
          </Tab>
        </Tabs>
      )}
    </Container>
  );
};

export default TranslationsEditor;
