import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import YouTube from "react-youtube";
import Badge from "react-bootstrap/Badge";
import "./OnboardingModal.css";
// get authContext
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";

function OnboardingModal({ showModal = false }) {
  const context = React.useContext(AuthContext);

  const [show, setShow] = useState(showModal);
  const { t } = useTranslation();
  const handleClose = () => {
    setShow(false);
    context.setOnboardingShownToTrue();
  };

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  useEffect(() => {
    if (context.known_words === undefined) {
      console.log("No need to show onboarding modal - known_words haven't fetched yet.");
      setShow(false);
      return;
    }
    if (context.onboarding_shown || context.known_words.length > 0) {
      console.log("No need to show onboarding modal - it's been already shown or the user has words.");
      setShow(false);
    } else {
      console.log("Show onboarding modal.");
      setShow(true);
    }
  }, [context.known_words]);

  // if (context.onboarding_shown) {
  //   return null;
  // } else {
  //   setShow(true);
  // }

  return (
    <>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("onboarding:how_to_use_lingo_champion")}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <div className="video-responsive">
            <YouTube videoId={"5zql8gDq77I"} />
          </div>
        </Modal.Body>

        {/* <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Okay, I know how it works
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default OnboardingModal;
