import React, { useContext } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import PlaceholderImg from "../chalice-150x150.png";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import "./SongCard.css";

export default function SongCard({ song, index, is_imported }) {

  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Col key={index} xs={12} className="mb-4">
        <Card>
          <Card.Body>
            <Row className="flex-column flex-md-row">
              <Col xs={12} md={2}>
                <div
                  style={{
                    width: "100%",
                    paddingBottom: "56.25%",
                    position: "relative",
                  }}
                  className="mb-3 mb-md-0"
                >
                  <Link to={`/music/${song._id}`}>
                    <Card.Img
                      variant="top"
                      src={song.thumbnail || PlaceholderImg}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: song.thumbnail ? "cover" : "scale-down",
                      }}
                      onError={(e) => {
                        e.target.onerror = null; // Prevents infinite callback loop
                        e.target.src = PlaceholderImg; // Replace with placeholder image
                        e.target.style.objectFit = "scale-down"; // Change objectFit to scale-down
                      }}
                    />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={10}>
                <Card.Title>
                  <Link
                    to={`/music/${song._id}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {song.title}
                  </Link>
                </Card.Title>

                <Card.Subtitle className="mb-2 text-muted">
                  {/* {video.channelTitle} |{" "} */}
                  <span className="text-none">
                    {/* {song.date_imported.slice(0, 10)} */}
                    {song.artist}
                  </span>
                </Card.Subtitle>
                <Card.Text>
                  {/* converts to user's local timezone */}
                  {/* {new Date(article.date_imported).toLocaleString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          }
                        )} */}
                </Card.Text>
                <Link to={`/music/${song._id}`}>
                  <Button
                    // href={`/reader/${article._id}`}
                    variant="outline-dark"
                    className="btn-sm me-2 mb-2"
                  >
                    {t("library:listen_and_study")}
                  </Button>
                </Link>
                {is_imported && (
                  <Link to={`/library/songs/editor/${song._id}`}>
                    <Button variant="outline-dark" className="btn-sm me-2 mb-2">
                      {t("library:edit")}
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
