import React, { useState, useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import SpeechButton from "./SpeechButton";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import "./Flashcard.css";
import { useTranslation } from "react-i18next";

const Flashcard = ({ flashcard, onStrengthChange, flashcardsType }) => {
  const context = React.useContext(AuthContext);
  const { t } = useTranslation();
  const [flip, setFlip] = useState(false);
  const [hasFlipped, setHasFlipped] = useState(false);
  const [showQuestionWord, setShowQuestionWord] = useState(
    context.settings.show_question_word_in_flashcards
  );

  useEffect(() => {
    setShowQuestionWord(context.settings.show_question_word_in_flashcards);
    console.log("Show question word in flashcards changed: ", showQuestionWord);
  }, [context.settings.show_question_word_in_flashcards]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // pressed on the front of the card
      if (
        (event.key === " " ||
          event.key === "Spacebar" ||
          event.key === "Enter") &&
        !flip
      ) {
        console.log("Spacebar or enter was pressed on the front:");
        handleFlip();
        return;
      }
      // pressed on the back of the card
      if (
        (event.key === " " ||
          event.key === "Spacebar" ||
          event.key === "Enter") &&
        flip
      ) {
        console.log("Spacebar or enter was pressed on the back:", flashcard);
        if (flashcardsType === "general") {
          // keep the strength the same
          handleStrengthChange(flashcard.strength);
        } else if (flashcardsType === "srs") {
          // increase the strength by 1
          handleStrengthChange(flashcard.strength + 1);
        }
        return;
      }

      // pressed on the back of the card
      if ((event.key === "x" || event.key === "X") && flip) {
        console.log("X was pressed:", flashcard);
        // decrease the strength by -1
        handleStrengthChange(flashcard.strength - 1);
        return;
      }

      // pressed on the back of the card
      if (["0", "1", "2", "3", "4", "5"].includes(event.key) && flip) {
        console.log("Number was pressed on the back: ", event.key);
        handleStrengthChange(parseInt(event.key), true);
        return;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [flip]); // Depend on the 'flip' state

  const handleStrengthChange = (strength, manual = false) => {
    flashcard.nr_of_tries += 1;
    if (strength < 0) strength = 0;
    if (strength > 5) {
      strength = 5;
    }
    console.log("Strength was changed to: ", strength);
    // if strength is different from the current strength, update the word in the backend
    // srs flashcards should be updated either way because the due date is updated (especially when the strength didn't change)
    // NOTE: the word should be always updated because the srs due date needs to be updated both in the case of SRS and general type of flashcards
    // if (strength !== flashcard.strength || flashcardsType === "srs") {
    updateWordInBackend({ ...flashcard, strength: strength });
    // }
    onStrengthChange(flashcard._id, strength, manual);
    // Reset flip and hasFlipped states
    setFlip(false);
    setHasFlipped(false);
  };

  function updateWordInBackend(word) {
    console.log("Updating the word in the backend: ", word);
    word.force_update = true;
    const request = {
      words: [word],
    };
    axios
      .put("/api/user/update-words", request)
      .then((response) => {
        // setKnownWordsArray(knownWordsArray.concat([response.data.added]));
        // console.log("Added new known word: ", newWord);
        console.log("RESPONSE FROM UPDATE WORDS backend: ", response.data);
      })
      .catch((err) => {
        console.error("ERROR WHEN UPDATING WORDS in backend:", err);
      });
  }

  const getColor = (strength) => {
    // Assuming strength is still in the range 0 to 5
    const hue = Math.round((120 * strength) / 5); // Linear interpolation for hue

    // Dynamically adjust saturation and lightness for more vibrant middle colors
    let saturation = 100;
    let lightness = 50 - (25 * (5 - strength)) / 5; // Gradually increase lightness as strength increases

    // Adjust saturation dynamically if needed
    // For example, you might want to increase saturation for middle strengths
    // This is a placeholder for any potential dynamic adjustment of saturation
    // Example: saturation = 70 + (30 * strength / 5); // This line is just an example and can be adjusted

    // Ensure the color is brighter and more saturated in the middle range
    if (strength > 2 && strength < 5) {
      lightness += 5; // Slightly increase lightness in the mid-strength range for more vibrancy
      saturation += 10; // Optionally increase saturation for mid-strengths if it looks better
    }

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`; // Full saturation with dynamic lightness
  };

  function getSRSTooltipText(strength) {
    switch (strength) {
      case 0:
        return t("flashcards:due_now");
      case 1:
        return t("flashcards:due_1_day");
      case 2:
        return t("flashcards:due_2_days");
      case 3:
        return t("flashcards:due_5_days");
      case 4:
        return t("flashcards:due_10_days");
      case 5:
        return t("flashcards:learned_no_due_date");
    }
  }

  const handleFlip = () => {
    if (!hasFlipped) {
      // Check if the card has not already flipped
      setFlip(!flip);
      setHasFlipped(true); // Set hasFlipped to true to prevent further flips
    }
  };

  function FlashcardSide({ isFront }) {
    const visibilityClass = isFront ? "invisible" : "visible";
    return (
      <div className={`flashcard ${isFront ? "front" : "back"}`}>
        <div className="flashcard-header"></div>
        <div className="text-center">
          <div className="my-2">
            <h2>
              {flashcard.word && (
                <SpeechButton
                  word={flashcard.word}
                  speakOnRender={isFront ? true : false}
                />
              )}
            </h2>
          </div>
          <div className="flashcard-word my-2">
            {!showQuestionWord && isFront ? null : flashcard.word}
            {/* {flashcard.word} */}
          </div>{" "}
          <div className={`flashcard-translation my-4 ${visibilityClass}`}>
            {flashcard.translation}
          </div>
          <div className="flashcard-sample-sentence my-4"></div>
        </div>
        <div
          className={`flashcard-strength-buttons d-flex justify-content-around align-items-center py-4 ${visibilityClass}`}
        >
          <div
            className={`d-flex flex-column ${visibilityClass}`}
            id="strengthButtonsContainer"
          >
            <div
              className="d-flex justify-content-center text-center mb-3"
              id="strengthTitle"
            >
              {t("flashcards:how_well_do_you_know_the_word")}
            </div>
            <div className="d-flex justify-content-around">
              {[0, 1, 2, 3, 4, 5].map((strength) => (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 50, hide: 100 }}
                  overlay={
                    <Tooltip
                      style={{
                        zIndex: 20000,
                      }}
                    >
                      {getSRSTooltipText(strength)}
                    </Tooltip>
                  }
                  popperConfig={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 10], // Change the numbers to control x, y offset
                        },
                      },
                      {
                        name: "preventOverflow",
                        options: {
                          padding: 10, // Change this value to control padding
                        },
                      },
                      {
                        name: "flip",
                        options: {
                          padding: 10, // Change this value to control padding
                        },
                      },
                    ],
                  }}
                >
                  <Button
                    className={`btn rounded-pill ${
                      flashcard.strength === strength ? "selected" : ""
                    }`}
                    key={strength}
                    style={{ backgroundColor: getColor(strength) }}
                    onClick={() => handleStrengthChange(strength, true)}
                  >
                    {strength}
                  </Button>
                </OverlayTrigger>
              ))}
            </div>
          </div>
        </div>
        {flashcardsType === "srs" && !isFront && (
          <div className="d-flex justify-content-center">
            {/* add buttons for right and wrong guess */}
            <Button
              className="mx-2 mb-3"
              variant="danger"
              onClick={() => handleStrengthChange(flashcard.strength - 1)}
            >
              {t("flashcards:forgot_it")}
            </Button>
            <Button
              className="mx-2 mb-3"
              variant="success"
              onClick={() => handleStrengthChange(flashcard.strength + 1)}
            >
              {t("flashcards:knew_it")}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      id="flashcardContainer"
      className={flip ? "isFlipped" : ""}
      onClick={handleFlip}
    >
      {/* NOTE: this is to avoid double TTS, but actually could setting speakOnRender could check also if the card was flipped */}
      {hasFlipped ? (
        <FlashcardSide isFront={false} />
      ) : (
        <FlashcardSide isFront={true} />
      )}
    </div>
  );
};

export default Flashcard;
