import React, { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import AppMetaDataContext from "../context/AppMetaDataContext";
import { Helmet } from "react-helmet-async";
import Spinner from "react-bootstrap/Spinner";
import Charts from "../Components/Charts";
import axios from "axios";

export default function Statistics() {
  const metaData = useContext(AppMetaDataContext);
  // Initialize state
  const [learningWords, setLearningWords] = useState([]);
  const [skippedWords, setSkippedWords] = useState([]);
  const [knownWordsArray, setKnownWordsArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // TODO: this should be rewritten to use getAuthInfo from the context and use context variables directly both in here and in the children
  
  useEffect(() => {
    fetchKnownWords();
  }, []);

  // Set state with imported articles data
  useEffect(() => {
    // filter out words that have strength and where ignored is not true
    const filteredLearningWords = knownWordsArray.filter(
      (word) => word.translation
    );
    setLearningWords(filteredLearningWords);

    const filteredSkippedWords = knownWordsArray.filter(
      (word) => !word.translation
    );
    setSkippedWords(filteredSkippedWords);
  }, [knownWordsArray]);

  async function fetchKnownWords() {
    setIsLoading(true);
    try {
      console.log("Trying to get known words from the backend.");
      const response = await axios.get("/api/user/known-words");
      console.log(
        "Got the known words from the backend: ",
        response.data.words
      );
      console.log("Setting the known words state values now.");
      setKnownWordsArray(response.data.words);
      setIsLoading(false);
    } catch (error) {
      console.error(
        "There was a problem with getting the known words from the backend:",
        error
      );
      setIsLoading(false);
    }
  }

  return (
    <Container
      className="d-flex pt-5 flex-column justify-content-top"
      style={{ minHeight: "70vh" }}
    >
      <Helmet>
        <title>Statistics - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>
      <div>
        <div className="" style={{ maxWidth: "700px" }}>
          {/* <h1 className="mb-5">Vocabulary</h1> */}
        </div>

        {isLoading && (
          <div className="d-flex justify-content-center mb-5">
            <div className="d-flex flex-column">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </div>
        )}

        <Charts
          learningWords={learningWords}
          skippedWords={skippedWords}
          knownWordsArray={knownWordsArray}
        />
      </div>
    </Container>
  );
}
