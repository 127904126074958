import React, { useState } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import { useParams, useNavigate } from "react-router-dom";
import { Magic } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

function LibraryEditor() {
  const context = React.useContext(AuthContext);
  const { t } = useTranslation();
  const { id } = useParams(); // Get the 'id' parameter from the URL
  const navigate = useNavigate();

  const [contentTitle, setContentTitle] = useState("");
  const [contentBody, setContentBody] = useState("");
  const [contentType, setContentType] = useState("story");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [importSuccessMessage, setImportSuccessMessage] = useState("");
  const isEditMode = id !== undefined; // Determine if the component is in edit mode or import mode

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleTitleChange = (event) => {
    setContentTitle(event.target.value);
  };

  const handleBodyChange = (event) => {
    setContentBody(event.target.value);
  };

  const handleContentTypeChange = (event) => {
    setContentType(event.target.value);
  };

  const validateInput = () => {
    if (contentTitle.length < 4 || contentTitle.length > 60) {
      setError(
        t("library:generator_error_title_length")
      );
      return false;
    }

    if (contentBody.length < 10 || contentBody.length > 500) {
      setError(
        t("library:generator_error_describe_situation_or_topic_description")
      );
      return false;
    }

    return true;
  };

  const submitArticle = () => {
    if (!validateInput()) {
      return;
    }

    setError("");
    setImportSuccessMessage("");
    setIsLoading(true);

    const articleData = {
      title: contentTitle,
      topic: contentBody,
      contentType: contentType,
      source: "webapp",
      language: context.getSelectedLanguagePair().language_learning,
    };

    const request = axios.post(`/api/generate-content`, articleData);
    request
      .then((response) => {
        console.log("Generation successful:", response.data);
        setImportSuccessMessage(
          t("library:generator_content_generated_successfully")
        );
        setIsLoading(false);
        // TODO: activate this
        // wait for 1 second
        setTimeout(() => {
          navigate("/library/editor/" + response.data.article_id); // Redirect to the editor
        }, 2000);
      })
      .catch((error) => {
        console.error("Error generating content:", error);
        setError(t("library:generator_error_occurred_while_generating_content") + error.message);
        setIsLoading(false);
      });
  };

  return (
    <Container
      className="d-flex pt-5 flex-column justify-content-top"
      style={{ minHeight: "70vh" }}
    >
      <h2>{t("library:generator_generate_content_with_ai_title")}</h2>
      <p>{t("library:generator_generate_content_with_ai_description")}</p>
      <Form>
        <Form.Group controlId="formContentImport">
          <Form.Label className="mt-3">
            <strong>{t("library:title")}</strong>
          </Form.Label>
          <Form.Control
            value={contentTitle}
            onChange={handleTitleChange}
            placeholder={t("library:generator_title_placeholder")}
          />
          <Form.Label className="mt-3">
            <strong>{t("library:generator_describe_situation_or_topic")}</strong>{" "}
            {t("library:generator_describe_situation_or_topic_description")}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={contentBody}
            onChange={handleBodyChange}
            placeholder={t(
              "library:generator_describe_situation_or_topic_placeholder"
            )}
          />
          <Row>
            <Col md={4}>
              {" "}
              {/* Adjust the column size as needed */}
              <Form.Group className="mt-3">
                <Form.Label>
                  <strong>{t("library:generator_content_type")}</strong>
                </Form.Label>
                <Form.Check
                  type="radio"
                  id="fake-news"
                  label={t("library:generator_content_type_fake_news")}
                  value="fake news"
                  checked={contentType === "fake news"}
                  onChange={handleContentTypeChange}
                  name="contentType"
                />
                <Form.Check
                  type="radio"
                  id="dialogue"
                  label={t("library:generator_content_type_dialogue")}
                  value="dialogue"
                  checked={contentType === "dialogue"}
                  onChange={handleContentTypeChange}
                  name="contentType"
                />
                <Form.Check
                  type="radio"
                  id="guideline"
                  label={t("library:generator_content_type_guide")}
                  value="guide"
                  checked={contentType === "guide"}
                  onChange={handleContentTypeChange}
                  name="contentType"
                />
                <Form.Check
                  type="radio"
                  id="instructions"
                  label={t("library:generator_content_type_instructions")}
                  value="instructions"
                  checked={contentType === "instructions"}
                  onChange={handleContentTypeChange}
                  name="contentType"
                />
                <Form.Check
                  type="radio"
                  id="poem"
                  label={t("library:generator_content_type_poem")}
                  value="poem"
                  checked={contentType === "poem"}
                  onChange={handleContentTypeChange}
                  name="contentType"
                />
                <Form.Check
                  type="radio"
                  id="song-lyrics"
                  label={t("library:generator_content_type_song_lyrics")}
                  value="song lyrics"
                  checked={contentType === "song lyrics"}
                  onChange={handleContentTypeChange}
                  name="contentType"
                />
                <Form.Check
                  type="radio"
                  id="story"
                  label={t("library:generator_content_type_story")}
                  value="story"
                  checked={contentType === "story"}
                  onChange={handleContentTypeChange}
                  name="contentType"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form.Group>
        {error && (
          <Alert variant="danger" className="mt-3 mb-0">
            {error}
          </Alert>
        )}
        {importSuccessMessage && (
          <Alert variant="success" className="mt-3 mb-0">
            {importSuccessMessage}
          </Alert>
        )}
        <Button
          variant="primary"
          onClick={submitArticle}
          disabled={isLoading}
          className="my-3"
        >
          {isLoading ? (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-1"
            />
          ) : (
            <Magic className="me-1 mb-1" />
          )}
          {t("library:generator_generate_with_ai")}
        </Button>
      </Form>
    </Container>
  );
}

export default LibraryEditor;
