import React from "react";
import Container from "react-bootstrap/Container";
import { Card, Alert } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";

// import updatedFeatureImg from "../updated-feature.png";

export default function ReleaseNotes() {
  const metaData = React.useContext(AppMetaDataContext);

  const [searchParams] = useSearchParams();
  const updated = searchParams.get("updated");

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <Helmet>
        <title>Updates - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <div>
        <div className="" style={{ maxWidth: "700px" }}>
          <h1 className="display-4 mb-5">Updates</h1>
        </div>

        <div style={{ maxWidth: "700px" }}>
          {updated ? (
            <Alert variant="success">
              Your {metaData.appName} extension has been updated to the latest
              version!
            </Alert>
          ) : (
            <></>
          )}

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Updated news and video sources</h3>
                <h6>26 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    For English and Portuguese.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Redesign the tabs in Library</h3>
                <h6>25 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    There are now only 4 main tabs for now: Reading, Listening, Writing,
                    and My stuff.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Audio player bug fixes</h3>
                <h6>24 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Audio player should now preload both on mobile and desktop.
                  </li>
                  <li>
                    This means that seeking timestamps should also always work.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Content editor improvements</h3>
                <h6>24 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now add double line breaks to the content to make it
                    more readable. Find the option in the editor.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Collections are now available for every language</h3>
                <h6>24 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>This includes creating collections.</li>
                  <li>Some minor redesigns were done as well.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Imported section redesign</h3>
                <h6>22 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Imported is now called "My stuff".</li>
                  <li>It has also got different sections.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>News and Stories now guarantee items</h3>
                <h6>22 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The category filters are now applied during fetching. This
                    guarantees that you always get 30 items matching your
                    categories.
                  </li>
                  <li>
                    Previously it fetched 30 items at a time but only some of
                    them matched your category filters.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Collections in beta in Spanish</h3>
                <h6>21 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    An early preview of Collections is available in Spanish.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Continue Studying imporvements</h3>
                <h6>21 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The items under Continue Studying are now organized by the
                    last accessed date (not when it was first added).
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Playlist improvements</h3>
                <h6>20 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Re-organized the playlist page a bit.</li>
                  <li>The mobile view is now more usable.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Audio player improvements</h3>
                <h6>20 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Audio player now allows to skip to the next sentence or
                    timestamp.
                  </li>
                  <li>This was only possible before with full page reload.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>More reliable audio creation</h3>
                <h6>19 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Article audio generation is now more reliable.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Mobile navigation redesign</h3>
                <h6>10 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The navigation is now more suitable for mobile devices. It's
                    the first step for moving towards having native mobile apps.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Learning words statistics</h3>
                <h6>6 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now see how many words you've learned based on the
                    strength.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Phrase selection limit increase</h3>
                <h6>6 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now select up to 30 words at a time for translation.
                    It used to be 10.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Grammar practice with AI</h3>
                <h6>5 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now practice grammar with AI. It's still in beta, so
                    you might come across some bugs.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Listening statistics</h3>
                <h6>1 Jan 2025</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now see your listening statistics in the Statistics
                    page.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Norwegian content updated</h3>
                <h6>30 Dec 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Norwegian video feed is now more diverse.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Reader bug fixes</h3>
                <h6>30 Dec 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    When you skipped a page and then saved a word, the
                    previously skipped words were marked as unknown. This is now
                    fixed.
                  </li>
                  <li>
                    When you open an article, it now starts from the last word
                    you were reading.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Top News Email improvement</h3>
                <h6>20 Dec 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    If you turned on "show articles titles in English" in the
                    news feed, you'll see those in English in the top news email
                    as well.
                  </li>
                  <li>
                    The links now have a temporary magic login attached to them
                    - no need to log in separately.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Spanish UI</h3>
                <h6>19 Dec 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Lingo Champion is now available in Spanish.</li>
                  <li>Go under Account settings to change the UI language.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Daily top news emails</h3>
                <h6>10 Dec 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    There's a notification which sends out daily top news
                    emails.
                  </li>
                  <li>The interests are based on your news feed settings.</li>
                  <li>You can configure notifications in the Account page.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>News categories</h3>
                <h6>3 Dec 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>News categories are more granular now.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Streak count improvements</h3>
                <h6>30 Nov 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Fixed some bugs with the streak count.</li>
                  <li>Streak count is now shown after finishing an article.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Stories are now in beta</h3>
                <h6>20 Nov 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    They're available in the Library but only for Spanish and
                    Portuguese for now.
                  </li>
                  <li>
                    Content importer now also includes cover images plus the
                    title can be longer (100 characters).
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Plan limit restrictions</h3>
                <h6>11 Nov 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Although the plans were updated already, the word lookups
                    now have actual limits.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Go full ghetto</h3>
                <h6>10 Nov 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Added ghetto mode for simplifying articles.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Updated pricing</h3>
                <h6>8 Nov 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The main limitation of the free plan is now the number of
                    word lookups.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Bug fixes</h3>
                <h6>7 Nov 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    % of new words in articles is now calculated correctly.
                  </li>
                  <li>Deleting words sometimes failed. It's now fixed.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Quick Lookup improvements</h3>
                <h6>7 Nov 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Fixed a bug where sometimes the tooltip wasn't completely
                    visible as it bled over the edge of the right side of the
                    screen.
                  </li>
                  <li>The buttons are now more clear to understand.</li>
                  <li>You can now also save skipped words.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Add song lyrics</h3>
                <h6>6 Nov 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>You can now add and edit song in the library.</li>
                  <li>There's also a magic button for getting the lyrics.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Chat interests</h3>
                <h6>5 Nov 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>You can now edit your interests in the Account page.</li>
                  <li>
                    AI Chat will use your interests to make the conversation
                    more interesting.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Audio & Video improvements</h3>
                <h6>4 Nov 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Sentence seeking now works on Android as well.</li>
                  <li>The YouTube song player is now wider on mobile.</li>
                  <li>Updated Spanish video and news sources</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Corrections in Chat</h3>
                <h6>4 Nov 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Chat now includes corrections done by AI.</li>
                  <li>There are now chat-specific settings as well.</li>
                  <li>Plus the chatbox stays always at the bottom.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>AI Chat is in beta</h3>
                <h6>31 Oct 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>You can now chat with AI to improve your language.</li>
                  <li>
                    It's available in the library view and also in the finished
                    page of articles (if you wanna chat about an article).
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Video categories</h3>
                <h6>30 Oct 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>You can now filter videos by category in the Library.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Account deletion</h3>
                <h6>29 Oct 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now delete your account under the Account page.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>New AI generation content types</h3>
                <h6>29 Oct 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Added new content types for AI generation: guides, fake
                    news, song lyrics, and poems.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Spanish content updated</h3>
                <h6>22 Oct 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Updated video and news sources for Spanish.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Mobile bug fixes</h3>
                <h6>22 Oct 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Phrase selection now works on Android (except for Thai).
                  </li>
                  <li>Vocabulary search now works on mobile.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Bug fixes</h3>
                <h6>10 Oct 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Clear translation panel after saving a word.</li>
                  <li>Load news articles on first visit every time.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Scroll to the top button in the library</h3>
                <h6>10 Oct 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>The library now has a button to scroll to the top.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Grammar guides for nouns</h3>
                <h6>8 Oct 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Nouns now have a separate grammar guide.</li>
                  <li>
                    Added some missing tense guides as well (for Portuguese and
                    English).
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Audio player redesign</h3>
                <h6>7 Oct 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>The audio player has been redesigned completely.</li>
                  <li>You're now able to select words and save words.</li>
                  <li>
                    It now has a separate setting for scrolling automatically.
                  </li>
                  <li>
                    It now has a separate setting for quick lookup as well.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Phrase selection improvements</h3>
                <h6>7 Oct 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The selection now checks whether the selection contains more
                    than 10 words. If so, it won't translate it. Previously it
                    wasn't as predictable.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>News feed speed improvements</h3>
                <h6>3 Oct 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    When you have thousands of words in your vocabulary, the
                    news feed tended to slow down when loading articles. It's
                    not the case anymore.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Grammar guides for tenses</h3>
                <h6>2 Oct 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    When you look up the word, you can now see the grammar tense
                    and click on it to see a grammar guide.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Reader improvements</h3>
                <h6>30 Sept 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    There's a new setting for expanding the AI explanation box
                    by default.
                  </li>
                  <li>You can now open the original article in a new tab.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Vietnamese is now available</h3>
                <h6>30 Sept 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now learn Vietnamese online with Lingo Champion.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Separate settings for news article titles</h3>
                <h6>27 Sept 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now choose to show the translated news article
                    titles in the library.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Article simplifications improvements</h3>
                <h6>27 Sept 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    It is now 100% reliable - it used to run into errors
                    sometimes.
                  </li>
                  <li>The title of article is now simplified as well.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Polish is now available</h3>
                <h6>26 Sept 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Co ma piernik do wiatraka?</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Vocabulary page improvements</h3>
                <h6>25 Sept 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    There's now an edit icon behind every word in the desktop
                    view.
                  </li>
                  <li>You can now search for the word or phrase.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Bug fix for mobile</h3>
                <h6>18 Sept 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Don't show the default browser menu when selecting text in
                    article or music lyrics reader.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Quick lookup now has pronunciation</h3>
                <h6>9 Sept 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    It's automatic in the reader and manual in the music lyrics.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Save words with the Chrome extension</h3>
                <h6>8 Sept 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Select the text on any website, let the extension translate
                    it and save it as a new learning word.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Chrome extension rewrite</h3>
                <h6>6 Sept 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>It's now lightning fast.</li>
                  <li>Words are loaded as you scroll.</li>
                  <li>Removed a few unnecessary settings.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Some small fixes</h3>
                <h6>31 Aug 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Changed the text-to-speech synthesizer in Swedish.</li>
                  <li>Bug fix: phrase selection works again in Music.</li>
                  <li>
                    Bug fix: clearing the highlighted word clears now both the
                    quick lookup and translation panel.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Quick lookup updates</h3>
                <h6>28 Aug 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Quick lookup is now also available in the reader.</li>
                  <li>You can now also save a word with quick lookup.</li>
                  <li>
                    Redesigned the music and news reader settings dropdown.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Bug fixes</h3>
                <h6>26 Aug 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    When audio is generating, the loader/spinner doesn't
                    disappear after closing the modal.
                  </li>
                  <li>Article simplification works again.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Portuguese updated</h3>
                <h6>26 Aug 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Updated Portuguese news sources.</li>
                  <li>
                    Switched the voice from Brazilian Portuguese from Portuguese
                    Portuguese.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Improvements to Music</h3>
                <h6>2 Aug 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The translation panel now works properly on mobile.
                    Otherwise when scrolling it tended to show the lyrics
                    underneath.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Small improvements</h3>
                <h6>29 July 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The selection is now handled properly on iOS - it used to
                    linger on after opening the translation panel for phrases.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Songs improvements</h3>
                <h6>26 July 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>There's a now a quick lookup for words in the lyrics.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Songs in beta</h3>
                <h6>25 July 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now listen to songs and look up words in the lyrics.
                    The feature is in beta for now.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Browser extension bug fix</h3>
                <h6>20 June 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Website translation just marked the original words instead
                    of the translations. This has been fixed now.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Bug fixes</h3>
                <h6>19 June 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Phrase translation now includes extra context for better AI
                    translations and explanations.
                  </li>
                  <li>Add mobile view for vocabulary.</li>
                  <li>
                    Add max length to word translations (max 50 characters)
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Learning words editing</h3>
                <h6>18 June 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now edit learning words under the vocabulary tab.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Browser Extension: speed improvements</h3>
                <h6>18 June 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The extension now cleans up after itself better and should
                    therefore not slow down your browser as much as before.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>
                  Browser Extension: YouTube captions translation improvements
                </h3>
                <h6>17 June 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    There's now a separate option for turning captions
                    translation on/off.
                  </li>
                  <li>
                    It now works with sentence translation or even if
                    youtube.com is ignored as a domain.
                  </li>
                  <li>It distinguishes between known and unknown words.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Browser Extension: YouTube captions translation</h3>
                <h6>14 June 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now translate YouTube captions. It's an early beta.
                  </li>
                  <li>
                    It works only with word translation (not with sentence
                    translation).
                  </li>
                  <li>It works only in YouTube (not with embedded players).</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Browser Extension: more translations</h3>
                <h6>11 June 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The extension now translates more words on the page. The
                    downside side that you might hit the translation limit for
                    sentences quicker.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Updated English news sources</h3>
                <h6>4 June 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Better quality news sources.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Continue Studying speed improvements</h3>
                <h6>2 June 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The articles under Continue Studying should now load
                    instantly when navigating between pages.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Finnish is now available!</h3>
                <h6>28 May 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can{" "}
                    <a href="/course/finnish-learn-online">
                      learn Finnish online
                    </a>{" "}
                    with Lingo Champion.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Playlists are here!</h3>
                <h6>24 May 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now add article audio to playlists and listen to
                    them in the order you prefer.
                  </li>
                  <li>
                    It's an early preview version, so all feedback is welcome.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>AI explanation bug fixes</h3>
                <h6>20 May 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Sometimes the responses got scrambled. It's been fixed now.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>AI is now twice as fast</h3>
                <h6>17 May 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    AI explanations and content creation should now be twice as
                    fast as before.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Bug fixes</h3>
                <h6>10 Apr 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Some mobile views used to throw errors when loading an
                    article (especially Chrome on Android). This should now be
                    fixed.
                  </li>
                  <li>
                    Article reader sometimes went to the wrong page after saving
                    a word. Not anymore.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Swedish is now available</h3>
                <h6>8 Apr 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>You can now learn Swedish online with Lingo Champion.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Increased audio quality for articles</h3>
                <h6>8 Apr 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Article audio quality has been increased for better
                    comprehension.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>YouTube captions import with translation</h3>
                <h6>2 Apr 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    YouTube captions will now be translated based on the English
                    ones if the original language ones are not available.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Italian video feed updated</h3>
                <h6>28 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Updated the Italian video feed with many new channels.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Bookmarking content</h3>
                <h6>28 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now add content under "Continue studying" without
                    having to open the content first.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Updated levels</h3>
                <h6>27 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The level descriptions are now more in tune with reality and
                    the actual learning process.
                  </li>
                  <li>
                    Some of the higher level goals have been adjusted to be more
                    realistic.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Reader simplification</h3>
                <h6>26 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Re-organized the buttons in the reader for simplicity and
                    consistency.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Chrome extension now supports multiple translations</h3>
                <h6>25 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    If your words have multiple translations (separated by
                    comma), you can now seem them translated.
                  </li>
                  <li>
                    Bug fixes: fixed some breaking changes in the settings.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Daily streak reminder email setting</h3>
                <h6>18 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now decide whether you want to receive a daily email
                    about streak reminders under Account settings.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Word strength goes to 5 now</h3>
                <h6>18 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Word strength got simplified - it now goes from 0 to 5. 5
                    means it's a learned word and doesn't have an SRS due date.
                  </li>
                  <li>
                    Learning words are underlined according to the strength now
                    - the stronger the word, the less underline.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Updated vocabulary import</h3>
                <h6>18 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>It's now strickly for tab-separated values.</li>
                  <li>The directions should be more clear now as well.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Updated Italian news sources</h3>
                <h6>18 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Italian news sources have been updated to be more relevant
                    to international learners.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>New statistics - day streaks</h3>
                <h6>15 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now see how many days in a row you've read at least
                    one page.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>New statistics - read words</h3>
                <h6>14 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now follow the number of words you've read per day.
                    There's a cumulative chart as well.
                  </li>
                  <li>
                    Additionally, both the streaks and stats should load faster.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Bug fixes</h3>
                <h6>14 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Tracking skipped words now works correctly. Sometimes the
                    queries failed because the size of the query was too big.
                  </li>
                  <li>Weekly streaks now works with timezones.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>YouTube captions import in the Chrome extension</h3>
                <h6>13 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now import captions with the Chrome extension as
                    well right from YouTube.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>YouTube captions import</h3>
                <h6>12 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now import captions/subtitles from the video
                    section.
                  </li>
                  <li>
                    They subtitles will be formatted with AI for better
                    readability.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Updated settings design & timezone</h3>
                <h6>9 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>You can now set your timezone.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Generate content with AI</h3>
                <h6>7 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>You can now generate content from scratch with AI.</li>
                  <li>
                    It's useful for various situations or topics you might
                    encounter in real life (but not necessarily in the news or
                    video feed).
                  </li>
                  <li>Find the button under the Imported tab.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Edit button in Continue Studying</h3>
                <h6>6 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can access editing now directly from the Continue
                    Studying section.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Improved AI explanations</h3>
                <h6>6 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    AI explanations should now be more accurate and include the
                    best translation. Previously it tended to ramble and explain
                    too much without saying what the best translation was.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>SRS flashcards</h3>
                <h6>5 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Flashcards have now the SRS (spaced repetition system)
                    option. It's an early preview.
                  </li>
                  <li>
                    Cards with the due date being set as today are selected
                    first, if there's room left over for more cards they will be
                    selected by earliest past due date first.
                  </li>
                  <li>
                    BUG FIX: sometimes when starting flashcards for an article,
                    the error message said "not enough words" although there
                    were plenty - this has been fixed now. The UI was correct as
                    well.
                  </li>
                  <li>
                    BUG FIX: editing words in the article reader translation
                    panel used to reset word strength - not anymore.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>AI scrambling fixes</h3>
                <h6>5 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    AI explanations and translations should now be more accurate
                    - no scrambled or missing text anymore when it comes to
                    non-English characters.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Thai language update</h3>
                <h6>5 Mar 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The words now have a small spacing between them to make it
                    easier to read.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Article audio speed changes</h3>
                <h6>29 Feb 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Text-to-speech for entire articles is now a bit slower by
                    default.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Reader changes</h3>
                <h6>26 Feb 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Improved layout for the reader success/finished page.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Thai language is in beta</h3>
                <h6>22 Feb 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>You can now learn Thai!</li>
                  <li>
                    Note that article filtering based on the new words does not
                    work yet.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Small fixes to article audio generation</h3>
                <h6>20 Feb 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    New button for generating audio on the article finished
                    page.
                  </li>
                  <li>Improved sentence splitting for audio.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Enhancements to article audio</h3>
                <h6>16 Feb 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    New button for continue studying articles to listen to the
                    audio.
                  </li>
                  <li>Faster article loading.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Audio generation and player for articles is in beta</h3>
                <h6>15 Feb 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now generate audio for the entire article and listen
                    to it while reading the text.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Flashcards bug fixed</h3>
                <h6>12 Feb 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    When doing the regular flashcards based on the vocabulary
                    (not article vocabulary), there was a bug that showed not
                    enough words. This has been fixed now.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>No more scrambled characters</h3>
                <h6>9 Feb 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    It's now possible to practice the learning words of each
                    article with flashcards.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>No more scrambled characters</h3>
                <h6>6 Feb 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Fixed scrambled characters in the reader both for AI
                    explanations and word translations.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>More up-to-date news</h3>
                <h6>15 Jan 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    News are now getting updated more often - several times per
                    day.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Bug fixes</h3>
                <h6>9 Jan 2024</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    When you navigate between pages, the page now scrolls to the
                    top automatically.
                  </li>
                  <li>Increased flashcard words from 6 to 7 words.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Video library released</h3>
                <h6>28 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now find videos in the library and watch them either
                    in the library or in YouTube.
                  </li>
                  <li>
                    This adds another layer to{" "}
                    <a href="/comprehensible-input">comprehensible input</a> -
                    you can now watch videos and read articles in the same
                    place.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Improved article finished page</h3>
                <h6>27 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The page shown after article completion has been redesigned
                    for more clarity.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Bug fixes</h3>
                <h6>27 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Levels now show the correct progress between the previous
                    and next level.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Phrases now shown in the reader</h3>
                <h6>27 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Previously it was already possible to save phrases. Those
                    phrases are now also displayed in the reader.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Article completion percentage in Library</h3>
                <h6>25 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Articles now show a completion percentage in your Library
                    (under Continue Studying).
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Better text-to-speech</h3>
                <h6>23 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Text-to-speech comes now directly from the server instead of
                    using browser-based TTS.
                  </li>
                  <li>
                    It's more accurate but it also might be slightly slower.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Bug fixes and other improvements</h3>
                <h6>19 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The AI explanations should now be slightly faster and
                    shorter.
                  </li>
                  <li>
                    When switching quickly between in the words (in the reader)
                    the translations won't show the previous word anymore.
                  </li>
                  <li>
                    Imported content is no longer showing duplicates when moving
                    between Imported and News feed tabs.
                  </li>
                  <li>
                    Flashcards are no disabled when there are no learning words.
                  </li>
                  <li>
                    Weekly streak is now updated without having to refresh the
                    page.
                  </li>
                  <li>
                    Proficiency level percentages are rounded down not up.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Article titles in English</h3>
                <h6>16 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Article titles are now shown in English (if automatic
                    translations are available).
                  </li>
                  <li>
                    You can still see the original title by hovering over the
                    title.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Weekly streaks</h3>
                <h6>16 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The Statistics now also show weekly streaks based on your
                    reading habits.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Phrase translation is live</h3>
                <h6>15 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>You can now translate and save phrases.</li>
                  <li>
                    The maximum length for a phrase is 5-7 words right now
                    (depending on the selection).
                  </li>
                  <li>
                    Note that phrases won't show up as learning words yet in the
                    reader. The AI translations and explanations won't work
                    either yet.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Closing the translation panel is now easier</h3>
                <h6>15 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You could already swipe down on the translation panel (on
                    touch screens) to close it. The distance you have to swipe
                    is now smaller (and dynamic) though, so, it's easier.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Proficiency level in the reader</h3>
                <h6>15 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Show the proficiency level on the article finished page.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Proficiency levels</h3>
                <h6>14 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now see proficiency levels under the Statistics.
                    It's a work in progress.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Content reader bug fixes</h3>
                <h6>14 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    When you close the translation panel, the selected word gets
                    unselected now.
                  </li>
                  <li>
                    The tips are now displayed on the translation panel until
                    you reach 500 total words.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>
                  Chrome extension now has sentence translation (v. 2.1.0)
                </h3>
                <h6>14 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now translate sentences on the web pages you visit
                    automatically.
                  </li>
                  <li>
                    The translation happens only when at least 50% of the words
                    are known.
                  </li>
                  <li>
                    The layout of options and settings has been simplified as
                    well.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Chrome extension is in beta</h3>
                <h6>13 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now use the Chrome extension to automatically
                    translate words on the web pages you visit.
                  </li>
                  <li>
                    Get it from the{" "}
                    <a
                      href="https://chromewebstore.google.com/detail/lingo-champion/jjbaohhbimlnboamjmdbblhjomhnipff?hl=en-GB"
                      target="_blank"
                    >
                      Chrome Web Store
                    </a>
                    .
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Word deletion</h3>
                <h6>11 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now delete learning words in the Vocabulary view.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Hide new words filter for new courses</h3>
                <h6>10 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    When starting a new course, there are no known words. So, it
                    doesn't make sense to show the filter until you've learned
                    some words.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Updated Spanish sources</h3>
                <h6>9 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The Spanish sources have been updated to be more relevant to
                    international learners.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Latvian course released</h3>
                <h6>8 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now{" "}
                    <a href="/learn-latvian-online">learn Latvian online</a> by
                    reading native speaker content.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Minor fixes</h3>
                <h6>8 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Bugfix: back buttons point to a specific place now instead
                    of using browser history (which didn't work for some users).
                  </li>
                  <li>Added shortcut to vocabulary import.</li>
                  <li>
                    Bugfix: imported articles now show the % of new words.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Flashcards are live</h3>
                <h6>7 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>It's an early version of flashcards.</li>
                  <li>Access it under the Vocabulary section.</li>
                  <li>
                    There are also shortcuts: "space" and "enter" flip the card
                    or advance to the next one. "0", "1", "2", "3", "4" change
                    the strength.
                  </li>
                  <li>
                    The vocabulary list now allows you to hear the pronunciation
                    as well.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Redesigned article reader</h3>
                <h6>5 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>The icons/buttons now line up properly.</li>
                  <li>
                    Fixed some bugs for smaller screens where the translation
                    panel wasn't showing correctly.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>You can now simplify articles with AI</h3>
                <h6>1 Dec 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Article simplification with AI is now in beta.</li>
                  <li>
                    Find it under the additional help options in the article
                    reading view.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Article reader design improvements</h3>
                <h6>30 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Simplified the translations panel design.</li>
                  <li>
                    Fixed a bug where on mobile the reader had horizontal
                    scroll.
                  </li>
                  <li>
                    Moved word type explanations from word lookup view to the
                    instructions view.
                  </li>
                  <li>
                    Reader is hiding now the tooltip for the next page button
                    after the user has seen it.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Russian is now available</h3>
                <h6>29 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now learn Russian by reading native speaker content.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Add text-to-speech for Norwegian and Danish</h3>
                <h6>29 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Essentially all languages (in the future) not supported by
                    browser's TTS, will have TTS support.
                  </li>
                  <li>
                    Both languages also got their news sources updated for
                    better quality content.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Text-to-speech for numbers</h3>
                <h6>28 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Numbers now have text-to-speech support.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Login bug fixed</h3>
                <h6>27 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    In some browsers, the login button didn't work because the
                    users weren't redirected to the secure login page. This has
                    been fixed now.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Added speech synthesis for Estonian</h3>
                <h6>24 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now{" "}
                    <a href="/learn-estonian-online">learn Estonian online</a>{" "}
                    properly - it is now out of beta with text-to-speech
                    support.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Faster AI translations</h3>
                <h6>23 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    AI translations and explanations should be up to 2x faster
                    now.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Portuguese is out!</h3>
                <h6>23 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Released Portuguese. Please report any issues you come by.
                  </li>
                  <li>Text-to-speech for Portuguese is available as well.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Danish in beta</h3>
                <h6>22 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Released Danish in beta. Please report any issues you come
                    by.
                  </li>
                  <li>Text-to-speech for Danish is not yet available.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Content import beta</h3>
                <h6>22 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now import your own content to library and study it.
                  </li>
                  <li>You can share it with other users or keep it private.</li>
                  <li>
                    Library view has been redesigned a bit as well to
                    accommodate the changes.
                  </li>
                  <li>
                    The Library now also displays placeholder images instead of
                    showing a blank space when the image for an article doesn't
                    load for some reason.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Improved vocabulary import</h3>
                <h6>22 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Vocabulary importer now removes column headers and empty
                    lines automatically.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Improved AI translations</h3>
                <h6>20 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    AI now also adds possible translations to the list if it
                    finds any that weren't included already. Previously it only
                    explained the word.
                  </li>
                  <li>
                    AI explanations now have a fixed height and "show more"
                    expansion option to make sure the explanations don't shift
                    the possible translations while you're trying to choose
                    them.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Improved AI translations</h3>
                <h6>20 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    AI now also adds possible translations to the list if it
                    finds any that weren't included already. Previously it only
                    explained the word.
                  </li>
                  <li>
                    AI explanations now have a fixed height and "show more"
                    expansion option to make sure the explanations don't shift
                    the possible translations while you're trying to choose
                    them.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Statistics has a new home</h3>
                <h6>17 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>Statistics has now its own page in the main menu.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>BUG FIX: AI explanations</h3>
                <h6>16 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    When clicking on words in the article reader too fast, the
                    AI still tried to translate the previously selected words.
                    Not anymore.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Multiple courses support</h3>
                <h6>15 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now add, edit, and switch between multiple language
                    courses.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Words are now explained by the AI</h3>
                <h6>15 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    You can now see contextually-aware explanations by the AI
                    when looking up words.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Improvements to the article reader</h3>
                <h6>15 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Saved learning words now show possible translations still as
                    well.
                  </li>
                  <li>
                    Changed the line height of text to make it easier to read.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>
                  It's now possible to filter articles by % of new words in the
                  article
                </h3>
                <h6>12 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Each article also displays the % of new words next to it.
                  </li>
                  <li>
                    This makes it easier to find articles that are at your level
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Fixed a bug where the reader displayed a blank page</h3>
                <h6>12 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    This affected some users who were using the browser zoom.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Added vocabulary importer</h3>
                <h6>11 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>You can now import vocabulary to {metaData.appName}.</li>
                  <li>
                    Both tab-separated and comma-separated values are allowed.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Launched a public beta</h3>
                <h6>10 Nov 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Public beta is now available. Please report any bugs or
                    issues you find.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Proof of Concept is up</h3>
                <h6>22 Oct 2023</h6>
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    The first version of Lingo Champion is up. It's a proof of
                    concept, so, don't expect much.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />

          <br />
        </div>
      </div>
    </Container>
  );
}
