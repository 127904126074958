import React, { useContext, useEffect, useState } from "react";
import Navigation from "./Components/Navigation";
import { Route, Routes, Navigate } from "react-router-dom";
import BackButtonHandler from "./utils/BackButtonHandler";
import { useTranslation, I18nextProvider } from "react-i18next";
import "./i18n";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Home from "./pages/Home";
import Library from "./pages/Library";
import LibraryEditor from "./pages/LibraryEditor";
import LibraryGenerator from "./pages/LibraryGenerator";
import LibraryImportSong from "./pages/LibraryImportSong";
import LibraryImportBook from "./pages/LibraryImportBook";
import CollectionEditor from "./pages/CollectionEditor";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import Vocabulary from "./pages/Vocabulary";
import VocabularyImporter from "./pages/VocabularyImporter";
import Statistics from "./pages/Statistics";
import Reader from "./pages/Reader";
// import Reader2 from "./pages/Reader2";
// import Reader3 from "./pages/Reader3";
import Reader4 from "./pages/Reader4";
import Music from "./pages/Music";
import ReaderHeight from "./pages/ReaderHeight";
import Player from "./pages/Player";
import Chat from "./pages/Chat";
import ChatGrammar from "./pages/ChatGrammar";
import Review from "./pages/Review";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import Account from "./pages/Account";
import Privacy from "./pages/Privacy";
import Admin from "./pages/Admin";
import ReleaseNotes from "./pages/ReleaseNotes";
import TermsOfService from "./pages/TermsOfService";
import ComprehensibleInput from "./pages/ComprehensibleInput";
import NotFound from "./pages/NotFound";
import CookieDetection from "./pages/CheckIfCookiesAreEnabled";
import Footer from "./Components/Footer";

import Spanish from "./pages/comprehensible-input/Spanish";
import Estonian from "./pages/comprehensible-input/Estonian";
import French from "./pages/comprehensible-input/French";
import Finnish from "./pages/comprehensible-input/Finnish";
import German from "./pages/comprehensible-input/German";
import Italian from "./pages/comprehensible-input/Italian";
import Norwegian from "./pages/comprehensible-input/Norwegian";
import Danish from "./pages/comprehensible-input/Danish";
import Polish from "./pages/comprehensible-input/Polish";
import Portuguese from "./pages/comprehensible-input/Portuguese";
import Russian from "./pages/comprehensible-input/Russian";
import Latvian from "./pages/comprehensible-input/Latvian";
import Swedish from "./pages/comprehensible-input/Swedish";
import Thai from "./pages/comprehensible-input/Thai";
import Vietnamese from "./pages/comprehensible-input/Vietnamese";

import LanguageCourse from "./pages/LanguageCourse";

import ScrollToTop from "./utils/ScrollToTop";

// could be rewritten and simplified to use a Hook instead
// https://www.youtube.com/watch?v=ZBZ6BqoUDsU&ab_channel=yoursTRULY
import AuthContext from "./context/AuthContext";

import { FullStory } from "@fullstory/browser";

import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import ImportDuolingoVocabulary from "./pages/ImportDuolingoVocabulary";

import GrammarGuidePage from "./pages/GrammarGuidePage";

import TranslationsEditor from "./pages/TranslationsEditor";

const ProtectedRoute = ({ children }) => {
  const context = useContext(AuthContext);
  const location = useLocation();
  const { isValidatingToken } = context; // Move isValidatingToken to AuthContext

  if (isValidatingToken) {
    return <div>Validating access...</div>;
  }

  if (!context.user_id) {
    return (
      <Navigate
        to={`/login?returnTo=${encodeURIComponent(location.pathname)}`}
      />
    );
  }

  return children;
};

function App() {
  const context = useContext(AuthContext);
  console.log("CONTEXT VARIABLES AND METHODS: ", context);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => i18n.changeLanguage(lang);

  let location = useLocation();

  const [isValidatingToken, setIsValidatingToken] = useState(false);

  // Add this effect to handle token login
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("temporary_login_token");
    const userId = params.get("user_id");

    if (token && userId && !context.user_id) {
      setIsValidatingToken(true);
      fetch(`/api/user/validate-temporary-login-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, userId }),
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            // If token is valid, update auth context
            context.getAuthInfo();
          }
        })
        .catch((error) => {
          console.error("Token validation failed:", error);
        })
        .finally(() => {
          setIsValidatingToken(false);
        });
    }
  }, [location.search, context]);

  // send pageview to Google Analytics only when location (path) changes

  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize("G-XFLY2RT4FR");
      window.GA_INITIALIZED = true;
    }
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  // Effect for identifying the user with FullStory
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      context.email &&
      context.user_id
    ) {
      FullStory.identify(context.user_id, {
        email: context.email,
        plan: context.plan,
      });
      console.log("FullStory user identified.");
    }
  }, [context.email, context.user_id]);

  useEffect(() => {
    context.getAuthInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isValidatingToken) {
    return <div>Validating access...</div>;
  }

  if (context.has_been_verified === undefined) {
    return <div>Loading...</div>;
  }

  const isReaderRoute = location.pathname.includes("/reader");
  const isReviewRoute = location.pathname.includes("/review");
  const isPlayerRoute = location.pathname.includes("/player");
  const isMusicRoute = location.pathname.includes("/music");
  const isChatRoute = location.pathname.includes("/chat");
  const isRegisterRoute = location.pathname.includes("/register");
  const isAdminRoute = location.pathname.includes("/admin");
  return (
    <>
      <I18nextProvider i18n={i18n}>
        {!isReaderRoute &&
          !isReviewRoute &&
          !isMusicRoute &&
          !isPlayerRoute &&
          !isChatRoute && <Navigation />}
        <ScrollToTop />
        <BackButtonHandler />
        <Routes>
          <Route
            path="/"
            element={context.user_id ? <Navigate to="/library" /> : <Home />}
          />
          <Route path="/home" element={<Home />} />

          {/* Protected routes */}
          <Route
            path="/library"
            element={
              <ProtectedRoute>
                <Library />
              </ProtectedRoute>
            }
          />
          <Route
            path="/library/editor/:id"
            element={
              <ProtectedRoute>
                <LibraryEditor />
              </ProtectedRoute>
            }
          />
          <Route
            path="/library/editor"
            element={
              <ProtectedRoute>
                <LibraryEditor />
              </ProtectedRoute>
            }
          />
          <Route
            path="/library/collection/editor"
            element={
              <ProtectedRoute>
                <CollectionEditor />
              </ProtectedRoute>
            }
          />
          <Route
            path="/library/collection/editor/:id"
            element={
              <ProtectedRoute>
                <CollectionEditor />
              </ProtectedRoute>
            }
          />
          <Route
            path="/library/generator"
            element={
              <ProtectedRoute>
                <LibraryGenerator />
              </ProtectedRoute>
            }
          />
          <Route
            path="/library/songs/editor"
            element={
              <ProtectedRoute>
                <LibraryImportSong />
              </ProtectedRoute>
            }
          />
          <Route
            path="/library/songs/editor/:id"
            element={
              <ProtectedRoute>
                <LibraryImportSong />
              </ProtectedRoute>
            }
          />
          <Route
            path="/library/import-book"
            element={
              <ProtectedRoute>
                <LibraryImportBook />
              </ProtectedRoute>
            }
          />

          {/* Vocabulary routes */}
          <Route
            path="/vocabulary/import"
            element={
              <ProtectedRoute>
                <VocabularyImporter />
              </ProtectedRoute>
            }
          />
          <Route
            path="/vocabulary"
            element={
              <ProtectedRoute>
                <Vocabulary />
              </ProtectedRoute>
            }
          />
          <Route
            path="/vocabulary/review"
            element={
              <ProtectedRoute>
                <Review />
              </ProtectedRoute>
            }
          />
          <Route
            path="/vocabulary/review/:articleId"
            element={
              <ProtectedRoute>
                <Review />
              </ProtectedRoute>
            }
          />

          {/* Statistics and Account routes */}
          <Route
            path="/statistics"
            element={
              <ProtectedRoute>
                <Statistics />
              </ProtectedRoute>
            }
          />
          <Route
            path="/account"
            element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            }
          />

          {/* Chat route */}
          <Route
            path="/chat"
            element={
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chat-grammar"
            element={
              <ProtectedRoute>
                <ChatGrammar />
              </ProtectedRoute>
            }
          />

          <Route
            path="/reader/:id"
            element={
              <ProtectedRoute>
                <Reader />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reader/books/:bookId/chapters/:chapterId"
            element={
              <ProtectedRoute>
                <Reader />
              </ProtectedRoute>
            }
          />
          {context.user_id && context.email.includes("atjackiejohns") && (
            <Route
              path="/admin/translations-editor"
              element={<TranslationsEditor />}
            />
          )}
          {context.user_id && context.email.includes("atjackiejohns") && (
            <Route path="/admin" element={<Admin />} />
          )}
          {/* <Route path="/reader2/:id" element={<Reader2 />} />
        <Route path="/reader3/:id" element={<Reader3 />} /> */}
          <Route
            path="/reader4/:id"
            element={
              <ProtectedRoute>
                <Reader4 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/music/:id"
            element={
              <ProtectedRoute>
                <Music />
              </ProtectedRoute>
            }
          />
          {context.user_id && (
            <Route path="/reader-height" element={<ReaderHeight />} />
          )}
          <Route
            path="/player/:id"
            element={
              <ProtectedRoute>
                <Player />
              </ProtectedRoute>
            }
          />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/account/change-password" element={<ChangePassword />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/release-notes" element={<ReleaseNotes />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/cookie-detector" element={<CookieDetection />} />

          <Route
            path="/comprehensible-input"
            element={<ComprehensibleInput />}
          />
          <Route path="/spanish-comprehensible-input" element={<Spanish />} />
          <Route path="/estonian-comprehensible-input" element={<Estonian />} />
          <Route path="/finnish-comprehensible-input" element={<Finnish />} />
          <Route path="/french-comprehensible-input" element={<French />} />
          <Route path="/german-comprehensible-input" element={<German />} />
          <Route path="/italian-comprehensible-input" element={<Italian />} />
          <Route
            path="/norwegian-comprehensible-input"
            element={<Norwegian />}
          />
          <Route path="/danish-comprehensible-input" element={<Danish />} />
          <Route path="/polish-comprehensible-input" element={<Polish />} />
          <Route
            path="/portuguese-comprehensible-input"
            element={<Portuguese />}
          />
          <Route path="/russian-comprehensible-input" element={<Russian />} />
          <Route path="/latvian-comprehensible-input" element={<Latvian />} />
          <Route path="/swedish-comprehensible-input" element={<Swedish />} />
          <Route path="/thai-comprehensible-input" element={<Thai />} />
          <Route
            path="/vietnamese-comprehensible-input"
            element={<Vietnamese />}
          />
          <Route
            path="/course/:language-learn-online"
            element={
              <ProtectedRoute>
                <LanguageCourse />
              </ProtectedRoute>
            }
          />
          <Route
            path="/import-duolingo-vocabulary"
            element={<ImportDuolingoVocabulary />}
          />
          <Route
            path="/grammar-guides/:language/:category/:guide"
            element={
              <ProtectedRoute>
                <GrammarGuidePage />
              </ProtectedRoute>
            }
          />
          <Route path="/*" element={<NotFound />} />
        </Routes>
        {!isReaderRoute &&
          !isReviewRoute &&
          !isPlayerRoute &&
          !isMusicRoute &&
          !isChatRoute &&
          !isAdminRoute &&
          !isRegisterRoute && <Footer />}
      </I18nextProvider>
    </>
  );
}

export default App;
