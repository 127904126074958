import React, { useContext, useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import AuthContext from "../context/AuthContext";
import LearnedWordsChart from "./ChartLearnedWords";
import LearnedWordsChartCumulative from "./ChartLearnedWordsCumulative";
import ReadWordsChart from "./ChartReadWords";
import ReadWordsChartCumulative from "./ChartReadWordsCumulative";
import ChartAudioListened from "./ChartAudioListened";
import ChartAudioListenedCumulative from "./ChartAudioListenedCumulative";
import LevelsFullProgress from "./LevelsFullProgress";
import StreakWeek from "./StreakWeek";
import { useTranslation } from "react-i18next";

const Charts = ({
  learningWords = [],
  skippedWords = [],
  knownWordsArray = [],
}) => {
  const { t } = useTranslation();
  const context = useContext(AuthContext);
  console.log("CONTEXT IN CampaignList.js: ", context);

  const [learningWordsByDay, setLearningWordsByDay] = useState([]);
  const [learningWordsByDayCumulative, setLearningWordsByDayCumulative] =
    useState([]);
  const [readWordsByDayCumulative, setReadWordsByDayCumulative] = useState([]);

  const learnedWords = learningWords.filter((word) => word.strength === 5);

  // Process learningWords to get count by date where translation is defined
  const processDataForChart = (words) => {
    console.log("== WORDS: ", words);
    const filteredWords = words.filter(
      (word) => typeof word.translation === "string"
    );
    const countsByDate = filteredWords.reduce((acc, { date_added }) => {
      const date = new Date(date_added).toISOString().split("T")[0];
      if (!acc[date]) {
        acc[date] = 0;
      }
      acc[date]++;
      return acc;
    }, {});

    return Object.entries(countsByDate).map(([date, count]) => ({
      date,
      count,
    }));
  };

  // useEffect for learning words
  useEffect(() => {
    if (learningWords.length === 0) return;
    setLearningWordsByDay(processDataForChart(learningWords));
    setLearningWordsByDayCumulative(
      processDataForChartCumulative(learningWords)
    );
  }, [learningWords]);

  const processDataForChartCumulative = (words) => {
    const filteredWords = words.filter(
      (word) => typeof word.translation === "string"
    );
    const countsByDate = filteredWords.reduce((acc, { date_added }) => {
      const date = new Date(date_added).toISOString().split("T")[0];
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    const sortedDates = Object.keys(countsByDate).sort();
    const cumulativeData = sortedDates.reduce((acc, date) => {
      const lastCount = acc.length > 0 ? acc[acc.length - 1].count : 0;
      acc.push({
        date,
        count: lastCount + countsByDate[date],
      });
      return acc;
    }, []);

    return cumulativeData;
  };

  const convertToObjectArray = (data) => {
    return Object.entries(data).map(([date, count]) => ({
      date,
      count,
    }));
  };

  useEffect(() => {
    if (context.statistics?.wordsReadByDay) {
      // console.log("CONTEXT STATISTICS: ", context.statistics);
      // console.log(
      //   "CONTEXT STATISTICS CUMULATIVE: ",
      //   makeDataCumulative(context.statistics)
      // );
      setReadWordsByDayCumulative(
        makeDataCumulative(context.statistics.wordsReadByDay)
      );
    }
  }, [context.statistics]);

  const makeDataCumulative = (data) => {
    let cumulativeCount = 0;
    return data.map((entry) => {
      cumulativeCount += entry.count;
      return {
        date: entry.date,
        count: cumulativeCount,
      };
    });
  };

  // const readWordsChartArray = convertToObjectArray(context.statistics);

  return (
    <div className="mb-5" style={{ maxWidth: "700px", margin: "0 auto" }}>
      <StreakWeek />

      <LevelsFullProgress currentWordCount={knownWordsArray.length} />

      <ListGroup as="ol" className="my-5">
        <ListGroup.Item as="li" className="d-flex flex-column">
          <div className="d-flex justify-content-between align-items-start">
            <div className="ms-2">
              <div className="fw-bold">{t("statistics:learning_words")}</div>
              {t("statistics:learning_words_description")}
            </div>
            <Badge bg="primary" pill>
              {learningWords.length}
            </Badge>
          </div>
          <div className="d-flex justify-content-between align-items-start mt-2">
            <div className="ms-2">
              <div className="fw-bold">{t("statistics:learned_words")}</div>
              {t("statistics:learned_words_description")}
            </div>
            <Badge bg="success" pill>
              {learnedWords.length}
            </Badge>
          </div>
          <div className="d-flex justify-content-between align-items-start mt-2">
            <div className="ms-2">
              <div className="fw-bold">{t("statistics:not_learned_words")}</div>
              {t("statistics:not_learned_words_description")}
            </div>
            <Badge bg="danger" pill>
              {learningWords.length - learnedWords.length}
            </Badge>
          </div>
        </ListGroup.Item>

        <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start"
        >
          <div className="ms-2 me-auto">
            <div className="fw-bold">{t("statistics:skipped_words")}</div>
            {t("statistics:skipped_words_description")}
          </div>
          <Badge bg="primary" pill>
            {skippedWords.length}
          </Badge>
        </ListGroup.Item>
        <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start"
        >
          <div className="ms-2 me-auto">
            <div className="fw-bold">
              {t("statistics:total_unique_words_in_your_vocabulary")}
            </div>
            {t("statistics:total_unique_words_in_your_vocabulary_description")}
          </div>
          <Badge bg="primary" pill>
            {knownWordsArray.length}
          </Badge>
        </ListGroup.Item>
      </ListGroup>

      <h2 className="mt-5 mb-3 text-center">{t("statistics:reading_title")}</h2>

      <Card className="mt-3 my-3">
        <Card.Body>
          <Card.Title>
            {t("statistics:new_learning_words_added_by_day")}
          </Card.Title>
          <LearnedWordsChart data={learningWordsByDay} />
        </Card.Body>
      </Card>

      <Card className="mt-3 my-3">
        <Card.Body>
          <Card.Title>
            {t("statistics:learning_words_by_day_cumulative")}
          </Card.Title>
          <LearnedWordsChartCumulative data={learningWordsByDayCumulative} />
        </Card.Body>
      </Card>

      <Card className="mt-3 my-3">
        <Card.Body>
          <Card.Title>{t("statistics:words_read_by_day")}</Card.Title>
          <ReadWordsChart data={context.statistics?.wordsReadByDay || []} />
        </Card.Body>
      </Card>

      <Card className="mt-3 my-3">
        <Card.Body>
          <Card.Title>
            {t("statistics:words_read_by_day_cumulative")}
          </Card.Title>
          <ReadWordsChartCumulative data={readWordsByDayCumulative} />
        </Card.Body>
      </Card>

      <h2 className="mt-5 mb-3 text-center">
        {t("statistics:listening_title")}
      </h2>

      <Card className="mt-3 my-3">
        <Card.Body>
          <Card.Title>{t("statistics:minutes_listened_by_day")}</Card.Title>
          <ChartAudioListened
            data={context.statistics?.secondsListenedByDay || []}
          />
        </Card.Body>
      </Card>

      <Card className="mt-3 my-3">
        <Card.Body>
          <Card.Title>
            {t("statistics:minutes_listened_by_day_cumulative")}
          </Card.Title>
          <ChartAudioListenedCumulative
            data={context.statistics?.secondsListenedByDay || []}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default Charts;
